import React, { Component } from 'react'

const TEMPLATE = `<div class='popover slimline-popover'>
  <div class='arrow'></div>
  <div class='popover-inner'>
    <h3 class='popover-title'></h3><div class='popover-content'></div>
  </div>
</div>`

export default class Popover extends Component {

  componentDidMount() {
    $(this.dom).popover() // Why not jquery?
  }

  render() {
    return (
      <span
        data-original-title={this.props.title || ''}
        data-content={this.props.content}
        data-template={TEMPLATE}
        data-toggle="popover"
        data-trigger="hover"
        data-container="body"
        data-placement="auto bottom"
        data-html="true"
        ref={e => this.dom = e}
      >
        {this.props.children}
      </span>
    )
  }
}

