import React from 'react'

import InfoBox from '../common/InfoBox'
import PopOver from '../common/PopOver'

// Nicer names for display purposes:
const HUMAN_FRIENDLY_NAME_FOR = {
  capacity_info: 'Capacity',
  customs_info: 'Customs',
  geographic_info: 'Location',
  warehouse_info: 'Warehouse',
  additional_info: 'Add. Info'
}

// Display trailer "notes" indicators.
function TrailerBookingNotes (props) {
  // Ignore any notes without actual text in them:
  const notes = Object.keys(props.notes).filter(kind => props.notes[kind])

  const items = notes.map(kind => {
    const summary = HUMAN_FRIENDLY_NAME_FOR[kind] || kind
    const content = <InfoBox header={summary}>{props.notes[kind]}</InfoBox>

    return (
      <div key={kind} className='trailer-planning-trailer-booking-notes-icon'>
        <PopOver title={content}>{summary}</PopOver>
      </div>
    )
  })

  return <div className='trailer-planning-trailer-booking-notes'>{items}</div>
}

export default TrailerBookingNotes
