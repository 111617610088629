import React, { Component } from 'react'
import Select from 'react-select'

import StateManager from '../StateManager'

export default class DepartmentFilter extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.options = this.manager.getDepartments().map(d => ({
      value: d.id,
      label: d.name
    }))

    this.state = { department: this.manager.getDepartmentId() }
  }

  setDepartment(value) {
    this.setState({ department: value })
    this.manager.setDepartment(value)
  }

  render() {
    return (
      <div className="department-filter">
        <span className="filter-title">Filter Department</span>
        <Select
          options={this.options}
          multi={false}
          simpleValue={true}
          placeholder="Select department..."
          value={this.state.department}
          onChange={this.setDepartment.bind(this)}
        />
      </div>
    )
  }

}
