import React from 'react'

// Generic pagination. Intended for tables, but could be used for anything.
function Pagination (props) {
  // `props.current` is zero-based, so will always be less than `props.total`.
  const lastPage = props.total ? props.total - 1 : 0

  // NOTE: This logic mimics how the original DataTables pagination worked.
  let pattern = []
  if (lastPage < 5) {
    // 1 | 2 | 3 | 4 | 5
    for (let i = 0; i <= lastPage; ++i) { pattern.push(i) }
  } else if (props.current < 4) {
    // 1 | 2 | 3 | 4 | 5 | ... | 10
    pattern = [0, 1, 2, 3, 4, null, lastPage]
  } else if (props.current > lastPage - 4) {
    // 1 | ... | 6 | 7 | 8 | 9 | 10
    const n = lastPage
    pattern = [0, null, n - 4, n - 3, n - 2, n - 1, n]
  } else {
    // 1 | ... | 4 | 5 | 6 | ... | 10
    const n = props.current
    pattern = [0, null, n - 1, n, n + 1, null, lastPage]
  }

  // NOTE: We can't use the page as the key, as there might be two "..." items!
  const pages = pattern.map((page, key) => {
    if (page === null) {
      return (
        <li key={key} className='paginate_button disabled'>
          <span>…</span>
        </li>
      )
    } else if (page === props.current) {
      return (
        <li key={key} className='paginate_button active'>
          <span>{page + 1}</span>
        </li>
      )
    }

    return (
      <li key={key} className='paginate_button' tabIndex='0'>
        <a href='#' onClick={e => props.onClick(e, page)}>
          {page + 1}
        </a>
      </li>
    )
  })

  // Only show this if we have a previous page to go back to:
  const prevButton = (props.total > 1 && props.current > 0) ? (
    <li className='paginate_button' tabIndex='0'>
      <a href='#' onClick={e => props.onClick(e, props.current - 1)}>
        Previous
      </a>
    </li>
  ) : null

  // Only show this if we have a next page to go forward to:
  const nextButton = (props.total > 1 && props.current < lastPage) ? (
    <li className='paginate_button' tabIndex='0'>
      <a href='#' onClick={e => props.onClick(e, props.current + 1)}>
        Next
      </a>
    </li>
  ) : null

  return (
    <ul className='pagination'>
      {prevButton}
      {pages}
      {nextButton}
    </ul>
  )
}

export default Pagination
