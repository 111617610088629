import React from 'react'

// Components:
import Modal from '../../common/Modal'
import Content from './Show/Content'
import Footer from './Show/Footer'
// APIs:
import NotificationApi from '../../apis/events/NotificationApi'

// Displays information about a specific notification.
class ShowModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = { notification: null }
  }

  componentDidMount () {
    NotificationApi.show(this.props.id).then(
      json => { this.setState({ notification: json }) },
      error => { console.log(error) }
    )
  }

  render () {
    if (!this.state.notification) {
      return <Modal wide={true} onClose={this.props.onClose} />
    }

    const title = `Notification: ${this.state.notification.title}`
    const footer = <Footer onClose={this.props.onClose} />

    return (
      <Modal wide={true} title={title} footer={footer} onClose={this.props.onClose}>
        <Content notification={this.state.notification} />
      </Modal>
    )
  }
}

export default ShowModal
