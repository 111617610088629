import React, { Component } from 'react'

import DateFilter from './head/DateFilter'
import BookingSearch from './head/BookingSearch'
import CountryFilter from './head/CountryFilter'
import ProgressFilter from './head/ProgressFilter'
import DepartmentFilter from './head/DepartmentFilter'

import Excel from './head/Excel'
import PopOut from './head/PopOut'
import IconKey from './head/IconKey'
import ExpandAll from './head/ExpandAll'
import NewBooking from './head/NewBooking'
import IncludeJob from './head/IncludeJob'

import BookingSelector from './BookingSelector'
import ProgressSelector from './bookings/ProgressSelector'

export default class Head extends Component {

  render() {
    return (
      <div className="head">
        <div className="header-filters">
          {this.props.multi_window ? '' : <DateFilter />}
          <DepartmentFilter />
          <CountryFilter options={this.props.countries} />
          {this.props.multi_window ? '' : <ProgressFilter />}
          {this.props.multi_window ? '' : <BookingSearch />}
        </div>
        <hr />
        <div className="header-icons">
          <IconKey />
          <div className="controls-wrapper">
            <Excel />
            <ExpandAll />
            {this.props.multi_window ? '' : <PopOut />}
            {this.props.multi_window ? '' : <NewBooking permissions={this.props.permissions} />}
            <IncludeJob permissions={this.props.permissions} />
          </div>
        </div>
        <BookingSelector />
        <ProgressSelector />
      </div>
    )
  }

// private

}
