const ROUTES = {
  change_planning_unit_path: '/planning_units/:id/change',
  edit_planning_unit_path: '/planning_units/:id/edit',
  movement_planning_unit_path: '/planning_units/:id/movement',
  planning_unit_path: '/planning_units/:id',

  groupage_path: '/groupages/:id',

  picking_list_trailer_bookings_pdf_path: '/trailer_bookings/pdfs/:id/picking_list',
  plan_driver_running_sheet_trailer_bookings_pdf: '/trailer_bookings/pdfs/:id/plan_driver_running_sheet',
  edit_trailer_booking_path: '/trailer_bookings/:id/edit',
  trailer_booking_path: '/trailer_bookings/:id',

  update_progress_trailer_planners_booking_path: '/trailer_planners/bookings/:id/update_progress',
  create_groupage_trailer_planners_booking_path: '/trailer_planners/bookings/:id/create_groupage',
  new_trailer_planners_booking_path: '/trailer_bookings/new',

  trailer_planners_path: '/trailer_planners',
  excel_trailer_planners_path: '/trailer_planners/excel',
  missed_events_trailer_planners_path: '/trailer_planners/missed_events',

  trailer_planners_unit_path: '/trailer_planners/units/:id',

  include_trailer_planners_jobs_path: '/trailer_planners/jobs/include',
  add_trailer_planners_jobs_path: '/trailer_planners/jobs/add',
  dimensions_lines_trailer_planners_job_path: '/trailer_planners/jobs/:id/dimensions_lines',

  financials_trailer_planners_groupage_path: '/trailer_planners/groupages/:id/financials',
  assign_job_trailer_planners_groupages_path: '/trailer_planners/groupages/assign_job'
}

// Usage: url_for('jobs_path')
//        url_for('job_path', 211)
//        url_for('jobs_path, { import: true })
//        url_for('job_path', 211, { mode: 'braille' })
//
export function url_for(route, id, params={}) {
  if (typeof(id) === 'object') { params = id }

  let url = `${ROUTES[route].gsub(':id', id)}`

  if (!params || params.empty()) { return url }

  url += '?'
  url += params.keys().map(k => `${k}=${params[k]}`).join('&')
  return url
}
