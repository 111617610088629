
const SEARCHABLE = [
  "groupage",
  "supplier",
  "trailer_numbers",
  "vehicle_registration",
  "origin_hub",
  "destination_hub"
]

const UNIT_SEARCH = [
  "name",
  "customer",
  "shipper",
  "consignee"
]

export default class PlannerCollection {

  constructor(collection) {
    this.collection = collection
  }

  where(c) {
    let always_show  = this.collection.groupBy(u => u.show_on_planner == 'always')
    this.collection = always_show.false || []

    this.collection = this.collection.reject(u => u.show_on_planner == 'never')

    this._dateFilter(c.start_date, c.end_date)
    this._originFilter(c.origin)
    this._destinationFilter(c.destination)
    this._progressFilter(c.progress)
    this._search(c.search, c.units)

    if (always_show.true) { this.collection = this.collection.concat(always_show.true) }

    return this.collection
  }

// private

  _search(q, units) {
    if (!q) { return }
    const regexp = new RegExp(q, 'i')
    let block_units = units.values().groupBy(u => u.block_id)
    this.collection = this.collection.select(
      b => SEARCHABLE.any(s => b[s] && b[s].match(regexp)) ||
        this._unitsMatch(block_units[b.id], regexp)
    )
  }

  _unitsMatch(units, regexp) {
    if (!units || units.empty()) { return false }
    return UNIT_SEARCH.any(attr => units.any(u => u[attr] && u[attr].match(regexp)))
  }

  // http://momentjs.com/docs/#/query/is-between/
  _dateFilter(start, end) {
    if (!start || !end) { return }
    this.collection = this.collection.select(
      b => b.date_of_use.isBetween(start, end, 'day', [])
    )
  }

  _originFilter(origins) {
    if (!origins || origins.empty()) { return }
    this.collection = this.collection.select(
      b => origins.includes(b.origin_country)
    )
  }

  _destinationFilter(destinations) {
    if (!destinations || destinations.empty()) { return }
    this.collection = this.collection.select(
      b => destinations.includes(b.destination_country)
    )
  }

  _progressFilter(progresses) {
    if (!progresses) { return }
    this.collection = this.collection.select(
      b => progresses.includes(b.progress)
    )
  }

}

