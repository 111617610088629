import React from 'react'
import { Link } from 'react-router-dom'

// Emulates the Breadcrumb Bar usually provided by PixelAdmin.
function BreadcrumbBar (props) {
  const route = { '/': 'Home', ...props.route }

  // The last item has a special CSS class, and is not a link:
  const items = Object.keys(route)
  const links = items.map(url => {
    if (url === items[items.length - 1]) {
      return <li key={url} className='active'>{route[url]}</li>
    } else {
      return <li key={url}><Link to={url}>{route[url]}</Link></li>
    }
  })

  return (
    <div id='breadcrumb-container'>
      <ul className='breadcrumb breadcrumb-page'>{links}</ul>
    </div>
  )
}

export default BreadcrumbBar
