import React from 'react'

import LocationWithMapLinks from './LocationWithMapLinks'
import EmissionsHelper from '../reactive/helpers/EmissionsHelper'

function responsibilityTextFor (journey) {
  if (!journey.phase) { return null } // Not present in "per-vehicle" datasets.

  return `"${journey.phase}" phase - ${journey.partyResponsible} responsible`
}

// Refer to https://developers.google.com/maps/documentation/urls/get-started
function googleParams (pointA, pointB) {
  if (!pointA || !pointB) { return null }

  const params = new URLSearchParams()
  params.append('api', 1)
  params.append('origin', `${pointA.latitude},${pointA.longitude}`)
  params.append('destination', `${pointB.latitude},${pointB.longitude}`)
  return params
}

// Displays details for an individual (leg of a) journey.
function JourneyDetails (props) {
  const km = props.journey.actualDistance || props.journey.estimatedDistance
  const gmaps = googleParams(props.journey.origin, props.journey.destination)
  const miles = EmissionsHelper.kilometresToMiles(km)

  // Vehicles use the weight of ALL jobs, but jobs only care about themselves:
  const weight = props.useKm ? props.journey.totalWeight : props.journey.weight
  const tkm = EmissionsHelper.calculateTonneKilometres(props.journey, weight)

  // Vehicles are calculated based on distance; jobs use weight AND distance:
  const value = props.useKm ? km : tkm
  const wtt = EmissionsHelper.calculateWellToTank(props.journey, value)
  const ttw = EmissionsHelper.calculateTankToWheel(props.journey, value)

  // Additional information (basically just presenting the above differently):
  const wtw = Number(wtt) + Number(ttw) // WTW = "Well To Wheel"
  const ratio = tkm ? wtw / tkm : null // Pledge's API has this, so we do too.

  // FMS-9365: Indicate who is "responsible" for each phase of the journey...
  const responsibilityText = responsibilityTextFor(props.journey)

  return (
    <div className='journey-details'>
      <div className='journey-details-misc'>
        <div className='journey-details-party' title={responsibilityText}>
          {responsibilityText}
        </div>
        <div className='text'>{props.firstColumn}</div>
        {props.journey.estimatedDistance ? (
          <a href={`https://www.google.com/maps/dir/?${gmaps}`} target='_blank'>
            Google
          </a>
        ) : null}
      </div>
      <div className='journey-details-origin'>
        <LocationWithMapLinks place={props.journey.origin} />
      </div>
      <div className='journey-details-destination'>
        <LocationWithMapLinks place={props.journey.destination} />
      </div>
      <div className='journey-details-distance'>
        <span title={miles && `${EmissionsHelper.formatDistance(miles)} miles`}>
          {EmissionsHelper.formatDistance(km) || '---'}
        </span>
        {(props.journey.estimatedDistance && !props.journey.actualDistance) ? (
          <i
            className='fa fa-exclamation-triangle text-warning estimate-warning'
            title='Estimated'
          />
        ) : null}
      </div>
      <div className='journey-details-weight'>
        <span title={tkm && `${tkm.toFixed(2)} t-km`}>{weight}</span>
      </div>
      <div className='journey-details-wtt'>
        <span title={`Multiplier: ${props.journey.emissions?.wtt}`}>
          {wtt ? wtt.toFixed(2) : '---'}
        </span>
      </div>
      <div className='journey-details-ttw'>
        <span title={`Multiplier: ${props.journey.emissions?.ttw}`}>
          {ttw ? ttw.toFixed(2) : '---'}
        </span>
      </div>
      <div className='journey-details-emissions'>
        <span title={ratio ? `Intensity ratio: ${ratio.toFixed(2)} kg` : null}>
          {wtw ? wtw.toFixed(2) : '---'}
        </span>
      </div>
      <div className='journey-details-special'>{props.children}</div>
    </div>
  )
}

export default JourneyDetails
