// Send a generic AJAX request.
function sendRequest (verb, path, data) {
  return new Promise((resolve, reject) => {
    // NOTE: We use jQuery here to avoid CSRF errors in non-API controllers.
    $.ajax(path, {
      data: data,
      dataType: 'json',
      method: verb,
      success: (data) => resolve(data),
      error: (_xhr, _status, error) => reject(error)
    })
  })
}

export default sendRequest
