import React from 'react'

// Part of the top bar.
function NavbarMisc (props) {
  return (
    <React.Fragment>
      <li>
        <a href="https://freightsoft.zendesk.com/hc/en-gb" target="_blank">
          <i className="dropdown-icon fa fa-question-circle-o"></i>&nbsp;&nbsp;Help
        </a>
      </li>
      <li className="hazculator-nav-btn nav-icon-btn">
        <a href='/hazculator' target='_blank' dangerouslySetInnerHTML={{ __html: props.logoImage }} />
      </li>
    </React.Fragment>
  )
}

export default NavbarMisc
