import React from 'react'

import ManualNctsShipmentForm from './ManualNctsShipmentForm'
import NormalNctsShipmentForm from './NormalNctsShipmentForm'
// Common components:
import Modal from '../../reactive/common/Modal'
// APIs:
import CustomsNctsShipmentApi from '../../reactive/apis/customs/CustomsNctsShipmentApi'
import CustomsSubmissionApi from '../../reactive/apis/customs/CustomsSubmissionApi'
// Helpers:
import showErrors from '../showErrors'

// Used on the Show Groupage screen to represent a (potential?) NCTS Shipment.
export default class extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      errors: [],
      modal: false,
      // This may be `null` if this is a "potential" NCTS shipment:
      nctsShipment: props.nctsShipment,
      // If we're creating a NEW record, we'll need to store some data for it:
      manualReference: '',
      selectedCcg: null,
      selectedAlci: null
    }
  }

  handleManualReferenceChange = (e) => {
    this.setState({ manualReference: e.target.value })
  }

  handleCcgChange = (e) => this.setState({ selectedCcg: e.target.value })
  handleAlciChange = (e) => this.setState({ selectedAlci: e.target.value })

  handleShowModal = () => this.setState({ modal: true })
  handleHideModal = () => this.setState({ modal: false })

  // If an NCTS record exists, we can check the status of its MRN:
  handleCheckStatus = () => {
    CustomsNctsShipmentApi.checkStatus(this.state.nctsShipment?.id).then(
      json => this.setState({ nctsShipment: json }),
      json => showErrors(json.errors || [json.error])
    )
  }

  // We might want to "manually" create a record, rather than uploading one...
  handleManualSubmit = () => {
    const params = {
      groupageId: this.props.groupageId,
      reference: this.state.manualReference,
      ccgNumber: this.state.selectedCcg,
      alciReference: this.state.selectedAlci,
      jobIds: this.props.jobs.map(job => job.id)
    }

    CustomsNctsShipmentApi.create({ nctsShipment: params }).then(
      json => this.setState({ nctsShipment: json, modal: false }),
      json => this.setState({ errors: json.errors || [json.error] })
    )
  }

  // ...but normally new NCTS Shipments would be uploaded to a Customs API:
  handleNormalSubmit = () => {
    const groupageId = this.props.groupageId
    const ccg = this.state.selectedCcg
    const alci = this.state.selectedAlci
    const jobIds = this.props.jobs.map(job => job.id)

    // Make sure that the user knows what is about to happen!
    const prompt = [`Submit the following jobs using CCG ${ccg}?`, '']
    this.props.jobs.forEach(job => prompt.push(`• ${job.jobReference}`))
    prompt.push('', alci ? `ALCI: ${alci}` : 'No ALCI will be specified')
    if (!window.confirm(prompt.join('\n'))) { return }

    CustomsSubmissionApi.submitNctsShipment(groupageId, ccg, alci, jobIds).then(
      json => this.setState({ nctsShipment: json }),
      json => showErrors(json.errors || [json.error])
    )
  }

  render () {
    const modalFooter = (
      <React.Fragment>
        <button className='btn btn-primary' onClick={this.handleManualSubmit}>
          Save
        </button>
        <button className='btn' onClick={this.handleHideModal}>
          Cancel
        </button>
      </React.Fragment>
    )

    return (
      <div className='ncts-shipment'>
        <NormalNctsShipmentForm
          goodsValue={this.props.goodsValue}
          guaranteeValue={this.props.guaranteeValue}
          jobs={this.props.jobs}
          nctsShipment={this.state.nctsShipment}
          office={this.props.office}
          partner={this.props.partner}
          problem={this.props.problem}
          selectedAlci={this.state.selectedAlci}
          selectedCcg={this.state.selectedCcg}
          onAlciChange={this.handleAlciChange}
          onCcgChange={this.handleCcgChange}
          onCheckStatus={this.handleCheckStatus}
          onManualSubmit={this.handleShowModal}
          onNormalSubmit={this.handleNormalSubmit}
        />
        {this.state.modal && (
          <Modal
            wide={true}
            title={this.props.office}
            footer={modalFooter}
            onClose={this.handleHideModal}
          >
            <ManualNctsShipmentForm
              errors={this.state.errors}
              jobs={this.props.jobs}
              reference={this.state.manualReference}
              partner={this.props.partner}
              selectedAlci={this.state.selectedAlci}
              selectedCcg={this.state.selectedCcg}
              onAlciChange={this.handleAlciChange}
              onCcgChange={this.handleCcgChange}
              onReferenceChange={this.handleManualReferenceChange}
              onSubmit={this.handleManualSubmit}
            />
          </Modal>
        )}
      </div>
    )
  }
}
