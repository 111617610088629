import React from 'react'

// Internal names vs. display names:
const namesAndLabels = {
  name: 'Name',
  shipper: 'Shipper',
  shipping_postcode: 'Ship. PC',
  consignee: 'Consignee',
  consignee_postcode: 'Con. PC',
  package_details: 'Pkg',
  gross_weight_kg: 'Kg',
  cubic_metres: 'Cube',
  loading_metres: 'LDM',
  taxable_weight: 'Tax',
  notes: 'Notes', // Not (directly) sortable.
  consignment_flags: 'Consignment Flags', // Not (directly) sortable.
  dangerous_goods: 'Dangerous Goods', // Not (directly) sortable.
  ready_date: 'Ready',
  delivery_required_by: 'Del. Rqd',
  collection_date: 'Col.',
  due_in_date: 'Due',
  customs_status: 'Customs Status',
  origin_hub: 'Origin Hub',
  destination_hub: 'Dest. Hub',
  collection: 'Collection', // Not (directly) sortable.
  delivery: 'Delivery' // Not (directly) sortable.
}

// This represents the "header" of a planning unit list.
function PlanningUnitHeader (props) {
  const css = 'trailer-planning-planning-unit-header'

  const items = Object.keys(namesAndLabels).map(col => {
    const selected = props.column === col ? 'selected' : ''
    const btnCss = `${css}-${col.replace('_', '-')} ${selected}`

    return (
      <button key={col} className={btnCss} onClick={props.onClick} value={col}>
        {namesAndLabels[col]}
      </button>
    )
  })

  return (
    <div className={`${css} ${props.ascending ? 'asc' : 'desc'}`}>
      {items}
    </div>
  )
}

export default PlanningUnitHeader
