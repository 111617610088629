import BaseApi from '../BaseApi'
import buildFormData from '../buildFormData'
import buildGetQuery from '../buildGetQuery'

// This class handles all API interactions related to file uploads.
export default class {
  static all (params) {
    params = buildGetQuery(params || {})
    return BaseApi.sendRequest('GET', 'api/files/items.json', params)
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/files/items/${id}.json`)
  }

  static create (params) {
    // TODO: Avoid this by updating buildFormData() to handle cases like this?
    const data = buildFormData({
      'item[attachment]': params?.item?.attachment,
      'item[parentType]': params?.item?.parentType,
      'item[parentId]': params?.item?.parentId
    })

    return BaseApi.sendRequest('POST', 'api/files/items.json', data)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/files/items/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/files/items/${id}.json`)
  }

  static customsOptions () {
    return BaseApi.sendRequest('GET', 'api/files/items/customs_options.json')
  }
}
