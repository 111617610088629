// Contains various bits of "support" logic related to dates.
class DateHelper {
  // Find the prior Monday (unless it IS a Monday, in which case just use it):
  static startOfWeek (date) {
    return this.addDays(date, 0 - (date.getDay() ? date.getDay() - 1 : 6))
  }

  // Go forward/back by X days from a reference date:
  static addDays (date, days) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days)
  }

  // Format a date (or a string representing one) as DD/MM
  static formatShort (date) {
    return this.formatLong(date).split('/', 2).join('/') // Remove the year.
  }

  // Format a date (or a string representing one) as DD/MM/YY
  static formatLong (date) {
    if (!date) { return '--/--/--' }
    date = new Date(date) // In case it is a string.

    const days = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    return [days, month, date.getFullYear().toString().slice(2)].join('/')
  }
}

export default DateHelper
