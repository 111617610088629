import React from 'react'

import FilterToggle from './FilterToggle'

// This is used to filter job/trailers by status.
function StatusFilter (props) {
  return (
    <div className='trailer-planning-status-filter'>
      <FilterToggle enabled={props.showFullLoad} onClick={props.onFullLoadToggle}>
        Full Load
      </FilterToggle>
      {props.unassignedOnly ? null : (
        <React.Fragment>
          <FilterToggle enabled={props.showPending} onClick={props.onPendingToggle}>
            Pending
          </FilterToggle>
          <FilterToggle enabled={props.showArrived} onClick={props.onArrivedToggle}>
            Arrived
          </FilterToggle>
          <FilterToggle enabled={props.showInProgress} onClick={props.onInProgressToggle}>
            In progress
          </FilterToggle>
          <FilterToggle enabled={props.showComplete} onClick={props.onCompleteToggle}>
            Complete
          </FilterToggle>
        </React.Fragment>
      )}
    </div>
  )
}

export default StatusFilter
