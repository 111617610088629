import React from 'react'

import PlanningUnit from './PlanningUnit'
import PlanningUnitHeader from './PlanningUnitHeader'
import PlanningUnitFooter from './PlanningUnitFooter'

// This shows a list of planning units.
class PlanningUnitList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      expanded: false,
      sortColumn: 'name',
      sortAscending: true
    }
  }

  handleShowHide = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  handleSortChange = (event) => {
    if (event.target.value === this.state.sortColumn) {
      this.setState({ sortAscending: !this.state.sortAscending })
    } else {
      this.setState({ sortAscending: true, sortColumn: event.target.value })
    }
  }

  render () {
    const role = this.props.role || 'trailer'
    const mode = this.state.expanded ? 'expanded' : 'collapsed'
    const buttonStyle = `trailer-planning-planning-unit-list-show-hide ${mode}`

    const sortedUnits = this.props.units.sort((unitOne, unitTwo) => {
      const one = this.state.sortAscending ? 1 : -1 // Inverse 1 if descending.
      const foo = unitOne[this.state.sortColumn] || ''
      const bar = unitTwo[this.state.sortColumn] || ''
      return foo < bar ? -one : one
    })

    const items = sortedUnits.map(item => (
      <PlanningUnit
        key={item.id}
        unit={item}
        groupageId={this.props.groupageId}
        cotDotBookings={this.props.cotDotBookings}
      />
    ))

    return (
      <div className={`trailer-planning-planning-unit-list ${role}`}>
        <div className='trailer-planning-planning-unit-list-header'>
          <button className={buttonStyle} onClick={this.handleShowHide}>
            Show/Hide
          </button>
          <div className='trailer-planning-planning-unit-list-header-content'>
            {this.props.children}
          </div>
        </div>
        {this.state.expanded ? (
          <div className='trailer-planning-planning-unit-list-items'>
            <PlanningUnitHeader
              column={this.state.sortColumn}
              ascending={this.state.sortAscending}
              onClick={this.handleSortChange}
            />
            {items}
          </div>
        ) : null}
        <div className='trailer-planning-planning-unit-list-footer'>
          <PlanningUnitFooter
            units={this.props.units}
            capacity={this.props.capacity}
            groupageId={this.props.groupageId}
          />
        </div>
      </div>
    )
  }
}

export default PlanningUnitList
