import React from 'react'
import innerText from 'react-innertext'

// Components:
import Pagination from './Tables/Pagination'

// A basic table component.
class SimpleTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = { page: 0, perPage: 15, search: '' }
  }

  rangeStart () {
    return (this.state.page + 0) * this.state.perPage
  }

  rangeEnd () {
    return (this.state.page + 1) * this.state.perPage
  }

  rows () {
    return React.Children.toArray(this.props.children)
  }

  filteredRows () {
    const text = this.state.search.toLowerCase()
    if (!text) { return this.rows() }
    return this.rows().filter(r => innerText(r).toLowerCase().includes(text))
  }

  pageCount () {
    return Math.ceil(this.filteredRows().length / this.state.perPage)
  }

  resultCount () {
    const total = this.filteredRows().length
    const start = this.rangeStart() + 1
    const end = Math.min(this.rangeEnd(), total)
    return `Showing ${start} to ${end} of ${total} entries`
  }

  handlePageClick = (event, newPage) => {
    event.preventDefault()
    this.setState({ page: newPage })
  }

  handleSearchChange = (event) => {
    this.setState({ search: event.target.value, page: 0 })
  }

  render () {
    const headers = this.props.headers.map(
      header => <th key={header.label}>{header.label}</th>
    )

    const visibleRows = this.filteredRows().slice(this.rangeStart(), this.rangeEnd())

    return (
      <div className='table-primary'>
        <div className='dataTables_wrapper form-inline no-footer'>
          <div className='table-header clearfix'>
            <div className='table-caption'>{this.props.caption}</div>
            <div className='DT-lf-right'>
              <div className='DT-search'>
                <div className='dataTables_filter'>
                  <label>
                    <input
                      type='search'
                      className='form-control input-sm'
                      placeholder='Search...'
                      value={this.state.search}
                      onChange={this.handleSearchChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <table className='table table-striped table-bordered dataTable reactive'>
            <thead>
              <tr>{headers}</tr>
            </thead>
            <tbody>{visibleRows}</tbody>
          </table>
          <div className='table-footer clearfix'>
            <div className='DT-label'>
              <div className='dataTables_info'>{this.resultCount()}</div>
            </div>
            <div className='DT-pagination'>
              <div className='dataTables_paginate paging_simple_numbers'>
                <Pagination
                  current={this.state.page}
                  total={this.pageCount()}
                  onClick={this.handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SimpleTable
