import React from 'react'

// Components:
import PleaseWait from '../../../common/PleaseWait'
import SimpleTable from '../../../common/SimpleTable'
// APIs:
import GroupageApi from '../../../apis/consolidations/GroupageApi'
// Helpers:
import PersonHelper from '../../../helpers/PersonHelper'
// I18N:
import enums from '../../../i18n/enums'

// This table lists a set of groupages requiring customs inspections.
export default class extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      json: null,
      errors: []
    }
  }

  componentDidMount () {
    const params = {
      status: 'active|in_transit|arrived',
      inspectionRequired: true,
      inspectionComplete: this.props.complete
    }

    GroupageApi.all(params).then(
      json => this.setState({ json: json.items }),
      this.props.onError
    )
  }

  handleInspectionCompleteToggle (groupage) {
    if (!confirm(`Update ${groupage.groupageReference}?`)) { return }

    const params = { inspectionComplete: !this.props.complete }
    GroupageApi.update(groupage.id, { groupage: params }).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== groupage.id) }),
      this.props.onError
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const headers = [
      { label: 'Groupage' },
      { label: 'Status' },
      { label: 'GVMS Reference' },
      { label: 'GVMS Status' },
      { label: 'Inspection Type' },
      { label: 'Inspection Location' },
      { label: 'Handled By' },
      { label: 'Actions' }
    ]

    const formatKinds = (groupage) => {
      const inspections = (groupage.inspections || [])
      const values = inspections.map(i => i.inspectionType?.name || '(UNKNOWN)')
      return Array.from(new Set(values)).join('/') // Make them unique!
    }

    const formatLocations = (groupage) => {
      const inspections = (groupage.inspections || [])
      const values = inspections.map(i => i.locations).join(' ').split(' ')
      return Array.from(new Set(values)).join('/') // Make them unique!
    }

    const items = this.state.json.map(groupage => (
      <tr key={groupage.id}>
        <td>
          <a href={`/groupages/${groupage.id}`} target='_blank'>
            {groupage.groupageReference}
          </a>
        </td>
        <td>{enums.en.consolidations.groupages.statuses[groupage.status] || groupage.status}</td>
        <td>{groupage.gvmsReference}</td>
        <td>{groupage.gvmsStatus}</td>
        <td>{formatKinds(groupage)}</td>
        <td>{formatLocations(groupage)}</td>
        <td>{PersonHelper.fullName(groupage.groupageController)}</td>
        <td>
          <a
            className='btn btn-xs btn-primary'
            onClick={() => this.handleInspectionCompleteToggle(groupage)}
          >
            Mark as {this.props.complete ? 'Pending' : 'Complete'}
          </a>
        </td>
      </tr>
    ))

    return (
      <SimpleTable caption='Customs Inspections' headers={headers}>
        {items}
      </SimpleTable>
    )
  }
}
