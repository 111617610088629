import React from 'react'

// Components:
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
import NotificationCount from './NotificationCount'
import NotificationTable from './NotificationTable'

// A simple wrapper around the Notification tables to display them in tabs:
function NotificationTabs (props) {
  const tabs = []

  tabs.push(
    <React.Fragment>
      Unacknowledged
      <NotificationCount {...props} status='unacknowledged' />
    </React.Fragment>
  )

  tabs.push(
    <React.Fragment>
      In Progress
      <NotificationCount {...props} status='in_progress' />
    </React.Fragment>
  )

  tabs.push('Dealt With')

  return (
    <TabGroup labels={tabs}>
      <TabContent>
        <NotificationTable {...props} status='unacknowledged' />
      </TabContent>
      <TabContent>
        <NotificationTable {...props} status='in_progress' />
      </TabContent>
      <TabContent>
        <NotificationTable {...props} status='dealt_with' />
      </TabContent>
    </TabGroup>
  )
}

export default NotificationTabs
