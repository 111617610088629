import React from 'react'

const STANDARD_ICONS = {
  single_load: 'fa-cube',
  do_not_stack: 'fa-bars',
  do_not_transship: 'fa-text-height',
  waste: 'fa-trash',
  stack: 'fa-bars',
  foodstuffs: 'fa-cutlery',
  temperature_controlled: 'u-cold',
  hazardous: 'fa-flask',
  overlength: 'fa-arrows-h',
  must_load: 'fa-exclamation-circle',
  tall_packages: 'fa-arrow-up',
  tail_lift_on_collection: 'fa-level-up',
  tail_lift_on_delivery: 'fa-level-down',
  // Extra trailer icons:
  prioritise: 'fa-product-hunt',
  unaccompanied: 'fa-underline',
  ferry: 'fa-ship',
  adr: 'fa-flask'
}

function StandardIcon (props) {
  const icon = STANDARD_ICONS[String(props.type).downcase()] || 'fa-circle'

  return <i className={`fa ${icon}`} />
}

export default StandardIcon
