import BaseApi from '../BaseApi'
import buildGetQuery from '../buildGetQuery'

// This class handles all API interactions related to customs discrepancies.
class CustomsDiscrepancyApi {
  static all (params) {
    params = buildGetQuery(params || {})
    return BaseApi.sendRequest('GET', 'api/customs/ncts_discrepancies.json', params)
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/customs/ncts_discrepancies/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/customs/ncts_discrepancies.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/customs/ncts_discrepancies/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/customs/ncts_discrepancies/${id}.json`)
  }

  static kinds () {
    return BaseApi.sendRequest('GET', 'api/customs/ncts_discrepancies/kinds.json')
  }

  static recoveryOptions () {
    return BaseApi.sendRequest('GET', 'api/customs/ncts_discrepancies/recovery_options.json')
  }

  static communicationKinds () {
    return BaseApi.sendRequest('GET', 'api/customs/ncts_discrepancies/communication_kinds.json')
  }
}

export default CustomsDiscrepancyApi
