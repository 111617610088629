// This class provides utility functions related to dates and times.
class DateHelper {
  static formatDate (value) {
    const settings = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return this._format(value, settings)
  }

  // NOTE: .toISOString() takes the timezone into account, which bereaks things!
  static formatDateISO (value) {
    const parts = [value.getFullYear(), value.getMonth() + 1, value.getDate()]
    return parts.map(s => String(s).length < 2 ? `0${s}` : s).join('-')
  }

  static formatTime (value) {
    return this._format(value, { hour: 'numeric', minute: 'numeric' })
  }

  static formatDateTime (value) {
    return this._format(value, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })
  }

  static _format (value, options) {
    if (!value) { return '' }

    const date = new Date(value) // value is "2020-06-10T13:57:47+01:00"
    const formatter = new Intl.DateTimeFormat('en-GB', options)
    return formatter.format(date)
  }
}

export default DateHelper
