import React from 'react'

// A placeholder for when something is loading.
function PleaseWait (props) {
  return (
    <div className='please-wait'>
      <i className='fa fa-spinner fa-pulse fa-5x fa-fw' />
    </div>
  )
}

export default PleaseWait
