import React from 'react'

// Used by section headers with buttons/checkboxes/etc. on the right-hand side.
function FormHeader (props) {
  return (
    <div className='reactive-form-header'>
      <div className='reactive-form-header-title'>{props.title}</div>
      <div className='right-hand-items'>{props.children}</div>
    </div>
  )
}

export default FormHeader
