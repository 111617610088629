import React from 'react'

import PlannerIcon from '../common/PlannerIcon'

// This explains what the various icons mean.
function IconKey (props) {
  return (
    <div className='trailer-planning-icon-key'>
      <PlannerIcon kind='single-load' show={true}>
        Single Load
      </PlannerIcon>
      <PlannerIcon kind='do-not-stack' show={true}>
        Do Not Stack
      </PlannerIcon>
      <PlannerIcon kind='do-not-transship' show={true}>
        Do Not Transship
      </PlannerIcon>
      <PlannerIcon kind='waste' show={true}>
        Waste
      </PlannerIcon>
      <PlannerIcon kind='foodstuffs' show={true}>
        Food
      </PlannerIcon>
      <PlannerIcon kind='temperature' show={true}>
        Temperature
      </PlannerIcon>
      <PlannerIcon kind='hazardous' show={true}>
        Hazardous
      </PlannerIcon>
      <PlannerIcon kind='overlength' show={true}>
        Overlength
      </PlannerIcon>
      <PlannerIcon kind='must-load' show={true}>
        Must Load
      </PlannerIcon>
      <PlannerIcon kind='unaccompanied' show={true}>
        Unaccompanied
      </PlannerIcon>
      <PlannerIcon kind='ferry' show={true}>
        Ferry
      </PlannerIcon>
      <PlannerIcon kind='tall-packages' show={true}>
        Tall Packages
      </PlannerIcon>
      <PlannerIcon kind='tail-lift' show={true}>
        Tail Lift
      </PlannerIcon>
    </div>
  )
}

export default IconKey
