import BaseApi from '../BaseApi'

// This class handles all API interactions related to CRM Sales Market Status.
class SalesMarketStatusApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/crm/sales_market_statuses.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/crm/sales_market_statuses/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/crm/sales_market_statuses.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/crm/sales_market_statuses/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/crm/sales_market_statuses/${id}.json`)
  }

  static categories () {
    return BaseApi.sendRequest('GET', 'api/crm/sales_market_statuses/categories.json')
  }
}

export default SalesMarketStatusApi
