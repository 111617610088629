import React from 'react'

// APIs:
import FileItemApi from '../../apis/files/ItemApi'

// Used to allow drag-and-drop uploads via "about" sidebars.
export default class extends React.Component {
  handleUpload = (e) => {
    // This might be a drag/drop event, or just a manual "file selected" event:
    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files

    const params = {
      item: {
        parentId: this.props.parent.id,
        parentType: this.props.parent.modelName,
        attachment: files[0]
      }
    }

    FileItemApi.create(params).then(
      json => {
        const params = { dropzone: true, general_file_id: json.id }
        $.get('/bulk_general_file_updates/new', params)
      },
      json => {
        const errors = json.errors || [json.error]
        alert(errors.join('\n'))
      }
    )
  }

  render () {
    return (
      <div className='reactive-upload-dropzone'>
        <input
          type='file'
          value=''
          onDrop={this.handleUpload}
          onChange={this.handleUpload}
        />
      </div>
    )
  }
}
