import React from 'react'

// Represents a simple on/off checkbox.
function CheckBox (props) {
  const css = `switcher switcher-theme-square switcher-success ${props.value && 'checked'}`

  return (
    <div className='switchers-colors-square'>
      <div className={css} onClick={props.onChange}>
        <div className='switcher-toggler'></div>
        <div className='switcher-inner'>
          <div className='switcher-state-on'>
            <i className='fa fa-check'></i>
          </div>
          <div className='switcher-state-off'>
            <i className='fa fa-times'></i>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckBox
