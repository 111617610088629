import React from 'react'

// A generic "view" button for notifications.
function NotificationViewButton (props) {
  const a = (event) => { props.onClick(event, props.item) }

  return <a className="notification-view-button" href="#" onClick={a}>View</a>
}

export default NotificationViewButton
