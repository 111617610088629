import BaseApi from '../BaseApi'

// This class handles all API interactions related to complaint types.
class ComplaintTypeApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/complaints/types.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/complaints/types/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/complaints/types.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/complaints/types/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/complaints/types/${id}.json`)
  }
}

export default ComplaintTypeApi
