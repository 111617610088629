import React, { Component } from 'react'
import Select from 'react-select'
import Modal from 'react-modal'

import Button from '../Button'

import StateManager from '../StateManager'

const customStyles = {
  content: {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '35em',
    padding               : 0,
    overflow              : 'visible'
  }
}

const OPTIONS = ['pending', 'arrived', 'in_progress', 'complete']

const LABELS = {
  complete: 'Loaded/Discharged'
}

export default class ProgressSelector extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerProgressSelector(this)
    this.state = {
      modalIsOpen: false,
      booking_id:  null,
      progress:    null
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.submit = this.submit.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.options = OPTIONS.map(o => ({
      value: o,
      label: LABELS[o] || o.humanize()
    }))
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  afterOpenModal() {
    this.select.focus()
  }

  show(state) {
    state.modalIsOpen = true
    this.setState(state)
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.submit}
          onAfterOpen={this.afterOpenModal}
          contentLabel={"Bethanies are great"}
          style={customStyles}
        >
          <div className="progress-selector-modal modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {`Set Trailer Booking Progress`}
              </h4>
            </div>
            <div className="modal-body">
              <Select
                options={this.options}
                simpleValue={true}
                value={this.state.progress}
                onChange={this.setProgress.bind(this)}
                ref={x => this.select = x}
                clearable={false}
              />
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={this.submit}>
                Set Progress
              </button>
              <button className="btn btn-default" onClick={this.closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  setProgress(progress) {
    this.setState({ progress: progress })
  }

  submit() {
    this.manager.changeBookingProgress(
      this.state.booking_id,
      this.state.progress || ''
    )
    this.closeModal()
  }

}

