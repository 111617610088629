import React from 'react'

import InfoBox from '../common/InfoBox'
import PopOver from '../common/PopOver'

// Summarise hazardous goods information.
function HazardDetails (props) {
  if (!props.hazards) { return null }
  const ferry = props.hazards.ferry
  const tunnel = props.hazards.eurotunnel

  // The server doesn't send these as an array of objects for some reason...
  const items = props.hazards.dgli_titles.split('$$').map((title, n) => ({
    title: title,
    summary: props.hazards.dgli_summaries.split('$$')[n]
  }))

  const popOver = (
    <InfoBox header='Dangerous Goods Info'>
      {items.map((item, n) => (
        <div key={n} className='trailer-planning-hazard-details-item'>
          <div className='trailer-planning-hazard-details-item-title'>
            {item.title}
          </div>
          <div className='trailer-planning-hazard-details-item-summary'>
            {item.summary}
          </div>
        </div>
      ))}
    </InfoBox>
  )

  return (
    <div className='trailer-planning-hazard-details'>
      <PopOver title={popOver}>
        <div className='trailer-planning-hazard-details-general'>
          {props.hazards.dgli_classes}
        </div>
      </PopOver>
      <div className='trailer-planning-hazard-details-ferry' title={ferry}>
        {ferry}
      </div>
      <div className='trailer-planning-hazard-details-tunnel' title={tunnel}>
        {tunnel}
      </div>
    </div>
  )
}

export default HazardDetails
