import React, { Component } from 'react'

import Button from '../Button'
import { url_for } from '../Routes'

export default class IncludeJob extends Component {

  render() {
    return (
      <Button
        text="Include Job"
        type="warning"
        title="Flag a job as 'always show on planner'"
        disabled={!this.props.permissions.can_manage_job}
        href={url_for("include_trailer_planners_jobs_path")}
      />
    )
  }

// private

}
