import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import PleaseWait from '../../../common/PleaseWait'
import SimpleTable from '../../../common/SimpleTable'
// APIs:
import CustomsDiscrepancyApi from '../../../apis/customs/CustomsDiscrepancyApi'
// Helpers:
import DateHelper from '../../../helpers/DateHelper'
import IconHelper from '../../../helpers/IconHelper'
import PersonHelper from '../../../helpers/PersonHelper'
// I18N:
import enums from '../../../i18n/enums'

// This table lists a set of customs discrepancies.
class CustomsDiscrepancyTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      json: null,
      errors: []
    }
  }

  componentDidMount () {
    CustomsDiscrepancyApi.all({ resolved: this.props.resolved }).then(
      json => this.setState({ json: json.items }),
      this.props.onError
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    CustomsDiscrepancyApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      this.props.onError
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const headers = [
      { label: 'Our Reference' },
      { label: 'NCTS Shipment' },
      { label: 'Groupage' },
      { label: 'Kind' },
      { label: 'Deadline' },
      { label: 'Penalty?' },
      { label: 'Cost' },
      { label: 'Recovered From' },
      { label: 'Recovered?' },
      { label: 'Handled By' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(discrepancy => (
      <tr key={discrepancy.id}>
        <td>
          <Link to={`/reactive/customs/discrepancies/${discrepancy.id}`}>
            {discrepancy.reference}
          </Link>
        </td>
        <td>{discrepancy.nctsShipment?.reference}</td>
        <td>
          <a href={`/groupages/${discrepancy.nctsShipment?.groupage?.id}`} target='_blank'>
            {discrepancy.nctsShipment?.groupage?.groupageReference}
          </a>
        </td>
        <td>{enums.en.customs.discrepancies.kinds[discrepancy.kind] || discrepancy.kind}</td>
        <td>{DateHelper.formatDate(discrepancy.deadline)}</td>
        <td>{IconHelper.boolean(discrepancy.penalty)}</td>
        <td>{discrepancy.currency?.currencySymbol}{discrepancy.cost}</td>
        <td>{enums.en.customs.discrepancies.recoveredFroms[discrepancy.recoveredFrom] || discrepancy.recoveredFrom}</td>
        <td>{IconHelper.boolean(discrepancy.recovered)}</td>
        <td>{PersonHelper.fullName(discrepancy.user)}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/customs/discrepancies/${discrepancy.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(discrepancy.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <SimpleTable caption='Customs Discrepancies' headers={headers}>
        {items}
      </SimpleTable>
    )
  }
}

export default CustomsDiscrepancyTable
