import React from 'react'

import DateRangeFilter from './Filters/DateRangeFilter'
import DepartmentFilter from './Filters/DepartmentFilter'
import CountryFilter from './Filters/CountryFilter'
import StatusFilter from './Filters/StatusFilter'
import TextFilter from './Filters/TextFilter'

import FilteringHelper from '../helpers/FilteringHelper'

// This contains the various filters shown at the top of the trailer planner.
function FilterToolbar (props) {
  const origins = FilteringHelper.originsFrom(props.bookings, props.unassigned)
  const destinations = FilteringHelper.destinationsFrom(props.bookings, props.unassigned)

  return (
    <div className='trailer-planning-filter-toolbar'>
      <DateRangeFilter
        startDate={props.startDate}
        rangeType={props.rangeType}
        disabled={props.unassignedOnly}
        onPriorDate={props.onPriorDate}
        onNextDate={props.onNextDate}
        onRangeTypeToggle={props.onRangeTypeToggle}
      />
      <DepartmentFilter
        originCountry={props.originCountry}
        destinationCountry={props.destinationCountry}
        onOriginCountryChange={props.onOriginCountryChange}
        onDestinationCountryChange={props.onDestinationCountryChange}
      />
      <CountryFilter
        placeholder='All origins'
        value={props.originCountry}
        options={origins}
        onChange={props.onOriginCountryChange}
      />
      <CountryFilter
        placeholder='All destinations'
        value={props.destinationCountry}
        options={destinations}
        onChange={props.onDestinationCountryChange}
      />
      <StatusFilter
        unassignedOnly={props.unassignedOnly}
        showPending={props.showPending}
        showArrived={props.showArrived}
        showInProgress={props.showInProgress}
        showComplete={props.showComplete}
        showFullLoad={props.showFullLoad}
        onPendingToggle={props.onPendingToggle}
        onArrivedToggle={props.onArrivedToggle}
        onInProgressToggle={props.onInProgressToggle}
        onCompleteToggle={props.onCompleteToggle}
        onFullLoadToggle={props.onFullLoadToggle}
      />
      <TextFilter
        value={props.searchText}
        disabled={props.unassignedOnly}
        onChange={props.onSearchTextChange}
      />
    </div>
  )
}

export default FilterToolbar
