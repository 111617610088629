import React from 'react'

// Part of the top bar.
class NavbarToggleRight extends React.Component {
  constructor (props) {
    super(props)

    const hidden = sessionStorage.getItem('sidebarHidden') || false

    if (hidden) {
      this.hideSidebar()
    } else {
      this.showSidebar()
    }

    this.state = { hidden: hidden }
  }

  // TODO: Made the sidebar a React component and change it via function props.
  hideSidebar () {
    $('#sidebar-bg').hide()
    $('#sidebar').hide()
    $('#main-wrapper').removeClass('sidebar')
  }

  // TODO: Made the sidebar a React component and change it via function props.
  showSidebar () {
    $('#sidebar-bg').show()
    $('#sidebar').show()
    $('#main-wrapper').addClass('sidebar')

    // If sidebar validations are changed while the sidebar is hidden, the
    // following is called, but does nothing ... try again
    $('#sidebar-form-validation .notifications-list').slimScroll({ height: 'auto' })
  }

  handleClick = (event) => {
    event.preventDefault()

    if (this.state.hidden) {
      this.showSidebar()
      this.setState({ hidden: false })
      sessionStorage.setItem('sidebarHidden', false)
    } else {
      this.hideSidebar()
      this.setState({ hidden: true })
      sessionStorage.setItem('sidebarHidden', true)
    }
  }

  render () {
    return (
      <li className='nav-icon-btn nav-icon-btn-success visible-sm-block hidden-sm'>
        <a href='#' onClick={this.handleClick}>
          <i className='nav-icon fa fa-indent'></i>
          <span className='small-screen-text'>Show Quick Stats</span>
        </a>
      </li>
    )
  }
}

export default NavbarToggleRight
