import React from 'react'

import ConnectionIndicator from './ConnectionIndicator'
import IconKey from './IconKey'

// This is the "custom" navbar shown on the trailer planner screen.
function Navbar (props) {
  return (
    <div className='trailer-planning-navbar'>
      <a className='trailer-planning-navbar-logo' href='/' dangerouslySetInnerHTML={{ __html: props.logo }} />
      <a href='/trailer_planners'>Show old planner</a>
      <ConnectionIndicator connected={props.connected} />
      <IconKey />
    </div>
  )
}

export default Navbar
