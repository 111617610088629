import React from 'react'

// Components:
import withNotifications from './withNotifications'

// Used by the notification tabs.
function NotificationCount (props) {
  const css = props.items && props.items.length ? 'some' : 'none'
  const text = props.items ? props.items.length : '-'

  return <div className={`notification-count ${css}`}>{text}</div>
}

export default withNotifications(NotificationCount)
