import React from 'react'

// Used to contain the buttons at the bottom of a form.
function FormFooter (props) {
  return (
    <div className='panel-footer'>
      <div className='form-actions'>
        <div className='text-right'>{props.children}</div>
      </div>
    </div>
  )
}

export default FormFooter
