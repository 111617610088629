import React, { Component } from 'react'

import Popover from './Popover'
import Badge from './Badge'

export default class Problems extends Component {

  render() {
    return (
      <span className="problems" style={this.props.style || null}>
        {this._problems()}
      </span>
    )
  }

// private

  _problems() {
    if (this._isAllGood()) { return null }

    return (
      <Popover title="Possible Problems" content={this._list()}>
        <Badge color={this.props.major.any() ? 'danger' : 'warning'} icon="hazard" />
      </Popover>
    )
  }

  _list() {
    return `<ul class="list-group problems">
      ${this.props.major.map(p => `<li class="list-group-item major">${p}</li>`).join('')}
      ${this.props.minor.map(p => `<li class="list-group-item minor">${p}</li>`).join('')}
    </ul>`
  }

  _isAllGood() {
    return this.props.minor.empty() && this.props.major.empty()
  }
}
