import React, { Component } from 'react'

// Props
// page, max, setPage()

export default class Pagination extends Component {

  render() {
    const setPage = this.props.setPage
    const page = this.props.page
    const max = this.props.max

    return <ul className="pagination">
      <li 
        onClick={e => setPage(page - 1)} 
        className={page == 0 ? 'disabled' : 'paginate_button'}
      >
        <a>◀</a>
      </li>
      {this._pageNumbers()}
      <li
        onClick={e => setPage(page + 1)} 
        className={page >= max ? 'disabled' : 'paginate_button'}
      > 
        <a>▶</a>
      </li>
    </ul>
  }

  // n is the current page
  // This is a harder than it looks...
  _pageNumbers() {
    let n = this.props.page + 1
    let max = this.props.max + 1
    let options = [1, n - 2, n - 1, n, n + 1, n + 2, max]

    if (n == 3) { options.push(6) }
    if (n == 2) { options.push(5, 6) }
    if (n == 1) { options.push(4, 5, 6) }
    if (n == max) { options.push(n - 3, n - 4, n - 5) }
    if (n == max - 1) { options.push(n - 3, n - 4) }
    if (n == max - 2) { options.push(n - 3) }

    options = options.uniq()
                     .select(x => x >= 1 && x <= max)
                     .sort((x,y) => x > y)

    if (options[1] > 2) { options[1] = '...' }
    let p = options.length - 2
    if (options[p] < max - 1) { options[p] = '...' }

    return options.map((p, i) => this._pageLink(p, i))
  }

  _pageLink(n, key) {
    if (n == '...') {
      return <li key={key} className="paginate_button disabled"><a>...</a></li>
    }
    return <li
      key={key}
      onClick={e => this.props.setPage(n - 1)}
      className={`paginate_button ${this.props.page == n - 1 ? 'active' : ''}`}
    >
      <a>{n}</a>
    </li>
  }
}
