import DateHelper from './DateHelper'

// Extract a list of country codes from a set of trailer bookings and units:
function countriesFrom (bookings, unassigned, field) {
  if (!bookings || !unassigned) { return [] } // Loading...

  // Unassigned units:
  let result = unassigned.map(u => u[field])
  // Trailer bookings:
  result = result.concat(bookings.map(b => b[field]))
  // Units assigned to trailer bookings:
  bookings.forEach(b => { result = result.concat(b.units.map(u => u[field])) })

  return Array.from(new Set(result)).sort() // Ensure that no duplicates exist.
}

// Filter trailer bookings to match a set of origin/destination countries:
function bookingsForCountries (bookings, field, params) {
  const countries = params[field.replace('_c', 'C')] // originCountry
  if (!countries) { return bookings }

  return bookings.filter(booking => (
    countries.includes(booking[field]) ||
    booking.units.filter(u => countries.includes(u[field])).length
  ))
}

// Filter unassigned units to match a set of origin/destination countries:
function unitsForCountries (units, field, params) {
  const countries = params[field.replace('_c', 'C')] // originCountry
  if (!countries) { return units }

  return units.filter(unit => (
    countries.includes(unit[field]) || unit.show_on_planner === 'always'
  ))
}

// Contains the logic for filtering things, keeping it out of React components.
class FilteringHelper {
  static originsFrom (bookings, unassigned) {
    return countriesFrom(bookings, unassigned, 'origin_country')
  }

  static destinationsFrom (bookings, unassigned) {
    return countriesFrom(bookings, unassigned, 'destination_country')
  }

  static filterBookings (bookings, params) {
    if (!bookings) { return null }
    bookings = bookingsForCountries(bookings, 'origin_country', params)
    bookings = bookingsForCountries(bookings, 'destination_country', params)

    if (params.searchText) {
      const text = params.searchText.toUpperCase()

      bookings = bookings.filter(booking => (
        (booking.groupage || '').toUpperCase().includes(text) ||
        (booking.supplier || '').toUpperCase().includes(text) ||
        (booking.origin_hub || '').toUpperCase().includes(text) ||
        (booking.destination_hub || '').toUpperCase().includes(text) ||
        (booking.trailer_numbers || '').toUpperCase().includes(text) ||
        (booking.vehicle_registration || '').toUpperCase().includes(text) ||
        (booking.units || []).find(unit => (
          (unit.name || '').toUpperCase().includes(text)
        ))
      ))
    }

    // Hide bookings containing exactly one "full load" job...
    bookings = bookings.filter(booking => (
      params.showFullLoad || // ...unless we've opted to show them!
      (new Set(booking.units.map(u => u.job_id))).size !== 1 || // 0 or 2+ jobs
      !booking.units[0].single_load // One job, but it is NOT a "full load" job
    ))

    return bookings.filter(booking => (
      (booking.progress === 'pending' && params.showPending) ||
      (booking.progress === 'arrived' && params.showArrived) ||
      (booking.progress === 'in_progress' && params.showInProgress) ||
      (booking.progress === 'complete' && params.showComplete)
    ))
  }

  static filterUnits (units, params) {
    if (!units) { return null }
    units = unitsForCountries(units, 'origin_country', params)
    units = unitsForCountries(units, 'destination_country', params)

    if (params.searchText) {
      const text = params.searchText.toUpperCase()

      units = units.filter(unit => (
        (unit.name || '').toUpperCase().includes(text) ||
        (unit.customer || '').toUpperCase().includes(text) ||
        (unit.shipper || '').toUpperCase().includes(text) ||
        (unit.consignee || '').toUpperCase().includes(text) ||
        (unit.origin_hub || '').toUpperCase().includes(text) ||
        (unit.destination_hub || '').toUpperCase().includes(text) ||
        (unit.customs_status || '').toUpperCase().includes(text)
      ))
    }

    if (params.week) {
      const monday = DateHelper.startOfWeek(new Date())
      const offset = params.week === 'next' ? 7 : 0

      const startDate = DateHelper.addDays(monday, offset)
      const endDate = DateHelper.addDays(monday, offset + 6)

      units = units.filter(unit => {
        const ready = new Date(`${unit.ready_date || '1970-01-01'}T00:00:00`)
        return ready >= startDate && ready <= endDate
      })
    }

    return params.showFullLoad ? units : units.filter(unit => !unit.single_load)
  }
}

export default FilteringHelper
