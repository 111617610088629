import React from 'react'

// Displays a list of items within the left-hand "about" bar.
export default function (props) {
  const items = props.items.map((data, idx) => (
    <div key={idx} className='reactive-about-bar-item'>
      <div className='reactive-about-bar-item-label'>{data.label}</div>
      <a
        className={`reactive-about-bar-item-value ${data.theme}`}
        href={data.href}
        target='_blank'
      >
        {data.value}
      </a>
    </div>
  ))

  return (
    <div className='reactive-about-bar-item-list'>{items}</div>
  )
}
