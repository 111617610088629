import React from 'react'

import UnassignedTable from './UnassignedTable'
// import PleaseWait from '../../reactive/common/PleaseWait'

// This represents the "unassigned" section of the trailer planner screen.
function UnassignedSection (props) {
  if (!props.units) { return /* <PleaseWait /> */ null }

  const active = props.units.filter(item => item.status !== 'pending')
  const pending = props.units.filter(item => item.status === 'pending')

  return (
    <div className='trailer-planning-unassigned-section'>
      <UnassignedTable
        label='Unassigned'
        role='active'
        units={active}
        cotDotBookings={props.cotDotBookings}
      />
      <UnassignedTable
        label='Pending'
        role='pending'
        units={pending}
        cotDotBookings={props.cotDotBookings}
      />
    </div>
  )
}

export default UnassignedSection
