import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import ErrorList from '../../common/ErrorList'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import CustomsTradeTariffApi from '../../apis/customs/CustomsTradeTariffApi'
// Helpers:
import IconHelper from '../../helpers/IconHelper'

// This is the index page, which lists all customs tradeTariffs.
class CustomsTradeTariffIndex extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      json: null,
      errors: []
    }
  }

  componentDidMount () {
    document.title = 'Customs Trade Tariffs'

    CustomsTradeTariffApi.all().then(
      json => this.setState({ json: json.items }),
      json => this.setState({ errors: json.errors || [json.error] })
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    CustomsTradeTariffApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      json => this.setState({ errors: json.errors || [json.error] })
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/trade_tariffs': 'Customs Trade Tariffs'
    }

    const headers = [
      { label: 'Export Code' },
      { label: 'Import Code' },
      { label: 'Description' },
      { label: '2nd Quantity' },
      { label: '3rd Quantity' },
      { label: 'VAT Code' },
      { label: 'Duty Rate' },
      { label: 'Active' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(tradeTariff => (
      <tr key={tradeTariff.id}>
        <td>{tradeTariff.exportCode}</td>
        <td>{tradeTariff.importCode}</td>
        <td>{tradeTariff.description}</td>
        <td>{tradeTariff.secondQuantityCode}</td>
        <td>{tradeTariff.thirdQuantityCode}</td>
        <td>{tradeTariff.vatCode}</td>
        <td>{tradeTariff.dutyRate}</td>
        <td>{IconHelper.boolean(tradeTariff.active)}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/customs/trade_tariffs/${tradeTariff.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(tradeTariff.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/customs/trade_tariffs/new'>
              <i className='btn-label icon fa fa-plus' />
              New Trade Tariff
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <ErrorList errors={this.state.errors} />
        <SimpleTable caption='Customs Trade Tariffs' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default CustomsTradeTariffIndex
