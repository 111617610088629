

const SINGLE_LOAD = "Multiple jobs are assigned, despite one (or more) of them being flagged as single load."
const SUPPLIER_SINGLE_LOAD = "This supplier is flagged as single-load only."

const DANGEROUS_GOODS = "This trailer cannot carry dangerous goods."
const WASTE = "This trailer cannot carry waste."
const TEMPERATURE_CONTROLLED = "This trailer is not temperature controlled."

const OVERWEIGHT = "Gross weight exceeded"
const OVERCUBE = "Cubic metres exceeded"
const OVERLDM = "Loading metres exceeded"
const OVERHEIGHT = "Trailer height exceeded"

export default class BookingCheck {
  constructor(props) {
    this.booking = props.booking
    this.units = props.units
    this._populateProblems()
  }

  // private

  _populateProblems() {
    this.problems = { minor: [], major: [] }
    this._singleLoad()
    this._supplierSingleLoad()
    this._dangerousGoods()
    this._waste()
    this._temperatureControlled()
    this._overWeight()
    this._overCube()
    this._overLDM()
    this._overHeight()
    this._jobTypeMismatch()
    this._groupageMismatch()
  }

  _singleLoad() {
    if (this.units.length > 1 && this.units.any(u => u.single_load)) {
      this.problems.minor.push(SINGLE_LOAD)
    }
  }

  _supplierSingleLoad() {
    if (!this.booking.full_loads_only) { return }
    if (this.units.length > 1 || this.units.any(u => !u.single_load)) {
      this.problems.minor.push(SUPPLIER_SINGLE_LOAD)
    }
  }

  _dangerousGoods() {
    if (!this.booking.adr && this.units.any(u => u.dangerous_goods)) {
      let unit_dangerous_goods = this.units.select(u => u.dangerous_goods).map(u => u.dangerous_goods)
      if (!unit_dangerous_goods.all(udg => udg.ltd_qty)) {
        this.problems.major.push(DANGEROUS_GOODS)
      }
    }
    let dg = this.booking.dangerous_goods
    if (dg && dg.clashes) {
      dg.clashes.split("$$").each(c => this.problems.major.push(c))
    }
  }

  _waste() {
    if (!this.booking.waste && this.units.any(u => u.waste)) {
      this.problems.major.push(WASTE)
    }
  }

  _temperatureControlled() {
    if (!this.booking.temperature_controlled && this.units.any(u => u.temperature_controlled)) {
      this.problems.major.push(TEMPERATURE_CONTROLLED)
    }
  }

  _overWeight() {
    if (!this.booking.max_gross_weight_kg) { return }
    if (this.booking.max_gross_weight_kg < this.units.sum(u => u.gross_weight_kg || 0)) {
      this.problems.major.push(OVERWEIGHT)
    }
  }

  _overCube() {
    let max = parseFloat(this.booking.max_cubic_metres)
    if (max && max < this.units.sum(u => u.cubic_metres || 0)) {
      this.problems.major.push(OVERCUBE)
    }
  }

  _overLDM() {
    let max = parseFloat(this.booking.max_loading_metres)
    if (max && max < this.units.sum(u => u.loading_metres || 0)) {
      this.problems.major.push(OVERLDM)
    }
  }

  _jobTypeMismatch() {
    let mismatches = this.units.select(u => this.booking.country_code != u.country_code)
    if (mismatches.empty()) { return }
    let message

    if (mismatches.length == 1) {
      message = `${mismatches[0].name} does not match the job type of this trailer`
    } else {
      message = `${mismatches.map(u => u.name).toSentence()
        } do not match the job type of this trailer`
    }
    this.problems.minor.push(message)
  }

  _groupageMismatch() {
    let mismatches = this.units.select(u => this.booking.groupage_id != u.groupage_id)
    if (mismatches.empty()) { return }
    let message

    if (mismatches.length == 1) {
      message = `${mismatches[0].name} does not belong to the groupage for this trailer`
    } else {
      message = `${mismatches.map(u => u.name).toSentence()
                  } do not belong to the groupage for this trailer`
    }
    this.problems.minor.push(message)
  }

  _overHeight() {
    const findLargest = (max, cur) => Math.max(max, cur)
    const findSmallest = (min, cur) => Math.min(min, cur)
    const max_trailer = this.booking.trailers.map(el => el.height_m).reduce(findSmallest, Infinity) * 100
    const max_package = this.units.flatMap(el => el.dimensions_lines.map(xs => xs.height_cm)).reduce(findLargest, -Infinity)
    if (max_trailer && max_package && max_trailer < max_package) {
      this.problems.major.push(OVERHEIGHT)
    }
  }

}
