import sendRequest from './sendRequest'

// Like `.toISOString(), but without a time and ignoring time zone conversions:
function dateToISO (date) {
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const days = String(date.getDate()).padStart(2, '0')
  return [date.getFullYear(), month, days].join('-')
}

// Encapsulates all AJAX calls related to trailer bookings.
class TrailerBookingApi {
  static all (startDate, endDate) {
    const params = { start: dateToISO(startDate), end: dateToISO(endDate) }
    return sendRequest('GET', '/trailer_planners/bookings', params)
  }

  // TODO: We should have a GENERAL "update" action here...
  static update (id, params) {
    const path = `/trailer_planners/bookings/${id}/update_progress`
    return sendRequest('PATCH', path, { progress: params.progress })
  }
}

export default TrailerBookingApi
