import React, { Component } from 'react'

import FormTable from './FormTable'

const tableFormat = ({ names, directions, rates, weights, priceMethods, mins, maxes }) => (
  [
    ["", "", ...names],
    ["Direction", "", ...directions],
    ["Min", "Flat Rate", ...mins],
    ...rates.map((r, i) => [weights[i], priceMethods[i], ...r]),
    ["Max", "Flat Rate", ...maxes],
  ]
)

const apiFormat = (cells, rawIds) => {
  const [first, second, third, ...later] = cells
  const [,,...names] = first // remove first two cols
  const [,,...directions] = second // remove first two cols
  const [,,...mins] = third // remove first two cols
  const [,,...maxes] = later[later.length - 1]
  const rest = later.slice(0, -1)
  const weights = rest.map(row => row[0])
  const priceMethods = rest.map(row => row[1])
  const rates = rest.map(row => row.slice(2))
  const [,,...ids] = rawIds

  return { names, directions, weights, rates, mins, maxes, priceMethods, ids }
}

export default class Rates extends Component {

  state = {}

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit })
  }

  submit = (cells, ids) => {
    $.ajax({
      url: `/tariffs/distribution_managers`,
      method: 'POST',
      data: {
        utf8: "✓",
        ...apiFormat(cells, ids),
        nodeId: this.props.nodeId,
        category: this.props.category,
      }
    })
    this.setState({ edit: false })
  }

  render = () => (
    <div className="panel colourable">
      <div className="panel-heading">
        <span className="panel-title">Distribution</span>
        <div className="panel-heading-controls">
          <button className="btn btn-warning btn-labeled" onClick={this.toggleEdit}>
            <i className="btn-label icon fa fa-edit" />
            Edit Rates
          </button>
        </div>
      </div>
      <div className="panel-body">
        <div className="tariffs-supplier-rates">
          <FormTable
            headRowPlaceholder="Zone..."
            neckRow
            neckCol
            originType="readOnly"
            headColType="number"
            cellType="number"
            cells={tableFormat(this.props.zones)}
            ids={['-', '-', ...this.props.zones.ids]}
            onRequests={[null, null, ...this.props.zones.onRequests]}
            submit={this.submit}
            edit={this.state.edit}
          />
        </div>
      </div>
    </div>
  )

}
