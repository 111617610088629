import React from 'react'

import InfoBox from '../common/InfoBox'
import PopOver from '../common/PopOver'
import TrailerInformation from './TrailerInformation'

// Displays a trailer number, with information shown in a pop-over.
function TrailerBookingNumbers (props) {
  const unitNumber = props.booking.vehicle_registration
  const trailerType = props.booking.trailer_type || 'Unknown'
  const trailerNumbers = props.booking.trailers.map(trailer => trailer.number)

  const title = (
    <InfoBox header={`Unit Number: ${unitNumber || '---'} (${trailerType})`}>
      {props.booking.trailers.length ? props.booking.trailers.map(trailer => (
        <TrailerInformation key={trailer.number} trailer={trailer} />
      )) : 'No trailer information has been provided yet'}
    </InfoBox>
  )

  return (
    <div className='trailer-planning-trailer-booking-numbers'>
      <PopOver title={title}>
        {trailerNumbers.concat([unitNumber]).filter(s => s).join(' / ')}
      </PopOver>
    </div>
  )
}

export default TrailerBookingNumbers
