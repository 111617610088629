import React from 'react'

// Components:
import NotificationViewButton from './NotificationViewButton'
import NotificationActionButton from './NotificationActionButton'

// Helpers:
import DateHelper from '../../helpers/DateHelper'
import NotificationHelper from '../../helpers/events/NotificationHelper'

// An individual item within a notifications table.
function NotificationTableRow (props) {
  return (
    <tr className={`notification-table-row ${props.item.severity}`}>
      <td style={{ width: '100px' }}>
        {DateHelper.formatDateTime(props.item.createdAt)}
      </td>
      <td style={{ width: '60px' }}>
        <div className='severity'>{props.item.severity}</div>
      </td>
      <td style={{ minWidth: '20%' }}>
        {NotificationHelper.notifiedParties(props.item).join(' / ')}
      </td>
      <td style={{ minWidth: '15%' }}>
        {NotificationHelper.parentLink(props.item)}
      </td>
      <td style={{ minWidth: '20%' }}>
        {props.item.title}
      </td>
      <td style={{ minWidth: '20%' }}>
        {props.item.message}
      </td>
      <td style={{ width: '80px' }} className='no-wrap'>
        <div className='actions'>
          <NotificationViewButton item={props.item} onClick={props.onView} />
          <NotificationActionButton item={props.item} onClick={props.onAction} />
        </div>
      </td>
    </tr>
  )
}

export default NotificationTableRow
