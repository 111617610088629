import BaseApi from '../BaseApi'
import buildFormData from '../buildFormData'

// This class currently just handles the file upload for jobs.
class CustomsDataImportApi {
  static upload (jobId, file) {
    const data = buildFormData({ jobId: jobId, file: file })
    return BaseApi.sendRequest('POST', 'api/customs/data_import/upload', data)
  }
}

export default CustomsDataImportApi
