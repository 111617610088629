import React, { Component } from 'react'

import Routes, { url_for } from '../Routes'
import Button from '../Button'

import StateManager from '../StateManager'

const INDEX = url_for("trailer_planners_path", { multi_window: true })

export default class PopOut extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.state = { popout: false }
  }

  render() {
    return (
      <Button
        type="success pop-out"
        title={this._title()}
        icon={this.state.popout ? "window-maximize" : "window-restore"}
        href="#"
        onClick={this.onClick.bind(this)}
      />
    )
  }

  onClick() {
    if (!this.state.popout) { this._popOut() }
    this._togglePopOut()
    return false
  }

// private

  _title() {
    if (this.state.popout) {
      return "Show unassigned jobs"
    } else {
      return "Move unassigned jobs to new window"
    }
  }
  _togglePopOut() {
    this.manager.setPopOut(!this.state.popout)
    this.setState({ popout: !this.state.popout })
  }

  // SetItem null/undefined gives you the string "null"/"undefined" on the
  // other side. This is problematic for blank handling so use empty string.
  _popOut() {
    let country_filters = this.manager.getCountries()
    localStorage.setItem('tp-origins', country_filters.origin || "")
    localStorage.setItem('tp-destinations', country_filters.destination || "")
    localStorage.setItem('tp-department', this.manager.getDepartmentId() || "")

    window.open(
      INDEX,
      'newwindow',
      `width=${window.innerWidth || 960}, height=${window.innerHeight || 540}`
    )
  }

}
