import BaseApi from './BaseApi'

// This class handles all API interactions related to users.
class UserApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/users.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/users/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/users.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/users/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/users/${id}.json`)
  }

  static lock (id) {
    return BaseApi.sendRequest('POST', `api/users/${id}/lock.json`)
  }

  static unlock (id) {
    return BaseApi.sendRequest('POST', `api/users/${id}/unlock.json`)
  }

  static current () {
    return BaseApi.sendRequest('GET', 'api/users/current.json')
  }

  static updateCurrent (params) {
    return BaseApi.sendRequest('PUT', 'api/users/current.json', params)
  }
}

export default UserApi
