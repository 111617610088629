import React, { Component } from 'react'

export default class Icon extends Component {
  render() { 
    var type = "fa fa-" + this.props.icon
    return(
      <div className="icon" onClick={this.props.onClick || null}><i className={type} /></div>
    )
  }
}

