import React from 'react'

import CustomsDataImportApi from '../reactive/apis/customs/CustomsDataImportApi'
import showErrors from './showErrors'

// FMS-8266: Allow customs lines to be imported from a CSV file.
class UploadFileButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = { file: null, loading: false }
  }

  handleChange = (event) => {
    this.setState({ file: event.target.files[0] })
  }

  handleSubmit = () => {
    if (!this.props.id || !this.state.file) { return }

    this.setState({ loading: true })
    CustomsDataImportApi.upload(this.props.id, this.state.file).then(
      json => window.location.reload(),
      json => {
        this.setState({ loading: false })
        showErrors(json.errors || [json.error])
      }
    )
  }

  render () {
    return (
      <div className='input-group'>
        <span className='input-group-addon'>
          {this.state.loading ? (
            <i className='fa fa-spinner fa-spin'></i>
          ) : (
            <i className='fa fa-upload'></i>
          )}
        </span>
        <input
          className='form-control'
          type='file'
          accept='text/csv'
          onChange={this.handleChange}
        />
        <div className='input-group-btn'>
          <button
            className='btn btn-primary'
            disabled={this.state.loading || !this.state.file}
            onClick={this.handleSubmit}>
            Upload CSV
          </button>
        </div>
      </div>
    )
  }
}

export default UploadFileButton
