import React from 'react'

// Helpers:
import DateHelper from '../DateHelper'
import TextHelper from '../TextHelper'

// This class provides utility functions related to notifications.
class NotificationHelper {
  static parentLink (notification) {
    const kind = notification.parent.type
    const text = notification.parent.text

    let path = TextHelper.pluralize(TextHelper.underscore(kind))

    // The `Financial::` document routes don't fit the expected pattern:
    if (kind.startsWith('Financial::')) {
      const proforma = text.startsWith('Proforma')
      path = proforma ? 'proforma_invoices' : path.replace('financial/', '')
    }

    const href = `/${path}/${notification.parent.id}`
    return <a href={href} target='_blank'>{text}</a>
  }

  static notifiedParties (notification, includeNotYetNotified) {
    const result = [1, 2, 3].map(n => {
      const time = notification[`notify${n}`].time
      const whom = notification[`notify${n}`].text

      if (time) {
        return `${whom} (notified ${DateHelper.formatDateTime(time)})`
      } else if (includeNotYetNotified) {
        return `${whom} (not yet notified)`
      }

      return null
    })

    return result.filter(s => s)
  }
}

export default NotificationHelper
