import React, { Component } from 'react'

import DropDownMenu from '../DropDownMenu'
import Routes, { url_for } from '../Routes'

export default class Controls extends Component {

  render() {
    return <DropDownMenu icon="caret-down" options={this._options()} />
  }

// private

  _options() {
    let options = [
      {
        target: '_blank',
        name: "Picking List PDF",
        href: url_for("picking_list_trailer_bookings_pdf_path", this.props.id)
      },
      {
        'data-remote' : true,
        name: "Driver Running Sheet",
        href: url_for("plan_driver_running_sheet_trailer_bookings_pdf", this.props.id)
      },
      {
        'data-remote' : true,
        name: "Edit",
        href: url_for("edit_trailer_booking_path", this.props.id)
      }
    ]
    if (this.props.progress == 'pending') {
      options.push({
        'data-remote' : true,
        name:           'Delete',
        href:           url_for("trailer_booking_path", this.props.id),
        'data-confirm': 'Are you sure?',
        'data-method':  'delete'
      })
    }

    return options
  }

}
