import React, { Component } from 'react'
import { range, pick } from 'lodash'
import Papa from 'papaparse'
import Mousetrap from 'mousetrap'

import { FontAwesomeIcon } from '../font-awesome'
import { DropdownButton, DropdownOption, IconButton } from '../Buttons'

const NUMERIC = /\d+(\.\d*)?|\.\d+/

const pasteToCells = (clipboardData) => (
  Papa.parse(clipboardData.getData('text')).data
  .filter(row => row.some(x => x))
)

const numeric = (val) => {
  try { return val.match(NUMERIC)[0] }
  catch (e) { return '' }
}

const sanitizeCells = (data) => {
  const columns = [...data.map(c => c.length), 2].max()
  const cells = data.map(d => [...d])
  while (cells.length < 2) { cells.push([]) }
  cells.forEach(row => {
    while (row.length < columns) { row.push('') }
  })

  return cells
}

const initialState = (props) => ({
  cells: sanitizeCells(props.cells || []),
  ids: props.ids,
})

const PRICE_METHODS = ["Flat Rate", "Per 100kg", "Per 1kg"]

const PriceMethodSelect = (props) => (
  <select {...props}>
    {PRICE_METHODS.map(m => <option key={m} value={m}>{m}</option>)}
  </select>
)

const DIRECTIONS = ["Both", "Collection", "Delivery"]

const DirectionSelect = (props) => (
  <select {...props}>
    {DIRECTIONS.map(m => <option key={m} value={m}>{m}</option>)}
  </select>
)

const Conditional = ({ children, ...props }) => (
  props.if ? children : null
)

const BLANK_STATE = [
  ['Zone', '', ''],
  ['Direction', '', ''],
  ['Min', 'Flat Rate', ''],
  ['', 'Flat Rate', ''],
  ['Max', 'Flat Rate', ''],
]

const getZoneModal = (id) => {
  $.ajax({
    url: `/tariffs/distribution_zones/${id}/edit`,
    method: 'get',
  })
}

const ZoneModalOrDead = ({ id }) => {
  if (parseFloat(id)) {
    return (
      <td>
        <button className="btn-warning" onClick={() => getZoneModal(id)}>
          Edit Zone
        </button>
      </td>
    )
  } else {
    return <td className="dead"><input disabled /></td>
  }
}

// Cells are row-major nested array: cells[row][column] = value
// They must not be mutated to allow for undo/redo time travel

export default class FormTable extends Component {

  state = initialState(this.props)
  history = [this.state]
  historyIndex = 0
  rowChanges = 0
  colChanges = 0

  recordState(next) {
    this.historyIndex += 1
    this.history = [
      ...this.history.slice(0, this.historyIndex),
      { ...this.state, ...next }
    ]
    this.setState(next)
  }

  undo = () => {
    this.timeTravel(-1)
  }

  redo = () => {
    this.timeTravel(1)
    return false // prevent ctrl+r making browser reload page
  }

  timeTravel = (change) => {
    const i = this.historyIndex + change
    const table = this.history[i]
    if (!table) { return }
    this.historyIndex = i
    this.setState(table)
  }

  componentDidMount() {
    Mousetrap.bind('ctrl+z', this.undo)
    Mousetrap.bind('ctrl+r', this.redo)
  }

  componentWillUnmount() {
    Mousetrap.unbind('ctrl+z', this.undo)
    Mousetrap.unbind('ctrl+r', this.redo)
  }

  resize = (rows, cols) => {
    const cells = this.state.cells.map(c => [...c])
    cols = [cols, cells[0].length].max()

    while (cells.length < rows) { cells.push([]) }
    cells.forEach(c => {
      while (c.length < cols) { c.push('') }
    })

    return cells
  }

  cellOnPaste = ({ currentTarget: { name }, clipboardData }) => {
    const [i, j] = name.split(':').map(x => parseFloat(x))
    const paste = pasteToCells(clipboardData)
    this.pasteData(paste, i, j)
  }

  pasteData = (paste, cell_i, cell_j) => {
    const cells = this.resize(
      paste.length + cell_i,
      paste.map(r => r.length).max() + cell_j
    )

    paste.forEach((row, paste_i) => {
      row.forEach((val, paste_j) => {
        const i = cell_i + paste_i
        const j = cell_j + paste_j
        cells[i][j] = this.newCellValue(val, i, j)
      })
    })

    this.pasteState({ cells })
  }

  newCellValue = (val, i, j) => {
    const type = this.cellType(i, j)
    if (type == 'readOnly') { return this.state.cells[i][j] }
    if (type == 'number') { return numeric(val) }
    return val
  }

  cellType = (i, j) => {
    if (!this.props.edit) { return 'readOnly' }
    if (i == 0 && j == 0) { return 'readOnly' }
    if (i == 0) { return this.props.headRowType }
    if (j == 0) { return this.props.headColType }
    if (i == 1) { return this.props.neckRowType }
    if (j == 1) { return this.props.neckColType }
    return this.props.cellType
  }

  // the regular onChange event/browser will try to put the paste contents
  // into the single cell selected, we don't want that. This is a hacky
  // solution but other quickfixes didn't seem to work
  pasteState = (next) => {
    setTimeout(() => this.recordState(next), 50)
  }

  headRow = () => (
    range(2, this.state.cells[0].length).map(j =>
    <th key={j}>
      <input
        value={this.state.cells[0][j]}
        name={`0:${j}`}
        className="head-row"
        type="text"
        placeholder="Name..."
        onChange={this.cellOnChange}
        onPaste={this.cellOnPaste}
        disabled={!this.props.edit}
      />
    </th>
    )
  )

  neckRow = () => (
    range(2, this.state.cells[1].length).map(j =>
    <th key={j}>
      <DirectionSelect
        value={this.state.cells[1][j]}
        name={`1:${j}`}
        onChange={this.cellOnChange}
        onPaste={this.cellOnPaste}
        disabled={!this.props.edit}
      />
    </th>
    )
  )

  thead = () => (
    <thead>
      <tr>
        <th className="col-label">
          <input
            value="Zone"
            name={`0:0`}
            disabled={!this.props.edit}
            onPaste={this.cellOnPaste}
            onChange={null}
          />
          <FontAwesomeIcon icon="caret-right" />
        </th>
        <th className="col-label dead">
          <input
            name="0:1"
            onPaste={this.cellOnPaste}
            onChange={null}
            disabled={!this.props.edit}
            value=""
          />
        </th>
        {this.headRow()}
        <Conditional if={this.props.edit}>
          <th>
            <button className="btn-primary" onClick={this.addColumn}>Add Column</button>
          </th>
        </Conditional>
      </tr>
      <tr>
        <th className="col-label">
          <input
            value="Direction"
            name="1:0"
            onPaste={this.cellOnPaste}
            onChange={null}
            disabled={!this.props.edit}
          />
          <FontAwesomeIcon icon="caret-right" />
        </th>
        <th className="col-label dead">
          <input
            name="1:1"
            onPaste={this.cellOnPaste}
            onChange={null}
            value=""
            disabled={!this.props.edit}
          />
        </th>
        {this.neckRow()}
        <Conditional if={this.props.edit}>
          <th className="dead"><input disabled /></th>
        </Conditional>
      </tr>
    </thead>
  )

  cellOnChange = ({ target: { name, value } }) => {
    const [i, j] = name.split(':')
    const cells = this.state.cells.map(c => [...c])
    cells[i][j] = value
    this.recordState({ cells })
  }

  addColumn = () => {
    this.insertColumn(this.state.cells[0].length)
  }

  addRow = () => {
    this.insertRow(this.state.cells.length - 1)
  }

  headColType = (row) => {
    if (row < 3 || (row + 1) == this.state.cells.length) { return "text" }
    return "number"
  }

  tableRows = () => (
    range(2, this.state.cells.length).map(i => this.tableRow(i))
  )

  minMaxRow = (row) => (
    row < 3 || (row + 1) == this.state.cells.length
  )

  tableRow = (row) => (
    <tr key={row}>
      <td>
        <input
          type={this.minMaxRow(row) ? 'text' : 'number'}
          className="head-col-value"
          name={`${row}:0`}
          placeholder="up to ... kg"
          value={this.state.cells[row][0]}
          onPaste={this.cellOnPaste}
          onChange={this.minMaxRow(row) ? null : this.cellOnChange}
          disabled={!this.props.edit}
        />
      </td>
      <td>
        <PriceMethodSelect
          name={`${row}:1`}
          value={this.state.cells[row][1]}
          onPaste={this.cellOnPaste}
          disabled={!this.props.edit || this.minMaxRow(row)}
          onChange={this.minMaxRow(row) ? null : this.cellOnChange}
        />
      </td>
      {range(2, this.state.cells[row].length).map(col =>
      <td key={col}>
        <input
          type={this.props.cellType}
          className="cell"
          name={`${row}:${col}`}
          value={this.state.cells[row][col]}
          onChange={this.cellOnChange}
          onPaste={this.cellOnPaste}
          disabled={!this.props.edit || this.props.onRequests[col]}
          placeholder={this.props.onRequests[col] ? 'On Request' : null}
        />
      </td>
      )}
      <Conditional if={this.props.edit}>
        <td>{this.rowControl({ row: row })}</td>
      </Conditional>
    </tr>
  )

  deleteRow = ({ currentTarget: { dataset: { row } } }) => {
    const cells = [...this.state.cells]
    cells.splice(row, 1)
    this.rowChanges += 1
    this.recordState({ cells })
  }

  insertRowBelow = ({ currentTarget: { dataset: { row } } }) => {
    this.insertRow(1 + parseFloat(row))
  }

  insertRowAbove = ({ currentTarget: { dataset: { row } } }) => {
    this.insertRow(row)
  }

  insertRow = (i) => {
    const cells = [...this.state.cells]
    cells.splice(i, 0, this.state.cells[0].map(c => ''))
    cells[i][1] = 'Flat Rate'
    this.rowChanges += 1
    this.recordState({ cells })
  }

  rowControl = ({ row }) => (
    <DropdownButton icon="bars" label="" key={`${this.rowChanges}-${row}`}>
      <Conditional if={row > 2}>
        <DropdownOption
          icon="arrow-up"
          label="Insert Row Above"
          data-row={row}
          onClick={this.insertRowAbove}
        />
      </Conditional>
      <Conditional if={row < (this.state.cells.length - 1)}>
        <DropdownOption
          icon="arrow-down"
          label="Insert Row Below"
          data-row={row}
          onClick={this.insertRowBelow}
        />
      </Conditional>
      <Conditional if={!this.minMaxRow(row)}>
        <DropdownOption
          icon="trash"
          label="Delete Row"
          data-row={row}
          onClick={this.deleteRow}
          className="danger"
        />
      </Conditional>
    </DropdownButton>
  )

  deleteColumn = ({ currentTarget: { dataset: { col } } }) => {
    const cells = this.state.cells.map(c => {
      const next = [...c]
      next.splice(col, 1)
      return next
    })
    const ids = [...this.state.ids]
    ids.splice(col, 1)
    this.colChanges += 1
    this.recordState({ cells, ids })
  }

  insertColumn = (i) => {
    const cells = this.state.cells.map(c => {
      const next = [...c]
      next.splice(i, 0, '')
      return next
    })
    const ids = [...this.state.ids]
    ids.splice(i, 0, 'new')
    this.colChanges += 1
    this.recordState({ cells, ids })
  }

  insertColumnLeft = ({ currentTarget: { dataset: { col } } }) => {
    this.insertColumn(col)
  }

  insertColumnRight = ({ currentTarget: { dataset: { col } } }) => {
    this.insertColumn(1 + parseFloat(col))
  }

  columnControl = ({ col }) => (
    <DropdownButton icon="bars" label="" key={`${this.colChanges}-${col}`}>
      <DropdownOption
        icon="arrow-left"
        label="Insert Column Left"
        data-col={col}
        onClick={this.insertColumnLeft}
      />
      <DropdownOption
        icon="arrow-right"
        label="Insert Column Right"
        data-col={col}
        onClick={this.insertColumnRight}
      />
      <DropdownOption
        icon="trash"
        label="Delete Column"
        data-col={col}
        onClick={this.deleteColumn}
        className="danger"
      />
    </DropdownButton>
  )

  clear = () => {
    this.rowChanges += 1
    this.colChanges += 1
    this.recordState({ cells: BLANK_STATE })
  }

  setFileUploader = (node) => {
    this.fileUploader = node
  }

  openFileUploader = () => {
    this.fileUploader.click()
  }

  upload = ({ target: { files } }) => {
    if (!files[0]) { return }
    Papa.parse(files[0], { complete: this.loadCSV })
  }

  loadCSV = (results, file) => {
    const data = results.data.filter(row => row.some(x => x))
    this.pasteData(data, 0, 0)
  }

  onDrop = ({ dataTransfer: { items } }) => {
    if (items[0].kind != 'file') { return }
    Papa.parse(items[0].getAsFile(), { complete: this.loadCSV })
  }

  masterControl = () => (
    <DropdownButton
      className="btn-warning"
      icon="bars"
      label=""
      key={`${this.colChanges}-${this.historyIndex}`}
    >
      <DropdownOption
        icon="undo"
        label="Undo"
        onClick={this.undo}
        className={this.historyIndex < 1 ? 'disabled' : ''}
        title="Ctrl+Z"
      />
      <DropdownOption
        icon="repeat"
        label="Redo"
        onClick={this.redo}
        className={this.historyIndex <= this.history.length ? 'disabled' : ''}
        title="Ctrl+R"
      />
      <DropdownOption
        icon="file-excel-o"
        label="Upload CSV"
        onClick={this.openFileUploader}
        title="You can also drag and drop CSV files onto the table"
      />
      <DropdownOption
        icon="exclamation-triangle"
        label="Delete all"
        onClick={this.clear}
        className="danger"
      />
      <input
        type="file"
        className="hidden"
        ref={this.setFileUploader}
        onChange={this.upload}
        disabled={!this.props.edit}
      />
    </DropdownButton>
  )

  controlRow = () => (
    <Conditional if={this.props.edit}>
      <tr>
        <td>
          <button onClick={this.addRow} className="btn-primary">Add Row</button>
        </td>
        <td className="dead"><input disabled /></td>
        {range(2, this.state.cells[0].length).map(i =>
        <td key={i}>{this.columnControl({ col: i })}</td>
        )}
        <td>{this.masterControl()}</td>
      </tr>
    </Conditional>
  )

  zoneModalRow = () => (
    <Conditional if={!this.props.edit}>
      <tr>
        {this.state.ids.map((id, i) => <ZoneModalOrDead id={id} key={i} />)}
      </tr>
    </Conditional>
  )

  submit = () => {
    this.props.submit(this.state.cells, this.state.ids)
  }

  render = () => (
    <div className="form-table js-disable-select2">
      <table onDrop={this.onDrop}>
        {this.thead()}
        <tbody>
          {this.tableRows()}
          {this.controlRow()}
          {this.zoneModalRow()}
        </tbody>
      </table>
      <Conditional if={this.props.edit}>
        <div className="controls">
          <button className="btn btn-success btn-labeled" onClick={this.submit}>
            <span className="btn-label icon fa fa-save" /> Save
          </button>
        </div>
      </Conditional>
    </div>
  )

}
