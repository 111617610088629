import React, { Component } from 'react'

import Routes, { url_for } from '../Routes'

export default class Groupage extends Component {

  render() {
    return (
      <div>
        <span className="name" {...this._spanAttrs()}>
          {this.props.name || 'No groupage'}
        </span>
        {this.props.id ? this._link() : this._newLink()}
      </div>
    )
  }

// private

  _spanAttrs() {
    if (!this.props.controller) { return {} }
    return {
      title: `Controlled by ${this.props.controller}`,
      style: { cursor: 'help' }
    }
  }

  _link() {
    return (
      <a
        target="_blank"
        className="input-group-addon btn btn-xs btn-primary"
        href={url_for("groupage_path", this.props.id)}
      >
        <i className="fa fa-ellipsis-h" />
      </a>
    )
  }

  _newLink() {
    return (
      <a
        data-confirm="Create a new Groupage for this trailer?"
        className="input-group-addon btn btn-xs btn-primary"
        data-remote="true"
        rel="nofollow"
        data-method="post"
        href={url_for("create_groupage_trailer_planners_booking_path", this.props.booking_id)}
      >
        <i className="fa fa-plus" />
      </a>
    )
  }

}

