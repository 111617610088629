import React from 'react'

// Contains a list of FormField (or ShowField) fields.
function FieldList (props) {
  return (
    <div className='form-horizontal'>
      <div className='row'>{props.children}</div>
    </div>
  )
}

export default FieldList
