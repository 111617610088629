import React from 'react'

import EmissionsHelper from '../reactive/helpers/EmissionsHelper'

// Displays a summary for a single job.
function JobSummary (props) {
  const distance = props.job.breakdown.map(
    r => r.actualDistance || r.estimatedDistance
  ).filter(s => s).sum()

  const problems = props.job.breakdown.map(
    r => (r.actualDistance || r.estimatedDistance) ? null : 'Unknown Distance'
  ).filter(s => s)

  // Calculate the equivalent distance in miles, in case the user wants that:
  const miles = EmissionsHelper.kilometresToMiles(distance)
  const title = miles ? `${EmissionsHelper.formatDistance(miles)} miles` : null

  // FMS-9365: Display the applicable incoterm, if known.
  const incoterms = [props.job.incoterm?.incoterm, props.job.incotermVariant]

  return (
    <div className='emissions-job-summary'>
      <div className='emissions-job-summary-reference'>
        <a href={`/jobs/${props.job.id}`} target="_blank">
          {props.job.jobReference}
        </a>
      </div>
      <div className='emissions-job-summary-incoterm'>
        {incoterms.filter(s => s).join(' - ')}
      </div>
      <div className='emissions-job-summary-distance' title={title}>
        {problems.length ? (
          <i
            className='fa fa-exclamation-triangle text-warning'
            title='One or more parts of the journey could not be calculated.'
          />
        ) : null}
        {EmissionsHelper.formatDistance(distance)}km
      </div>
    </div>
  )
}

export default JobSummary
