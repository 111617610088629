import React, { Component } from 'react'

import Popover from '../Popover'

export const TRAILER_INFO_PROPS = [
  'trailer_numbers',
  'vehicle_registration',
  'trailers',
  'trailer_type'
]

const MISSING = `<i class="text-primary">???</i>`

export default class TrailerInfo extends Component {

  render() {
    return <Popover title={this._title()} content={this._content()}>
      {this._registrations()}
    </Popover>
  }

  _registrations() {
    return [
      this.props.trailer_numbers,
      this.props.vehicle_registration
    ].select(x => x).join(" / ") || '???'
  }

  // Popover content neds to be raw string
  _content() {
    return `<ul class="list-group trailer-info">
      ${this.props.trailers.length == 0 ? this._blank() : this._trailers()}
    </ul>`
  }

  _blank() {
    return `<li>
      No trailer information has been provided yet.
    </li>`
  }

  _trailers() {
    return this.props.trailers.map(t => (
      `<li>
        <b>Trl No: ${t.number} </b> - ${t.length_m || MISSING} x ${
          t.width_m || MISSING} x ${t.height_m || MISSING}m
      </li>`
    )).join('')
  }

  _title() {
    let title = `Unit Number: ${this.props.vehicle_registration || MISSING}`
    if (this.props.trailer_type) { title += ` (${this.props.trailer_type})` }

    return title
  }

}
