import React from 'react'

// Encapsulates the standard page layout, with an (optional) left-hand sidebar.
export default function (props) {
  return (
    <div className='reactive-standard-layout'>
      <div className='reactive-standard-layout-left'>
        {props.sidebar}
      </div>
      <div className='reactive-standard-layout-right'>
        {props.children}
      </div>
    </div>
  )
}
