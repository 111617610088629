import React from 'react'

import SentEmail from './list/SentEmail'
// Common Components:
import PleaseWait from '../../common/PleaseWait'
// APIs:
import EmailEnvelopeApi from '../../apis/emails/EmailEnvelopeApi'

// Displays a list of emails, POSSIBLY attached to a parent model.
export default class extends React.Component {
  constructor (props) {
    super(props)

    this.state = { emails: null }
  }

  componentDidMount () {
    const params = {
      parentId: this.props.model.id,
      parentType: this.props.model.modelName,
      order: 'desc' // Most-recent at the top.
    }

    EmailEnvelopeApi.all(params).then(
      json => this.setState({ emails: json.items }),
      this.props.onError
    )
  }

  handleDestroy (email) {
    if (!confirm(`Really delete "${email.subject}"?`)) { return }

    // Get a list of everything EXCEPT the one email we want to delete:
    const newEmails = this.state.emails.filter(item => item.id !== email.id)

    EmailEnvelopeApi.destroy(email.id).then(
      () => this.setState({ emails: newEmails }),
      this.props.onError
    )
  }

  render () {
    if (!this.state.emails) { return <PleaseWait /> }
    if (!this.state.emails.length) { return <i>No emails have been sent...</i> }

    // FIXME: Don't show the Destroy button for users without that permission.
    const items = this.state.emails.map(email => (
      <SentEmail
        key={email.id}
        email={email}
        onDestroy={() => this.handleDestroy(email)}
      />
    ))

    return <div className='reactive-list-of-sent-emails'>{items}</div>
  }
}
