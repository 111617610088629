import React, { Component } from 'react'

import Icon from './Icon'
import UnitBlock from './UnitBlock'
import StateManager from './StateManager'
import BlockExpander from './BlockExpander'

export default class Unassigned extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
  }

  render() {
    var classes = `unassigned ${this.props.id}`
    return(
      <div className={classes}>
        <div className="head">
          <Icon onClick={this.expandCollapse.bind(this)} icon="archive" />
          <div className="details" style={this._detailsStyle()}>
            <span>{this.props.title}</span>
          </div>
          <WeekPicker id={this.props.id} />
          <Search id={this.props.id} />
          <div className="controls">
            <BlockExpander id={this.props.id} />
          </div>
        </div>
        <UnitBlock id={this.props.id} unassigned={true} />
      </div>
    )
  }

  _detailsStyle() {
    let width = this.manager.columnOffset('origin_hub')
    return { width: `${width}%` }
  }

  expandCollapse(e) {
    this.manager.toggleBlockExpanded(this.props.id)
  }
}

const WEEK_OPTIONS = ["All Weeks", "This Week", "Next Week"]

class WeekPicker extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.state = { week: this.manager.getWeekFilter(this.props.id) }
  }

  _style() {
    return {
      width: `${this.manager.multiColumnWidth(['origin_hub', 'destination_hub'])}%`
    }
  }

  render() {
    return <div className="week-picker js-disable-select2" style={this._style()}>
      <select value={this.state.week} onChange={this.onChange.bind(this)}>
        {WEEK_OPTIONS.map(o => <option value={o} key={o}>{o}</option>)}
      </select>
    </div>
  }

  onChange(e) {
    this.setState({ week: e.currentTarget.value })
    this.manager.setWeekFilter(this.props.id, e.currentTarget.value)
  }

}

class Search extends Component {
  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.state = { value: this.manager.getSearchFilter(this.props.id) || ''}
  }

  _style() {
    return {
      width: `${this.manager.multiColumnWidth(['collection', 'delivery'])}%`
    }
  }

  render() {
    return (
      <input
        style={this._style()}
        type="search"
        className="form-control search"
        placeholder="Search..."
        onChange={this.onChange.bind(this)}
        value={this.state.value}
      />
    )
  }

  onChange(e) {
    this.manager.setSearchFilter(this.props.id, e.currentTarget.value)
    this.setState({ value: e.currentTarget.value })
    return true
  }
}
