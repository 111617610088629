import React from 'react'

// Components:
import NotificationList from './NotificationList'
import withNotifications from './withNotifications'

// Part of the top bar.
class NotificationIndicatorTile extends React.Component {
  constructor (props) {
    super(props)

    this.state = { showDropDown: false }
  }

  handleClick = (event) => {
    event.preventDefault()

    // Expand/Collapse the drop-down:
    this.setState({ showDropDown: !this.state.showDropDown })
    // Get rid of the "new items" styling:
    this.props.disableAlert()
  }

  render () {
    // If we haven't loaded any notifications yet, show a loading icon instead:
    const icon = this.props.items ? 'fa-bullhorn' : 'fa-spinner fa-pulse'
    // If we have new messages, change the component appearance in some way:
    const style = this.props.alert ? 'new-messages' : 'old-messages'

    return (
      <li className='navbar-notifications nav-icon-btn nav-icon-btn-danger'>
        <a href='#' onClick={this.handleClick} className={style}>
          {this.props.items && this.props.items.length ? (
            <span className='label'>{this.props.items.length}</span>
          ) : null}
          <i className={`nav-icon fa ${icon}`}></i>
          <span className='small-screen-text'>Notifications</span>
        </a>
        {this.state.showDropDown && (
          <NotificationList
            items={this.props.items}
            onView={this.props.onView}
            onAction={this.props.onAction}
          />
        )}
      </li>
    )
  }
}

export default withNotifications(NotificationIndicatorTile)
