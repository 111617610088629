import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import SalesJournalEntryTypeApi from '../../apis/crm/SalesJournalEntryTypeApi'

// This is the index page, which lists all CRM sales journal entry types.
class SalesJournalEntryTypeIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'CRM Sales Journal Entry Types'

    SalesJournalEntryTypeApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    SalesJournalEntryTypeApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/crm/sales_journal_entry_types': 'Sales Journal Entry Types'
    }

    const headers = [
      { label: 'Name' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(salesJournalEntryType => (
      <tr key={salesJournalEntryType.id}>
        <td>{salesJournalEntryType.name}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/crm/sales_journal_entry_types/${salesJournalEntryType.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(salesJournalEntryType.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/crm/sales_journal_entry_types/new'>
              <i className='btn-label icon fa fa-plus' />
              New Sales Journal Entry Type
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <SimpleTable caption='CRM Sales Journal Entry Types' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default SalesJournalEntryTypeIndex
