import React from 'react'

import DropdownButton from '../../reactive/common/DropdownButton'

// Display a list of existing discrepancies, along with a "create new" option.
function NctsDiscrepanciesSummary (props) {
  const count = props.nctsShipment.discrepancies.filter(d => !d.resolved).length
  const theme = count ? 'warning' : 'success'
  const image = count ? 'fa-exclamation-circle' : 'fa-check-circle'

  const links = props.nctsShipment.discrepancies.map(discrepancy => (
    <li key={discrepancy.id}>
      <a href={`/reactive/customs/discrepancies/${discrepancy.id}`}>
        {discrepancy.resolved ? (
          <i className='text-success fa fa-check' />
        ) : (
          <i className='text-warning fa fa-exclamation-triangle' />
        )}
        &nbsp;{discrepancy.reference} ({discrepancy.kind})
      </a>
    </li>
  ))

  return (
    <div className='ncts-discrepancies-summary'>
      <DropdownButton theme={theme} label={<i className={`fa ${image}`} />}>
        {links}
        <li>
          <a href={`/reactive/customs/discrepancies/new?nctsShipmentId=${props.nctsShipment.id}`}>
            <i className='fa fa-plus' />&nbsp;Add a new discrepancy
          </a>
        </li>
      </DropdownButton>
    </div>
  )
}

export default NctsDiscrepanciesSummary
