import React from 'react'

import EmissionsBreakdown from './EmissionsBreakdown'
import JourneyDetails from './JourneyDetails'
import EmissionsHelper from '../reactive/helpers/EmissionsHelper'

function capacityTextFor (journey) {
  if (!journey.totalWeight || !journey.weightLimit) { return 'N/A' }

  const value = (journey.totalWeight / journey.weightLimit) * 100
  return `${value.toFixed(2)}%`
}

// Used to display details for a given vehicle.
function VehicleBreakdown (props) {
  const items = props.data.map((journey, idx) => (
    <JourneyDetails
      key={idx}
      journey={journey}
      firstColumn={capacityTextFor(journey)}
      useKm={journey.mode === 'road'}
    >
      {journey.mode === 'rail' && (
        <i className='fa fa-train' title='Freight train' />
      )}
      {journey.mode === 'sea' && (
        <i className='fa fa-anchor' title='Ferry' />
      )}
    </JourneyDetails>
  ))

  const wtt = props.data.reduce((total, journey) => {
    const km = journey.actualDistance || journey.estimatedDistance
    const tkm = EmissionsHelper.calculateTonneKilometres(journey, journey.weight)
    const value = journey.mode === 'road' ? km : tkm
    return total + (EmissionsHelper.calculateWellToTank(journey, value) || 0)
  }, 0)

  const ttw = props.data.reduce((total, journey) => {
    const km = journey.actualDistance || journey.estimatedDistance
    const tkm = EmissionsHelper.calculateTonneKilometres(journey, journey.weight)
    const value = journey.mode === 'road' ? km : tkm
    return total + (EmissionsHelper.calculateTankToWheel(journey, value) || 0)
  }, 0)

  return (
    <EmissionsBreakdown label='Capacity' wtt={wtt} ttw={ttw}>
      {items}
    </EmissionsBreakdown>
  )
}

export default VehicleBreakdown
