import React, { useState, useEffect } from 'react'

import BreadcrumbBar from '../../common/BreadcrumbBar'
import CustomsInspectionTable from './index/CustomsInspectionTable'
import ErrorList from '../../common/ErrorList'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'

// This is the index page, which lists all "active" customs inspections.
export default function (props) {
  const breadcrumbs = {
    '/reactive/customs/inspections': 'Customs Inspections'
  }

  // Set the page title when this component mounts:
  useEffect(() => { document.title = 'Customs Inspections' }, [])

  // This is the only state we need to keep track of, so using a hook is fine:
  const [errors, setErrors] = useState([])
  const handleError = json => setErrors(json.errors || [json.error])

  return (
    <div>
      <BreadcrumbBar route={breadcrumbs} />
      <ErrorList errors={errors} />
      <TabGroup labels={['Pending', 'Complete']}>
        <TabContent>
          <CustomsInspectionTable complete={false} onError={handleError} />
        </TabContent>
        <TabContent>
          <CustomsInspectionTable complete={true} onError={handleError} />
        </TabContent>
      </TabGroup>
    </div>
  )
}
