import React from 'react'

// Used to contain the contents of a single tab. Not required, but Looks Nice™.
function TabContent (props) {
  return (
    <div className='panel-body'>
      <div className='tab-content'>{props.children}</div>
    </div>
  )
}

export default TabContent
