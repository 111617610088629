import React from 'react'

import { DropdownButton, DropdownOption } from './DropdownButton'
import { FontAwesomeIcon } from './font-awesome'

export const IconButton = ({ icon, children, ...props }) => (
  <button {...props}>
    <FontAwesomeIcon icon={icon} className="icon" />
    {children}
  </button>
)

export { DropdownButton, DropdownOption }
