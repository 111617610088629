import React, { Component } from 'react'
import { omit } from 'lodash'

import { IconButton } from './Buttons'
import { FontAwesomeIcon } from './font-awesome'


export const DropdownOption = ({ icon, label, ...props }) => (
  <li {...props}>
    <FontAwesomeIcon icon={icon} /><label>{label}</label>
  </li>
)

export class DropdownButton extends Component {

  state = { active: false }

  onClick = () => {
    this.setState({ active: !this.state.active })
    this.closable = false
    setTimeout(() => this.closable = true, 10)
  }

  setRef = (node) => {
    this.ref = node
  }

  content = () => (
    <div className="dropdown-content" ref={this.setRef}>
      {this.props.children}
    </div>
  )

  activeContent = () => (
    this.state.active ? <this.content /> : null
  )

  clickOutside = ({ target }) => {
    if (this.ref && this.closable && !this.ref.contains(target)) {
      this.setState({ active: false })
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.clickOutside)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.clickOutside)
  }

  caret = () => (
    <i>{this.state.active ? '▲' : '▼'}</i>
  )

  optionalCaret = () => (
    this.props.caret ? <this.caret /> : null
  )

  render = () => (
    <div className="dropdown-button">
      <IconButton {...omit(this.props, ['label', 'children'])} onClick={this.onClick}>
        {this.props.label}
        {this.optionalCaret()}
      </IconButton>
      {this.activeContent()}
    </div>
  )

}

