import BaseApi from '../BaseApi'

// This class handles all API interactions related to customs trade tariffs.
class CustomsTradeTariffApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/customs/trade_tariffs.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/customs/trade_tariffs/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/customs/trade_tariffs.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/customs/trade_tariffs/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/customs/trade_tariffs/${id}.json`)
  }
}

export default CustomsTradeTariffApi
