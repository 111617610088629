import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import SalesMarketStatusApi from '../../apis/crm/SalesMarketStatusApi'

// This is the index page, which lists all CRM sales market statuses.
class SalesMarketStatusIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'CRM Sales Market Statuses'

    SalesMarketStatusApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    SalesMarketStatusApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/crm/sales_market_statuses': 'Sales Market Statuses'
    }

    const headers = [
      { label: 'Name' },
      { label: 'Description' },
      { label: 'Treat As' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(salesMarketStatus => (
      <tr key={salesMarketStatus.id}>
        <td>{salesMarketStatus.name}</td>
        <td>{salesMarketStatus.description}</td>
        <td>{salesMarketStatus.treatAs}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/crm/sales_market_statuses/${salesMarketStatus.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(salesMarketStatus.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/crm/sales_market_statuses/new'>
              <i className='btn-label icon fa fa-plus' />
              New Sales Market Status
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <SimpleTable caption='CRM Sales Market Statuses' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default SalesMarketStatusIndex
