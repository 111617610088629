import React from 'react'

import CcgDropdown from './CcgDropdown'
import NctsDiscrepanciesSummary from './NctsDiscrepanciesSummary'
import NctsJobList from './NctsJobList'
// Components:
import DropDown from '../../reactive/common/Forms/DropDown'
// APIs:
import CustomsNctsShipmentApi from '../../reactive/apis/customs/CustomsNctsShipmentApi'

// This is the "form" used to either display or upload an NCTS Shipment record.
export default function (props) {
  return (
    <div className='normal-ncts-shipment-form'>
      <div className='ncts-reference'>{props.nctsShipment?.reference}</div>
      <div className='ncts-lrn'>{props.nctsShipment?.lrn}</div>
      <div className='ncts-mrn'>{props.nctsShipment?.mrn}</div>
      <div className='ncts-status'>{props.nctsShipment?.status}</div>
      <div className='ncts-office'>{props.office}</div>
      <div className='ncts-partner'>{props.partner}</div>
      <div className='ncts-goods-value'>{props.goodsValue}</div>
      <div className='ncts-guarantee-value'>{props.guaranteeValue}</div>
      <div className='ncts-problem'>
        {props.problem && (
          <i
            className='fa fa-exclamation-triangle text-danger'
            title={props.problem}
          />
        )}
      </div>
      <div className='ncts-ccg'>
        {props.nctsShipment ? (
          <input
            className='form-control'
            disabled={true}
            value={props.nctsShipment.ccgNumber}
          />
        ) : (
          <CcgDropdown
            disabled={props.problem}
            value={props.selectedCcg}
            onChange={props.onCcgChange}
          />
        )}
      </div>
      <div className='ncts-alci'>
        {props.nctsShipment ? (
          <input
            className='form-control'
            disabled={true}
            value={props.nctsShipment.alciReference}
          />
        ) : (
          <DropDown
            source={CustomsNctsShipmentApi.alciOptions}
            disabled={props.problem}
            value={props.selectedAlci}
            onChange={props.onAlciChange}
          />
        )}
      </div>
      {props.nctsShipment ? (
        <div className='ncts-actions'>
          <button
            className='ncts-check-status-button'
            onClick={props.onCheckStatus}
          >
            Check Status
          </button>
          <NctsDiscrepanciesSummary nctsShipment={props.nctsShipment} />
        </div>
      ) : (
        <div className='ncts-actions'>
          <button
            className='ncts-manual-submit-button'
            onClick={props.onManualSubmit}
          >
            Manual Submission
          </button>
          <button
            className='ncts-normal-submit-button'
            disabled={props.problem || !props.selectedCcg}
            onClick={props.onNormalSubmit}
          >
            Upload to API
          </button>
        </div>
      )}
      <div className='ncts-jobs'>
        <NctsJobList jobs={props.jobs} />
      </div>
    </div>
  )
}
