import React from 'react'

import VehicleSummary from './VehicleSummary'
import VehicleBreakdown from './VehicleBreakdown'
import CollapsableSection from '../reactive/common/CollapsableSection'

// FMS-9238: Display carbon emissions information for a specific vehicle.
function ForVehicle (props) {
  if (!props.data.length) {
    return (
      <div className='well'>
        No journey details are defined for this vehicle yet...
      </div>
    )
  }

  return (
    <CollapsableSection label={<VehicleSummary breakdown={props.data} />}>
      <VehicleBreakdown data={props.data} />
    </CollapsableSection>
  )
}

export default ForVehicle
