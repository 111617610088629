import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import EventRuleApi from '../../apis/events/EventRuleApi'
// Helpers:
import RuleHelper from '../../helpers/events/RuleHelper'
import IconHelper from '../../helpers/IconHelper'

// This is the index page, which lists all event rules.
class EventIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'Events'

    EventRuleApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    EventRuleApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/events/rules': 'Events'
    }

    const headers = [
      { label: 'Trigger Condition' },
      { label: 'Add a timeline entry?' },
      { label: 'Add a notification?' },
      { label: 'Message' },
      { label: 'Notify' },
      { label: 'Escalate to' },
      { label: 'Severity' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(eventRule => (
      <tr key={eventRule.id}>
        <td>{RuleHelper.triggerCondition(eventRule)}</td>
        <td>{IconHelper.boolean(eventRule.timelineEntry)}</td>
        <td className='no-wrap'>
          {eventRule.notification ? 'Yes' : 'No'}
          {eventRule.important ? ', and confirm' : ''}
        </td>
        <td>
          {eventRule.messageText ? `[Model] ${eventRule.messageText}` : '[Auto-generated]'}
        </td>
        <td className='no-wrap'>{RuleHelper.notifyParty(eventRule.notifyFirst)}</td>
        <td className='no-wrap'>{RuleHelper.notifyParty(eventRule.notifySecond)}</td>
        <td className='no-wrap'>{eventRule.severity}</td>
        <td className='no-wrap'>
          <Link className='btn btn-xs' to={`/reactive/events/rules/${eventRule.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(eventRule.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/events/rules/new'>
              <i className='btn-label icon fa fa-plus' />
              New Event Rule
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <SimpleTable caption='Event Rules' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default EventIndex
