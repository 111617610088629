import React, { Component } from 'react'

import { url_for } from './Routes'
import AjaxRequest from './AjaxRequest'

import StateManager from './StateManager'

export default class GroupageFinancials extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.state = this._getState()
  }

  componentDidMount() {
    this.manager.registerGroupageFinancials(this)
  }

  componentWillUnmount() {
    this.manager.deregisterGroupageFinancials(this)
  }

  redraw() {
    this.setState(this._getState())
  }

  request() {
    new AjaxRequest({
      method: 'GET',
      url:    url_for("financials_trailer_planners_groupage_path", this.state.groupage_id)
    })
    this.setState({ requesting: true })
  }

  render() {
    return <span>
      {this._profit()}
    </span>
  }

  // private

  _profit() {
    let profit = this.state.projected_profit_base
    let prefix = window.baseCurrencySymbol();
    if (profit || typeof profit == "number") { // 0 is false!
      return <span>{prefix} {profit}</span>
    } else if (this.state.requesting) {
      return <span className="requesting">...</span>
    } else if (this.state.groupage_id) {
      return <span
        className="request"
        onClick={this.request.bind(this)}
        title="Calculate groupage projected profit"
      >{prefix} <i className="fa fa-refresh" /></span>
    }
  }

  _getState() {
    return this.manager.getGroupageFinancials(this.props.booking_id)
  }
}
