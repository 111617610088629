import React from 'react'

import TrailerBookingApi from '../api/TrailerBookingApi'

// NOTE: These are defined on the Rails server -- keep this list in sync!
const enumValues = ['pending', 'arrived', 'in_progress', 'complete']

// Show the current trailer status -- and maybe allow it to be changed.
class TrailerBookingProgress extends React.Component {
  constructor (props) {
    super(props)

    this.state = { loading: false }
  }

  handleChange = (event) => {
    const params = { progress: event.target.value }
    this.setState({ loading: true })

    TrailerBookingApi.update(this.props.booking.id, params).then(
      json => {
        // console.log('Trailer booking status updated:', json)
        this.setState({ loading: false })
      },
      json => {
        console.log('Could not update trailer booking status:', json)
        this.setState({ loading: false })
      }
    )
  }

  titleText () {
    const a = 'This trailer is updated automatically based on warehouse events.'
    if (!this.props.booking.actual_arrival_time) { return a }

    const b = this.props.booking.actual_arrival_time // TODO: Format this right!
    return `Arrived at ${b}. ${a}`
  }

  render () {
    const cssClass = 'trailer-planning-trailer-booking-progress'
    // Trailers arriving at a warehouse we operate are handled automatically:
    const autoSet = this.props.booking.goods_in || this.props.booking.goods_out
    // As such, we can't let the user set a status "manually" via the planner:
    const items = autoSet ? [this.props.booking.progress] : enumValues
    // Users might not realise why they can't change it, so we show a hint:
    const title = autoSet ? this.titleText() : null

    // Changes take a moment to take effect, so show a loading indicator:
    const options = this.state.loading ? (
      <option disabled={true} value=''>
        Saving...
      </option>
    ) : items.map(value => (
      <option key={value} value={value}>
        {value.replace('_', ' ')}
      </option>
    ))

    return (
      <select
        className={`${cssClass} ${this.props.booking.progress}`}
        value={this.state.loading ? '' : this.props.booking.progress}
        title={title}
        required={true}
        disabled={autoSet}
        onChange={this.handleChange}
      >
        {options}
      </select>
    )
  }
}

export default TrailerBookingProgress
