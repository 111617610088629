import BaseApi from '../BaseApi'

// This class handles all API interactions related to countries.
class CountryApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/places/countries.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/places/countries/${id}.json`)
  }

  // static create (params) {
  //   return BaseApi.sendRequest('POST', 'api/places/countries.json', params)
  // }

  // static update (id, params) {
  //   return BaseApi.sendRequest('PUT', `api/places/countries/${id}.json`, params)
  // }

  // static destroy (id) {
  //   return BaseApi.sendRequest('DELETE', `api/places/countries/${id}.json`)
  // }
}

export default CountryApi
