import React from 'react'

import BooleanIcon from '../../../common/Forms/BooleanIcon'
import FieldList from '../../../common/Forms/FieldList'
import FormHeader from '../../../common/Forms/FormHeader'
import FormSection from '../../../common/Forms/FormSection'
import ShowField from '../../../common/Forms/ShowField'
// Helpers:
import DateHelper from '../../../helpers/DateHelper'
import PersonHelper from '../../../helpers/PersonHelper'
// I18N:
import enums from '../../../i18n/enums'

// The "General" tab of the "Show NCTS Discrepancy" page.
export default function (props) {
  const generalHeader = (
    <FormHeader title='General'>
      <label>
        Penalty?
        <BooleanIcon value={props.discrepancy.penalty} />
      </label>
      <label>
        Resolved?
        <BooleanIcon value={props.discrepancy.resolved} />
      </label>
    </FormHeader>
  )

  const penaltyHeader = (
    <FormHeader title='Penalty Details'>
      <label>
        Recovered?
        <BooleanIcon value={props.discrepancy.recovered} />
      </label>
    </FormHeader>
  )

  return (
    <div className='ncts-discrepancy-show-general'>
      <FormSection title={generalHeader}>
        <FieldList>
          <ShowField label='NCTS Shipment' value={props.discrepancy.nctsShipment &&
            <a href={`/groupages/${props.discrepancy.nctsShipment?.groupage?.id}`} target='_blank'>
              {props.discrepancy.nctsShipment?.reference}
            </a>
          } />
          <ShowField label='Handled By' value={PersonHelper.fullName(props.discrepancy.user)} />
          <ShowField label='Kind' value={enums.en.customs.discrepancies.kinds[props.discrepancy.kind] || props.discrepancy.kind} />
          <ShowField label='Deadline' value={DateHelper.formatDate(props.discrepancy.deadline)} />
        </FieldList>
      </FormSection>
      {props.discrepancy.penalty && (
        <FormSection title={penaltyHeader}>
          <FieldList>
            <ShowField label='Recovered From' value={enums.en.customs.discrepancies.recoveredFroms[props.discrepancy.recoveredFrom] || props.discrepancy.recoveredFrom} />
            <ShowField
              label='Cost'
              prefix={props.discrepancy.currency?.currencySymbol}
              value={props.discrepancy.cost}
            />
          </FieldList>
        </FormSection>
      )}
    </div>
  )
}
