import React from 'react'

// APIs:
import NotificationApi from '../../apis/events/NotificationApi'

// Provides a generic "manager" layer between a React component and our API.
function withNotifications (WrappedComponent) {
  return class extends React.Component {
    constructor (props) {
      super(props)

      this.state = {
        items: null,
        alert: false
      }
    }

    componentDidMount () {
      this.fetchData()

      // TODO: Subscribe to websocket-based updates here.
      // this.setState({ timer: window.setInterval(this.fetchData, 60 * 1000) })

      // HACK: See app/views/notifications/update.js.erb
      document.body.addEventListener('notification.freightsoft', this.fetchData)
    }

    componentWillUnmount () {
      // TODO: Unsubscribe from websocket-based updates here.
      // window.clearInterval(this.state.timer)

      // HACK: See app/views/notifications/update.js.erb
      document.body.removeEventListener('notification.freightsoft', this.fetchData)
    }

    fetchData = () => {
      // Assume we just want "our" notifications by default:
      let endpoint = NotificationApi.all
      const params = { status: this.props.status } // Acknowledged, etc.

      // If a parent model is specified, get those notifications instead:
      if (this.props.parentType) {
        endpoint = NotificationApi.forParent
        params.parentId = this.props.parentId
        params.parentType = this.props.parentType
      }

      // Reset back to a "loading" state until the AJAX request is done:
      this.setState({ items: null, alert: false })

      // Fetch the data:
      endpoint(params).then(
        json => {
          const anyRecent = this.createdWithin(json, 60 * 1000) // 60 seconds
          this.setState({ items: json, alert: anyRecent })
        },
        error => console.log('Could not fetch notifications:', error)
      )
    }

    // Determine if any notifications were added (or escalated!) recently:
    createdWithin (items, newTime) {
      const now = new Date()

      return items.some(item => (
        (item.notify3.time && now - (new Date(item.notify3.time)) < newTime) ||
        (item.notify2.time && now - (new Date(item.notify2.time)) < newTime) ||
        now - (new Date(item.createdAt)) < newTime
      ))
    }

    // Disable the alert. Think of it as switching off your alarm clock.
    handleDisableAlert = () => {
      this.setState({ alert: false })
    }

    // View a specific notifcation.
    handleView = (event, item) => {
      event.preventDefault()

      this.props.onViewNotification(item.id)
    }

    // Progress a specific notification.
    handleAction = (event, item) => {
      event.preventDefault()

      if (item.important) { return this.props.onNotificationPrompt(item.id) }

      const api = NotificationApi
      const endpoint = item.acknowledged ? api.dealWith : api.acknowledge

      endpoint(item.id, '').then(
        json => {
          const fakeWebsocketEvent = new CustomEvent('notification.freightsoft')
          document.body.dispatchEvent(fakeWebsocketEvent)
        },
        error => console.log('Could not update notification:', error)
      )
    }

    render () {
      return (
        <WrappedComponent
          items={this.state.items}
          onView={this.handleView}
          onAction={this.handleAction}
          alert={this.state.alert}
          disableAlert={this.handleDisableAlert}
          {...this.props}
        />
      )
    }
  }
}

export default withNotifications
