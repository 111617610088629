// Contains various helper functions related to emissions calculations.
class EmissionsHelper {
  // Format a distance value (miles or km); value may be a Number or String.
  static formatDistance (value) {
    return (value || value === 0) ? Number(value).toFixed(2) : null
  }

  // Convert a distance in kilometres into the equivalent miles value.
  static kilometresToMiles (value) {
    return (value || value === 0) ? Number(value) * 0.621371 : null
  }

  // Calculate a Tonne-Kilometres value from kilograms and kilometres.
  static calculateTonneKilometres (journey, weight) {
    const distance = journey.actualDistance || journey.estimatedDistance
    if (!weight || !distance) { return null }

    return (Number(weight) / 1000) * Number(distance)
  }

  // Get the cost of the "mining fuel" to "putting it into the vehicle" stage.
  static calculateWellToTank (journey, value) {
    const wtt = journey.emissions?.wtt && Number(journey.emissions.wtt)
    return (value || value === 0) && (wtt || wtt === 0) ? value * wtt : null
  }

  // Get the cost of using the fuel to power the vehicle.
  static calculateTankToWheel (journey, value) {
    const ttw = journey.emissions?.ttw && Number(journey.emissions.ttw)
    return (value || value === 0) && (ttw || ttw === 0) ? value * ttw : null
  }
}

export default EmissionsHelper
