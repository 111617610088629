import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import CustomsPortApi from '../../apis/customs/CustomsPortApi'

function officeText (office) {
  const country = office.country || { countryCode: '??' }
  return `${office.nctsCode} - ${office.name} (${country.countryCode})`
}

// This is the index page, which lists all customs ports.
class CustomsPortIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'Customs Ports'

    CustomsPortApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    CustomsPortApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/ports': 'Customs Ports'
    }

    const headers = [
      { label: 'GVMS' },
      { label: 'Name' },
      { label: 'CDS' },
      { label: 'CHIEF' },
      { label: 'Office' },
      { label: 'Country' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(port => (
      <tr key={port.id}>
        <td>{port.gvmsCode}</td>
        <td>{port.name}</td>
        <td>{port.cdsCode}</td>
        <td>{port.chiefCode}</td>
        <td>{port.officeLocation ? officeText(port.officeLocation) : ''}</td>
        <td>{port.country?.name}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/customs/ports/${port.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(port.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/customs/ports/new'>
              <i className='btn-label icon fa fa-plus' />
              New Port
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <SimpleTable caption='Customs Ports' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default CustomsPortIndex
