import React from 'react'

// Part of the top bar.
function NavbarUser (props) {
  return (
    <li className='dropdown'>
      <a href='#' className='dropdown-toggle user-menu' data-toggle='dropdown'>
        <img src={props.avatar} />
        <span>{props.user.name}</span>
      </a>
      <ul className='dropdown-menu'>
        <li>
          <a href='/users/edit'>
            <i className='dropdown-icon fa fa-cog'></i>&nbsp;&nbsp;Settings
          </a>
        </li>
        <li className='divider'></li>
        <li>
          <a rel='nofollow' data-method='delete' href='/users/sign_out'>
            <i className='dropdown-icon fa fa-power-off'></i>&nbsp;&nbsp;Log Out
          </a>
        </li>
      </ul>
    </li>
  )
}

export default NavbarUser
