import BaseApi from '../BaseApi'

// This class handles all API interactions related to NCTS Shipments.
class CustomsNctsShipmentApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/customs/ncts_shipments')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/customs/ncts_shipments/${id}`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/customs/ncts_shipments', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/customs/ncts_shipments/${id}`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/customs/ncts_shipments/${id}`)
  }

  static checkStatus (id) {
    return BaseApi.sendRequest('POST', `api/customs/ncts_shipments/${id}/check_status`)
  }

  static ccgBalance () {
    return BaseApi.sendRequest('GET', 'api/customs/ncts_shipments/ccg_balance')
  }

  static ccgOptions () {
    return BaseApi.sendRequest('GET', 'api/customs/ncts_shipments/ccg_options')
  }

  static alciOptions () {
    return BaseApi.sendRequest('GET', 'api/customs/ncts_shipments/alci_options')
  }
}

export default CustomsNctsShipmentApi
