import React from 'react'

// Components:
import Header from '../../../common/AboutBar/Header'
import ItemList from '../../../common/AboutBar/ItemList'
import UploadDropzone from '../../../files/items/UploadDropzone'
// Helpers:
import DateHelper from '../../../helpers/DateHelper'

// The "About" bar content for the Show page.
export default function (props) {
  const items = [
    {
      theme: 'success',
      label: 'Created At',
      value: DateHelper.formatDateTime(props.discrepancy.createdAt)
    },
    {
      theme: 'success',
      label: 'Updated At',
      value: DateHelper.formatDateTime(props.discrepancy.updatedAt)
    }
  ]

  return (
    <React.Fragment>
      <UploadDropzone parent={props.discrepancy} />
      <Header>About {props.discrepancy.reference}</Header>
      <ItemList items={items} />
    </React.Fragment>
  )
}
