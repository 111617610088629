import React, { Component } from 'react'
import DatePicker from 'react-datepicker'

import StateManager from '../StateManager'

export default class DateFilter extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerDateFilter(this)
    this.state = this.manager.getDateRange()
  }

  redraw() {
    // Should check if we are mounted!
    this.setState(this.manager.getDateRange())
  }

  render() {
    return (
      <div className="date-filter">
        <span className="filter-title">{this._weekNumber()}</span>
        <div className="control-wrapper">
          <button 
            className="btn btn-primary date-mode"
            title="Switch between week and single day mode"
            onClick={this.toggleMode.bind(this)}
          >
            {this._modeText()}
          </button>
          <i 
            className="btn fa fa-angle-double-left fa-fw"
            onClick={this.dateDecrement.bind(this)}
          />
          <DatePicker
            value={this._dateText()}
            selected={this.state.start}
            onChange={this.setDate.bind(this)}
            className="form-control"
          />
          <i 
            className="btn fa fa-angle-double-right fa-fw"
            onClick={this.dateIncrement.bind(this)} 
          />
        </div>
      </div>
    )
  }

  dateIncrement() {
    this._dateAdd(this._isSingleDay() ? 1 : 7)
  }

  dateDecrement() {
    this._dateAdd(this._isSingleDay() ? -1 : -7)
  }

  setDate(date) {
    this._isSingleDay() ? this._setDay(date) : this._setWeek(date)
  }

  toggleMode() {
    if (this._isSingleDay())
      this._setWeek(this.state.start)
    else
      this._setDay(this.state.start)
  }

// private

  _isSingleDay() {
    return this.manager.isDateRangeSingle()
  }

  _setDay(date) {
    this.manager.setDateRange(date, date)
  }

  _setWeek(date) {
    this.manager.setDateRange(date.clone().startOf('week'), date.clone().endOf('week'))
  }

  _dateAdd(n) {
    var date = this.state.start
    date.add(n, 'days') // moments are mutated..
    this.setDate(date)
  }

  _modeText() {
    return this._isSingleDay() ? `Date` : 'Range'
  }

  _weekNumber() {
    return `Week ${this.state.start.format("W")}`
  }

  _dateText() {
    // https://momentjs.com/docs/#/displaying/
    if (this._isSingleDay()) {
      return this.state.start.format("ddd DD/MM/YY")
    } else {
      return `${this.state.start.format("DD/MM/YY")} to ${
        this.state.end.format("DD/MM/YY")}`
    }
  }
}
