import React, { Component } from 'react'

const COLORS = {
  "Prohibited on Eurotunnel": "danger",
  "Eurotunnel OK": "success"
}

export default class EuroTunnel extends Component {
  render() {
    return (
      <span 
        className={`badge badge-${COLORS[this.props.info] || 'warning'}`}
        title={this.props.info}
      >
        <i className="fa fa-train" />
      </span>
    )
  }
}
