import React from 'react'

import IconBadge from '../IconBadge'

const DETAILS = {
  prioritise: "This trailer has been flagged as high-priority",
  unaccompanied: "Trailers will not travel with vehicle",
  temperature_controlled: "This vehicle is temperature-controlled",
  adr: "This vehicle is able to carry hazardous goods",
  ferry: "Travelling via ferry",
  waste: "This vehicle is able to carry waste"
}

function Flags (props) {
  const flags = DETAILS.keys().filter(flag => props[flag])
  const icons = flags.map(f => <IconBadge key={f} name={f} title={DETAILS[f]} />)

  return <div className="booking-flags">{icons}</div>
}

export default Flags
export const BOOKING_FLAGS = DETAILS.keys()
