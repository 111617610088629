import React from 'react'

// Used for both job-centric and vehicle-centric journey breakdown sections.
function EmissionsBreakdown (props) {
  const wtt = props.wtt || 0
  const ttw = props.ttw || 0
  const wtw = wtt + ttw

  return (
    <div className='emissions-breakdown'>
      <div className='emissions-breakdown-header'>
        <div className='emissions-breakdown-row-misc'>{props.label}</div>
        <div className='emissions-breakdown-row-origin'>From</div>
        <div className='emissions-breakdown-row-destination'>To</div>
        <div className='emissions-breakdown-row-distance'>Kilometres</div>
        <div className='emissions-breakdown-row-weight'>Weight</div>
        <div className='emissions-breakdown-row-wtt'>WTT (kg)</div>
        <div className='emissions-breakdown-row-ttw'>TTW (kg)</div>
        <div className='emissions-breakdown-row-ttw'>CO2e (kg)</div>
        <div className='emissions-breakdown-row-special'>
          <i className='fa fa-info-circle' title='Special factors' />
        </div>
      </div>
      {props.children}
      <div className='emissions-breakdown-footer'>
        <div className='emissions-breakdown-row-misc'></div>
        <div className='emissions-breakdown-row-origin'></div>
        <div className='emissions-breakdown-row-destination'></div>
        <div className='emissions-breakdown-row-distance'></div>
        <div className='emissions-breakdown-row-weight'></div>
        <div className='emissions-breakdown-row-wtt'>{wtt.toFixed(2)}</div>
        <div className='emissions-breakdown-row-ttw'>{ttw.toFixed(2)}</div>
        <div className='emissions-breakdown-row-wtw'>{wtw.toFixed(2)}</div>
        <div className='emissions-breakdown-row-special'></div>
      </div>
    </div>
  )
}

export default EmissionsBreakdown
