import React from 'react'

// Part of the top bar.
function NavbarToggleLeft (props) {
  return (
    <button type='button' id='main-menu-toggle'>
      <i className='navbar-icon fa fa-bars icon'></i>
      <span className='hide-menu-text'>HIDE MENU</span>
    </button>
  )
}

export default NavbarToggleLeft
