import React from 'react'

import Dropzone from '../common/Dropzone'
import TrailerHeader from './TrailerHeader'
import PlanningUnitList from '../Jobs/PlanningUnitList'

// This represents an individual trailer booking on the planner screen.
function TrailerBooking (props) {
  return (
    <Dropzone bookingId={props.booking.id}>
      <PlanningUnitList
        units={props.booking.units}
        capacity={props.booking.max_gross_weight_kg}
        groupageId={props.booking.groupage_id}
        cotDotBookings={props.cotDotBookings}
      >
        <TrailerHeader booking={props.booking} />
      </PlanningUnitList>
    </Dropzone>
  )
}

export default TrailerBooking
