import React, { Component } from 'react'

import Pagination from './Pagination'

import Unit from './Unit'
import StateManager from './StateManager'
import GroupageFinancials from './GroupageFinancials'

const NUMERIC_TOTALS = {
  gross_weight_kg: " kg",
  cubic_metres:    " m³",
  loading_metres:  " m",
  taxable_weight:  "",
  package_details: ' pkg'
}

const ABBREVIATIONS = {
  shipping_postcode:    'Ship. PC',
  consignee_postcode:   'Con. PC',
  package_details:      'Pkg',
  gross_weight_kg:      'Kg',
  cubic_metres:         'Cube',
  loading_metres:       'LDM',
  taxable_weight:       'Tax.',
  notes_status:         'Notes',
  ready_date:           'Ready',
  delivery_required_by: 'Del. Req.',
  collection_date:      'Col.',
  due_in_date:          'Due',
  destination_hub:      'Dest. Hub',
  problems:             '﻿',
}

const DEFAULT_PER_PAGE = 25
const PER_PAGE_OPTIONS = [10, 25, 50, 100]

export default class UnitBlock extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerBlock(this)
    this.state = this._getState()
  }

  onDrop(e) {
    this.manager.transfer({
      unit_id:      e.dataTransfer.getData('unit-id'),
      selected_ids: e.dataTransfer.getData('selected-ids'),
      target_id:    this.props.id
    })
  }

  onDragOver(e) {
    e.preventDefault()
    // console.log('onDragOver')
    if (!this.state.dragover) {
      this.setState({ dragover: true }) // onDragLeave is dodgy
      this.manager.setDropTarget(this)
    }
  }

  componentWillUnmount() {
    if (this.state.dragover) { this.manager.clearDropTarget() }
  }

  redraw() {
    // Should check if we are mounted!
    this.setState(this._getState())
  }

  render() {
    let css = `unit-block ${this.state.dragover ? 'dragover' : ''}`
    return(
      <table
        className={css}
        onDrop={this.onDrop.bind(this)}
        onDragOver={this.onDragOver.bind(this)}
      >
        {this.state.expanded ? this._thead() : null}
        <tbody>
          {this.state.expanded ? this._expanded() : null}
          {this._dragoverRow()}
          {this._pagination()}
        </tbody>
        <tfoot>
          <tr>
            <td className="icon" />
            {this._totals()}
            <td className="groupage-financials">
              <GroupageFinancials booking_id={this.props.id} />
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }

  setSortColumn(column) {
    // Reverse sort if reselecting same sort_column
    let reverse = this.state.sort_method.column == column && !this.state.sort_method.reverse

    this.manager.setSortMethod(this.props.id, { column: column, reverse: reverse })
  }

  setPerPage(x) {
    this.setState({ per_page: Number(x.currentTarget.value), page: 0 })
  }

// private

  // note that the real page is zero-based, but we show the user + 1
  _pagination() {
    if (!this.state.expanded || this.state.units.length <= DEFAULT_PER_PAGE) { return null }

    let last_page = (this.state.units.length / this._perPage()).floor()

    return <tr>
      <td className="pagination" colSpan={this.manager.visibleColumns().length - 1}>
        <Pagination
          page={this._sanitizePage(this.state.page)}
          max={last_page}
          setPage={this.setPage.bind(this)}
        />
      </td>
      <td className="per-page">
        <span>Per Page:</span>
        <select
          onChange={this.setPerPage.bind(this)}
          value={this._perPage()}
          className="form-control"
        >
          {PER_PAGE_OPTIONS.map(pp => <option value={pp} key={pp}>{pp}</option>)}
        </select>
      </td>
    </tr>
  }

  _getState() {
    return {
      expanded:    this.manager.isBlockExpanded(this.props.id),
      sort_method: this.manager.getSortMethod(this.props.id),
      units:       this.manager.blockUnits(this.props.id, this.props.unassigned),
      page:        this.manager.getBlockPage(this.props.id),
      dragover:    false
    }
  }

  _thead() {
    return <thead>
      <tr>
        <th className="icon" />
          {this._columnHeaders()}
        <th className="controls" />
      </tr>
    </thead>
  }

  _perPage() {
    return this.state.per_page || DEFAULT_PER_PAGE
  }

  _expanded() {
    let first = this._sanitizePage(this.state.page) * this._perPage()

    return this.state.units.slice(first, first + this._perPage()).map(u => <Unit {...u} key={u.id} />)
  }

  setPage(n) {
    n = this._sanitizePage(n)
    this.manager.setBlockPage(this.props.id, n)
    this.setState({ page: n })
  }

  // zero-based
  _sanitizePage(page) {
    if (!page || page < 0) { page = 0 }
    while ((page * this._perPage()) >= this.state.units.length) { page -= 1 }

    return page
  }

  _isEmpty() {
    return this.state.units.length == 0
  }

  _dragoverRow() {
    if (!this.state.dragover) { return }
    return (
      <tr className="expand">
        <td colSpan={this.manager.visibleColumns().length}>
        </td>
      </tr>
    )
  }

  _columnHeaders() {
    return this.manager.visibleColumns().map((c, i) =>
      <th
        key={i}
        className={`sortable ${c}`}
        onClick={x => this.setSortColumn(c)}
        style={{ width: this.manager.columnWidth(c) }}
      >
        {ABBREVIATIONS[c] || c.humanize()}{this._sortIcon(c)}
      </th>
    )
  }

  _sortIcon(column) {
    if (this.state.sort_method.column != column) { return }
    return this.state.sort_method.reverse ? ' ▲'  : ' ▼'
  }

  _availableCapacity(name, total) {
    if ("number" != typeof total) { return }
    let details = this.manager.bookingDetails(this.props.id)
    let max = details && details[`max_${name}`]
    return max && parseFloat(max) - total
  }

  _totalJobs() {
    let n = this.state.units.length
    return `${n} job${n == 1 ? '' : 's'}`
  }

  // Please refactor me!
  _totals() {
    let columns = this.manager.visibleColumns()
    columns = columns.slice(0, columns.length - 2) // last is groupage financials
    let totals = {}
    let available = {}
    let styles = {}

    totals[columns[0]] = this._totalJobs()

    columns.each(c => styles[c] = { width: this.manager.columnWidth(c) })

    // get totals
    NUMERIC_TOTALS.keys().each(t => {
      let value = this._total(t)
      totals[t] = `${value}${NUMERIC_TOTALS[t]}`
      available[t] = this._availableCapacity(t, value)
      available[t] && available[t] < 0 ? styles[t].color = 'red' : null
    })

    // second column
    if (this._isBooking()) {
      totals[columns[1]] = `Available: ${available.gross_weight_kg || 'unknown'} kg`
      styles[columns[1]].overflow = 'visible'
    }

    // return with totals lining up
    return columns.map((c, i) => <td className={c} key={i} style={styles[c]}>{totals[c]}</td>)
  }

  _isBooking() {
    return ["pending", "active"].excludes(this.props.id)
  }

  _total(column) {
    return this.state.units.map(u => u[column] && parseFloat(u[column]))
               .compact().sum().prettyRound(1)
  }
}


