import React from 'react'

import InfoBox from '../common/InfoBox'
import PopOver from '../common/PopOver'
import DimensionsLine from './DimensionsLine'

// Show dimensions information on hover-over.
function PlanningUnitDimensions (props) {
  const dimensionsLines = props.unit.dimensions_lines

  const title = (
    <InfoBox header='Package Dimensions'>
      {dimensionsLines.length ? dimensionsLines.map((dimensionsLine, n) => (
        <DimensionsLine key={n} dimensionsLine={dimensionsLine} />
      )) : 'No specific dimensions have been provided yet.'}
    </InfoBox>
  )

  return <PopOver title={title}>{props.children}</PopOver>
}

export default PlanningUnitDimensions
