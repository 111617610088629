import BaseApi from '../BaseApi'
import buildGetQuery from '../buildGetQuery'

// This class handles all API interactions related to customs pricing.
class CustomsChargesApi {
  static fetch (params) {
    params = buildGetQuery(params)
    return BaseApi.sendRequest('GET', 'api/customs/charges.json', params)
  }

  static update (params) {
    return BaseApi.sendRequest('PUT', 'api/customs/charges.json', params)
  }
}

export default CustomsChargesApi
