import React from 'react'

import InfoBox from '../common/InfoBox'
import PopOver from '../common/PopOver'
import TrailerHelper from '../helpers/TrailerHelper'

// Problems icon.
function TrailerBookingProblems (props) {
  const minor = TrailerHelper.minorProblems(props.booking)
  const major = TrailerHelper.majorProblems(props.booking)
  if (!minor.length && !major.length) { return null }
  const severity = major.length ? 'major' : 'minor'

  const minorItems = minor.map(text => (
    <div key={text} className='trailer-planning-trailer-booking-problems-minor'>
      {text}
    </div>
  ))

  const majorItems = major.map(text => (
    <div key={text} className='trailer-planning-trailer-booking-problems-major'>
      {text}
    </div>
  ))

  const popOver = <InfoBox header='Problems'>{majorItems}{minorItems}</InfoBox>

  return (
    <PopOver title={popOver}>
      <div className={`trailer-planning-trailer-booking-problems ${severity}`}>
        {severity.toUpperCase()} problems found
      </div>
    </PopOver>
  )
}

export default TrailerBookingProblems
