import React from 'react'

import GroupageApi from '../api/GroupageApi'

// This is used in the bottom-right corner of Trailer sections.
class GroupageValue extends React.Component {
  constructor (props) {
    super(props)

    this.state = { loading: false }
  }

  handleClick = () => {
    this.setState({ loading: true })
    GroupageApi.profit(this.props.groupageId).then(
      json => this.setState({ loading: false, ...json }),
      json => this.setState({ loading: false })
    )
  }

  render () {
    if (!this.props.groupageId) { return null }
    const css = 'trailer-planning-groupage-value'

    if (this.state.loading) {
      return <div className={`${css} loading`}>Loading...</div>
    }

    return this.state.value ? (
      <div className={`${css} result`}>
        Projected profit: {this.state.currencySymbol}{this.state.value}
      </div>
    ) : (
      <button className={`${css} pending`} onClick={this.handleClick}>
        Calculate
      </button>
    )
  }
}

export default GroupageValue
