import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import ErrorList from '../../common/ErrorList'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import TrailerTypeApi from '../../apis/movements/TrailerTypeApi'
// Helpers:
import IconHelper from '../../helpers/IconHelper'
// I18N:
import enums from '../../i18n/enums'

// This table lists a set of trailer types.
class TrailerTypeIndex extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      json: null,
      errors: []
    }
  }

  componentDidMount () {
    document.title = 'Trailer Types'

    TrailerTypeApi.all().then(
      json => this.setState({ json: json.items }),
      json => this.setState({ errors: json.errors || [json.error] })
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    TrailerTypeApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      json => this.setState({ errors: json.errors || [json.error] })
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/movements/trailer_types': 'Trailer Types'
    }

    const headers = [
      { label: 'Name' },
      { label: 'Category' },
      { label: 'Capacity (kg)' },
      { label: 'Loading Metres' },
      { label: 'Length (M)' },
      { label: 'Width (M)' },
      { label: 'Height (M)' },
      { label: 'Trailer?' },
      { label: 'Refrigerated?' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(trailerType => (
      <tr key={trailerType.id}>
        <td>
          <Link to={`/reactive/movements/trailer_types/${trailerType.id}`}>
            {trailerType.name}
          </Link>
        </td>
        <td>{enums.en.movements.trailerTypes.vehicleCategories[trailerType.vehicleCategory] || trailerType.vehicleCategory}</td>
        <td>{trailerType.grossWeightKg}</td>
        <td>{trailerType.loadingMetres}</td>
        <td>{trailerType.lengthM}</td>
        <td>{trailerType.widthM}</td>
        <td>{trailerType.heightM}</td>
        <td>{enums.en.movements.trailerTypes.trailersAttacheds[trailerType.trailersAttached] || trailerType.trailersAttached}</td>
        <td>{IconHelper.boolean(trailerType.refrigerated)}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/movements/trailer_types/${trailerType.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(trailerType.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <React.Fragment>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/movements/trailer_types/new'>
              <i className='btn-label icon fa fa-plus' />
              New Trailer Type
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <ErrorList errors={this.state.errors} />
        <SimpleTable caption='Trailer Types' headers={headers}>
          {items}
        </SimpleTable>
      </React.Fragment>
    )
  }
}

export default TrailerTypeIndex
