import React, { Component } from 'react'

import StateManager from '../StateManager'

export default class BookingSearch extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
  }

  render() {
    return <div className="booking-search">
      <span className="filter-title">Booking Search</span>
      <div className="Select">
        <div className="Select-control">
          <input
            id="booking-search"
            onChange={this.onChange.bind(this)}
            placeholder="Search Trailers..."
          />
        </div>
      </div>
    </div>
  }

  onChange(e) {
    this.manager.setTrailerSearch(e.currentTarget.value)
  }
}

