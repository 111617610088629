// NOTE: This logic just handles "potential problems" logic; move server-side?

function singleLoadJobs (booking) {
  const error = 'Multiple jobs are assigned, despite one (or more) of them being flagged as single load.'

  const units = booking.units
  return units.length > 1 && units.find(u => u.single_load) ? error : null
}

function singleLoadOnly (booking) {
  const error = 'This supplier is flagged as single-load only.'

  return (
    (booking.full_loads_only && booking.units.length > 1) ||
    (booking.full_loads_only && booking.units.find(u => !u.single_load))
  ) ? error : null
}

function jobTypeMismatch (booking) {
  const error = 'One or more jobs have a different origin/destination country.'

  const units = booking.units
  return (
    units.find(u => u.origin_country !== booking.origin_country) ||
    units.find(u => u.destination_country !== booking.destination_country)
  ) ? error : null
}

function groupageMismatch (booking) {
  const error = 'One or more jobs belong to a different groupage.'

  const groupageId = booking.groupage_id || null
  return booking.units.find(u => u.groupage_id !== groupageId) ? error : null
}

// NOTE: This particular function returns an ARRAY, not a single error!
function hazardClashes (booking) {
  const clashes = (booking.dangerous_goods || {}).clashes // Not always present
  return clashes ? clashes.split('$$') : []
}

function nonAdrTrailer (booking) {
  const error = 'This trailer cannot carry dangerous goods.'

  const isHazardous = booking.units.find(unit => (
    unit.dangerous_goods && !unit.dangerous_goods.ltd_qty
  ))

  return !booking.adr && isHazardous ? error : null
}

function nonWasteTrailer (booking) {
  const error = 'This trailer cannot carry waste.'

  return !booking.waste && booking.units.find(u => u.waste) ? error : null
}

function nonTemperatureControlledTrailer (booking) {
  const error = 'This trailer is not temperature controlled.'

  const needsTempControl = booking.units.find(u => u.temperature_controlled)
  return !booking.temperature_controlled && needsTempControl ? error : null
}

function overWeightLimit (booking) {
  const error = 'Gross weight exceeded'

  const limit = booking.max_gross_weight_kg
  const value = booking.units.sum(u => u.gross_weight_kg || 0)
  return limit && value > limit ? error : null
}

function overVolumeLimit (booking) {
  const error = 'Cubic metres exceeded'

  const limit = parseFloat(booking.max_cubic_metres)
  const value = booking.units.sum(u => u.cubic_metres || 0)
  return limit && value > limit ? error : null
}

function overHeightLimit (booking) {
  const error = 'Trailer height exceeded'

  const trailerHeights = booking.trailers.map(t => t.height_m || 0)
  const packageHeights = booking.units.flatMap(u => u.dimensions_lines.map(dl => dl.height_cm || 0))

  const limit = trailerHeights.reduce(Math.min, Infinity) * 100
  const value = packageHeights.reduce(Math.max, 0)
  return limit && value > limit ? error : null
}

function overLoadingMetresLimit (booking) {
  const error = 'Loading metres exceeded'

  const limit = parseFloat(booking.max_loading_metres)
  const value = booking.units.sum(u => u.loading_metres || 0)
  return limit && value > limit ? error : null
}

// Handles problem-checking for trailer bookings.
class TrailerHelper {
  static majorProblems (booking) {
    const problems = hazardClashes(booking).concat([
      nonAdrTrailer(booking),
      nonWasteTrailer(booking),
      nonTemperatureControlledTrailer(booking),
      overWeightLimit(booking),
      overVolumeLimit(booking),
      overHeightLimit(booking),
      overLoadingMetresLimit(booking)
    ])

    return problems.filter(s => s)
  }

  static minorProblems (booking) {
    const problems = [
      singleLoadJobs(booking),
      singleLoadOnly(booking),
      jobTypeMismatch(booking),
      groupageMismatch(booking)
    ]

    return problems.filter(s => s)
  }
}

export default TrailerHelper
