import React from 'react'

// writeme
function CustomsPricingCategory (props) {
  const values = props.data.map(tier => (
    <div className='customs-pricing-category-tier' key={tier.quantity}>
      <div className='customs-pricing-category-label'>{tier.quantity}</div>
      <button
        className='customs-pricing-category-remove'
        tabIndex='-1'
        onClick={() => props.onRemoveQuantity(tier.quantity)}
      >Remove</button>
      <input
        className='customs-pricing-category-value'
        type='number'
        value={tier.value}
        step='0.01'
        onChange={
          () => props.onChange(props.name, tier.quantity, event.target.value)
        }
      />
    </div>
  ))

  return (
    <div className='customs-pricing-category'>
      <div className='customs-pricing-category-name'>
        <div className='customs-pricing-category-label'>Category</div>
        <input
          className='customs-pricing-category-value'
          type='text'
          value={props.name}
          disabled={true}
        />
        <button
          className='customs-pricing-category-remove'
          tabIndex='-1'
          onClick={() => props.onRemoveCategory(props.name)}
        >Remove</button>
      </div>
      {values}
    </div>
  )
}

export default CustomsPricingCategory
