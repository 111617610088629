import React from 'react'

// Essentially allows HTML to be used as title text.
function PopOver (props) {
  return (
    <span className='trailer-planning-pop-over'>
      <span className='trailer-planning-pop-over-body'>{props.children}</span>
      <span className='trailer-planning-pop-over-title'>{props.title}</span>
    </span>
  )
}

export default PopOver
