import React from 'react'

// Provides a general ribbon , as per standard Rails-rendered views.
function Ribbon (props) {
  return (
    <div id='ribbon' className='clearfix'>
      <div id='ribbon-content'>{props.children}</div>
    </div>
  )
}

export default Ribbon
