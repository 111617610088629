import React from 'react'

// A generic "action" button for notifications.
function NotificationActionButton (props) {
  const a = (event) => { props.onClick(event, props.item) }

  if (!props.item.acknowledged) {
    return (
      <a className="notification-action-button acknowledge" href="#" onClick={a}>
        Acknowledge
      </a>
    )
  }

  if (!props.item.dealtWith) {
    return (
      <a className="notification-action-button deal-with" href="#" onClick={a}>
        Deal With
      </a>
    )
  }

  return null // Already dealt with; no further action necessary.
}

export default NotificationActionButton
