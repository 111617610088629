import React from 'react'

// Components:
import Modal from '../../common/Modal'
import Content from './Confirm/Content'
import Footer from './Confirm/Footer'
// APIs:
import NotificationApi from '../../apis/events/NotificationApi'

// Prompts the user for an acknowledge/confirm reason, if required.
class ConfirmModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      errors: [],
      reason: '',
      notification: null
    }
  }

  componentDidMount () {
    NotificationApi.show(this.props.id).then(
      json => this.setState({ notification: json }),
      json => this.setState({ errors: json.errors || [json.error] })
    )
  }

  handleReasonChange = (reason) => {
    this.setState({ reason: reason })
  }

  handleSubmit = () => {
    const item = this.state.notification

    const api = NotificationApi
    const endpoint = item.acknowledged ? api.dealWith : api.acknowledge

    endpoint(item.id, this.state.reason).then(
      json => {
        this.props.onClose()
        // See components/reactive/events/notifications/withNotifications.jsx
        const fakeWebsocketEvent = new CustomEvent('notification.freightsoft')
        document.body.dispatchEvent(fakeWebsocketEvent)
      },
      json => this.setState({ errors: json.errors || [json.error] })
    )
  }

  render () {
    if (!this.state.notification) {
      return <Modal onClose={this.props.onClose} />
    }

    const title = 'High-Importance Notification'
    const footer = (
      <Footer onSubmit={this.handleSubmit} onClose={this.props.onClose} />
    )

    return (
      <Modal title={title} footer={footer} onClose={this.props.onClose}>
        <Content
          errors={this.state.errors}
          reason={this.state.reason}
          notification={this.state.notification}
          onReasonChange={this.handleReasonChange}
        />
      </Modal>
    )
  }
}

export default ConfirmModal
