import BaseApi from '../BaseApi'
import buildGetQuery from '../buildGetQuery'

// This class handles all API interactions related to event rules.
class EventRuleApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/events/rules.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/events/rules/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/events/rules.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/events/rules/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/events/rules/${id}.json`)
  }

  static actions () {
    return BaseApi.sendRequest('GET', 'api/events/rules/actions.json')
  }

  static fields (model) {
    const params = buildGetQuery({ model: model })
    return BaseApi.sendRequest('GET', 'api/events/rules/fields.json', params)
  }

  static models () {
    return BaseApi.sendRequest('GET', 'api/events/rules/models.json')
  }

  static notifyParties () {
    return BaseApi.sendRequest('GET', 'api/events/rules/notify_parties.json')
  }

  static severities () {
    return BaseApi.sendRequest('GET', 'api/events/rules/severities.json')
  }

  static updateKeys (model) {
    const params = buildGetQuery({ model: model })
    return BaseApi.sendRequest('GET', 'api/events/rules/update_keys.json', params)
  }
}

export default EventRuleApi
