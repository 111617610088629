import React from 'react'

import CreateEmailButton from '../CreateEmailButton'
// Common Components:
import CollapsableSection from '../../../common/CollapsableSection'
// Helpers:
import DateHelper from '../../../helpers/DateHelper'
import PersonHelper from '../../../helpers/PersonHelper'
// I18N:
import enums from '../../../i18n/enums'

// Represents a single email within a list of them.
export default function (props) {
  const body = { __html: props.email.body }

  const label = (
    <div>
      <i className='fa fa-envelope' />
      &nbsp;{DateHelper.formatDateTime(props.email.sentAt)}&nbsp;|&nbsp;
      <b>{props.email.subject}</b>
    </div>
  )

  const recipients = props.email.recipients.map(recipient => (
    <div key={recipient.id} className='recipient'>
      <div className='recipient-cc-type'>
        {enums.en.emails.recipients.recipientTypes[recipient.recipientType]}
      </div>
      <div className='recipient-name'>
        {PersonHelper.fullName(recipient.person)}
      </div>
      <div className='recipient-email'>
        {recipient.email}
      </div>
    </div>
  ))

  const attachments = props.email.contents.map(attachment => (
    attachment.generalFile ? (
      <div key={attachment.id} className='attachment'>
        {attachment.generalFile.attachmentFileName}
      </div>
    ) : (
      <div key={attachment.id} className='attachment deleted'>
        Deleted
      </div>
    )
  ))

  return (
    <CollapsableSection label={label}>
      <div className='reactive-sent-email'>
        <div className='recipients'>{recipients}</div>
        <div className='body' dangerouslySetInnerHTML={body} />
        <div className='attachments'>{attachments}</div>
        <div className='actions'>
          <CreateEmailButton
            label='Forward Email'
            templateType='general'
            modelId={props.email.parentId}
            modelType={props.email.parentType}
            forwardedEmailId={props.email.id}
          />
          {props.onDestroy && (
            <button
              className='btn-labeled btn btn-sm btn-danger'
              onClick={props.onDestroy}
            >
              <i className='btn-label fa fa-trash' /> Delete Email
            </button>
          )}
        </div>
      </div>
    </CollapsableSection>
  )
}
