import React from 'react'

// The form-footer of the "show" view.
function Footer (props) {
  return (
    <React.Fragment>
      <button className='btn' onClick={props.onClose}>
        Close
      </button>
    </React.Fragment>
  )
}

export default Footer
