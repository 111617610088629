import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import CustomsRouteApi from '../../apis/customs/CustomsRouteApi'

// This is the index page, which lists all customs routes.
class CustomsRouteIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'Customs Routes'

    CustomsRouteApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    CustomsRouteApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/routes': 'Customs Routes'
    }

    const headers = [
      { label: 'Code' },
      { label: 'Direction' },
      { label: 'Departure' },
      { label: 'Arrival' },
      { label: 'Carrier' }
      // { label: 'Actions' }
    ]

    const items = this.state.json.map(route => (
      <tr key={route.id}>
        <td>{route.gvmsCode}</td>
        <td>{route.direction}</td>
        <td>{route.departurePort.chiefCode || '???'} - {route.departurePort.name}</td>
        <td>{route.arrivalPort.chiefCode || '???'} - {route.arrivalPort.name}</td>
        <td>{route.carrier.name}</td>
        {false &&
          <td>
              <Link className='btn btn-xs' to={`/reactive/customs/routes/${route.id}/edit`}>
                Edit
              </Link>
            <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(route.id)}>
              Destroy
            </a>
          </td>
        }
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        {false &&
          <Ribbon>
            <div className='pull-right'>
              <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/customs/routes/new'>
                <i className='btn-label icon fa fa-plus' />
                New Route
              </Link>
            </div>
          </Ribbon>
        }
        &nbsp;
        <SimpleTable caption='Customs Routes' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default CustomsRouteIndex
