import React, { Component } from 'react'
import moment from 'moment'

import StateManager from './StateManager'
import UnitBlock from './UnitBlock'
import Problems from './Problems'
import Icon from './Icon'

import Notes from './bookings/Notes'
import Groupage from './bookings/Groupage'
import Controls from './bookings/Controls'
import Flags, { BOOKING_FLAGS } from './bookings/Flags'
import TrailerInfo, { TRAILER_INFO_PROPS } from './bookings/TrailerInfo'

import EuroTunnel from './dg/EuroTunnel'
import Ferry from './dg/Ferry'
import Info from './dg/Info'

const LABELS = {
  pending:     'default',
  in_progress: 'warning',
  complete:    'success',
  loaded:      'success',
  discharged:  'success'
}

class Label extends Component {

  render() {
    let css = `label label-${LABELS[this.props.name]}`
    return (
      <div
        className={this.props.className || ''}
        onClick={this.props.onClick}
        title={this.props.title || null}
      >
        <span className={css}>{this.props.name.humanize()}</span>
      </div>
    )
  }
}

class DangerousGoods extends Component {
  render() {
    return (
      <div className="dangerous_goods" style={this.props.style}>
        {this.props.dgli_classes ? <Info {...this.props} /> : ''}
        {this.props.ferry        ? <Ferry info={this.props.ferry} /> : ''}
        {this.props.eurotunnel   ? <EuroTunnel info={this.props.eurotunnel} /> : ''}
      </div>
    )
  }
}

export default class Booking extends Component {
  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.state = this._getState()
    this.manager.clearBookingAnimation(this.props.id)
    return this
  }

  redraw() {
    this.setState(this._getState())
    this.manager.clearBookingAnimation(this.props.id)
  }

  componentDidMount() {
    this.manager.registerBookingComponent(this)
  }

  componentWillUnmount() {
    this.anim = null
    this.manager.deregisterBookingComponent(this)
  }

  showProgressSelect() {
    if (this._isWarehouse()) { return false }
    this.manager.showProgressSelect(this.props.id)
  }

  _nameAndDate() {
    return [
      this.state.country_code,
      moment(this.state.date_of_use).format("DD/MM/YY")
    ].select(x => x).join(' - ')
  }

  _hub(hub) {
    let css = { width: this.manager.columnWidth(hub) }
    return <span className="hub" style={css} title={this.state[hub]}>
      {this.state[hub]}
    </span>
  }

  toggleExpanded(e) {
    this.manager.toggleBlockExpanded(this.props.id)
  }

  _width(col) {
    return { width: this.manager.columnWidth(col) }
  }

  _multiWidth(cols) {
    return { width: `${this.manager.multiColumnWidth(cols)}%` }
  }

  _problems() {
    return <Problems
      type="booking"
      {...this.manager.bookingProblems(this.props.id)}
      style={this._width('problems')}
    />
  }

  _progressName() {
    if (this.state.progress == 'complete') {
      return this.state.origin_country == 'GB' ? "loaded" : "discharged"
    }
    return this.state.progress
  }

  render() {
    let css = `trailer booking-${this._getAnimation() || 'default'}`

    // We can't just float: right cos that causes issues for the popover
    let control_width = 98.5 - this.manager.columnOffset('collection')
    let control_style = { maxWidth: `${control_width}%` }

    let notes_span = this._multiWidth([
      'package_details', 'gross_weight_kg', 'cubic_metres', 'loading_metres',
      'taxable_weight', 'notes_status', 'consignment_flags'
    ])

    let deadspace_span = this._multiWidth([
      'ready_date', 'delivery_required_by', 'collection_date', 'due_in_date'
    ])

    return(
    <div className={css}>
      <div className="head">
        <Icon icon="truck" onClick={this.toggleExpanded.bind(this)} />
        <span className="name" style={this._width('name')}>
          {this._nameAndDate()}
        </span>
        <span className="supplier" style={this._multiWidth(['shipper', 'shipping_postcode'])}>
          {this.state.supplier}
        </span>
        <span
          className="registrations"
          style={this._multiWidth(['consignee', 'consignee_postcode'])}
        >
          <TrailerInfo {...this.state.only(TRAILER_INFO_PROPS)} />
        </span>
        <Notes {...this.state.notes} style={notes_span} />
        <DangerousGoods {...this.state.dangerous_goods}
          style={this._width('dangerous_goods')}
        />
        <div style={deadspace_span} />
        <div style={this._width('customs_status')}>
          <Label
            className="booking-progress"
            title={this._progressTitle()}
            name={this._progressName()}
            onClick={this.showProgressSelect.bind(this)}
          />
        </div>
        {this._problems()}
        {this._hub('origin_hub')}
        {this._hub('destination_hub')}
        <div className="flags" style={this._width('collection')}>
          <Flags {...this.state.only(BOOKING_FLAGS)} />
        </div>
        <div className="groupage" style={this._width('delivery')}>
          <Groupage
            id={this.state.groupage_id}
            name={this.state.groupage}
            booking_id={this.props.id}
            controller={this.state.groupage_controller}
            style={{ width: '100%' }}
          />
        </div>
        <div className="controls">
          <Controls id={this.props.id} progress={this.state.progress} />
        </div>
      </div>
      <UnitBlock id={this.props.id} />
    </div>
    )
  }

// private

  _isWarehouse() {
    return this.state.goods_in || this.state.goods_out
  }

  _progressTitle() {
    return [
      this._arrivalTime(),
      this._isWarehouse() && 'The status of this trailer is controlled by the warehouse.'
    ].select(x => x).join('. ').presence()
  }

  _arrivalTime() {
    if (!this.state.actual_arrival_time) { return '' }
    let time = moment(this.state.actual_arrival_time).format("HH:mm on DD/MM/YY")
    return `Actual arrival time: ${time}`
  }

  _getState() {
    return this.manager.getBookingState(this.props.id)
  }

  _getAnimation() {
    let anim = this.state.action
    if (anim) {
      this.anim = anim
      setTimeout(this.redraw.bind(this), 5000)
    }
    return anim
  }

}
