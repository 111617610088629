import React from 'react'

import TrailerBooking from './TrailerBooking'
import PleaseWait from '../../reactive/common/PleaseWait'

// This represents the "trailers" section of the trailer planner screen.
function TrailerSection (props) {
  if (!props.bookings) { return <PleaseWait /> }

  const bookings = props.bookings.slice().sort(
    (a, b) => a.date_of_use < b.date_of_use ? -1 : 1
  )

  const items = bookings.map(b => (
    <TrailerBooking
      key={b.id}
      booking={b}
      cotDotBookings={props.cotDotBookings}
    />
  ))

  return <div className='trailer-planning-trailer-section'>{items}</div>
}

export default TrailerSection
