import React from 'react'

// Allows the old (jQuery) email modal to be invoked from new (React) code.
export default class extends React.Component {
  handleClick = () => {
    const params = {
      email_template_type: this.props.templateType,
      model_id: this.props.modelId,
      parent_type: this.props.modelType,
      forwarded_email: this.props.forwardedEmailId
    }

    $.ajax('/emails/new', { data: params })
  }

  render () {
    const label = this.props.label || 'Send Email'

    return (
      <a className='btn-labeled btn btn-sm btn-info' onClick={this.handleClick}>
        <i className='btn-label fa fa-envelope-o' /> {label}
      </a>
    )
  }
}
