import React from 'react'

// These buttons are for non-filter actions shown at the top of the screen.
class GeneralActions extends React.Component {
  constructor (props) {
    super(props)

    this.state = { expanded: false }
  }

  handleExpandOrCollapseAll = () => {
    const foo = this.state.expanded ? 'expanded' : 'collapsed'
    const css = `.trailer .trailer-planning-planning-unit-list-show-hide.${foo}`
    Array.prototype.forEach.call(document.querySelectorAll(css), e => e.click())
    this.setState({ expanded: !this.state.expanded })
  }

  excelDownloadPath () {
    if (!this.props.bookings || !this.props.units) { return '#' } // Loading...
    const active = this.props.units.filter(u => u.status !== 'pending')
    const pending = this.props.units.filter(u => u.status === 'pending')

    const params = new URLSearchParams()
    params.set('bookings', this.props.bookings.map(item => item.id).join(','))
    params.set('unassigned', active.map(item => item.id).join(','))
    params.set('pending', pending.map(item => item.id).join(','))
    return `/trailer_planners/excel?${params}`
  }

  render () {
    const expandOrCollapseVerb = this.state.expanded ? 'Collapse' : 'Expand'

    const expandOrCollapseIcon = this.state.expanded ? (
      <i className='fa fa-arrow-up'></i>
    ) : (
      <i className='fa fa-arrow-down'></i>
    )

    const multiWindowTitleText = this.props.multiWindow ? (
      'Show unassigned jobs'
    ) : (
      'Move unassigned jobs to new window'
    )

    return (
      <div className='trailer-planning-general-actions'>
        <a
          title='Create new trailer booking'
          className='btn btn-sm btn-labeled btn-primary'
          href='/trailer_bookings/new'
          data-remote='true'
        >
          <i className='fa fa-plus btn-label'></i> New Booking
        </a>
        <a
          title='Flag a job as "always show on planner"'
          className='btn btn-sm btn-warning'
          href='/trailer_planners/jobs/include'
          data-remote='true'
        >
          <i className='fa fa-thumb-tack'></i>
        </a>
        <button
          title={multiWindowTitleText}
          className='btn btn-sm btn-success'
          onClick={this.props.onMultiWindowToggle}
        >
          {this.props.multiWindow ? (
            <i className='fa fa-window-maximize'></i>
          ) : (
            <i className='fa fa-window-restore'></i>
          )}
        </button>
        <button
          title={`${expandOrCollapseVerb} all visible bookings`}
          className='btn btn-sm btn-danger'
          onClick={this.handleExpandOrCollapseAll}
        >
          {expandOrCollapseIcon}
        </button>
        <a
          title='Export current view to excel'
          className='btn btn-sm btn-info'
          href={this.excelDownloadPath()}
        >
          <i className='fa fa-file-excel-o'></i>
        </a>
      </div>
    )
  }
}

export default GeneralActions
