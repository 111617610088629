
const DEFAULTS = {
  type:      'warning',
  mouseover: 'pause'
}

export default class Growl {
  constructor(args) {
    $.notify({ 
      title:   `<p><strong>${args.title}</strong></p>`,
      message: args.message 
    }, DEFAULTS.merge(args.except('title', 'message')))
  } 

}
