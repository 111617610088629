import React from 'react'

import DropdownButton from '../../reactive/common/DropdownButton'

// This contains the various option for the right-hand drop-down menu.
function TrailerBookingDropdown (props) {
  const id = props.booking.id

  return (
    <DropdownButton theme='warning' size='btn-sm'>
      <li>
        <a href={`trailer_bookings/pdfs/${id}/picking_list`} target='_blank'>
          Picking List PDF
        </a>
      </li>
      <li>
        <a href={`trailer_bookings/pdfs/${id}/plan_driver_running_sheet`} data-remote={true}>
          Driver Running Sheet
        </a>
      </li>
      <li>
        <a href={`trailer_bookings/${id}/edit`} data-remote={true}>
          Edit
        </a>
      </li>
      {props.booking.progress === 'pending' ? (
        <li>
          <a
            href={`trailer_bookings/${id}`}
            data-remote={true}
            data-method='delete'
            data-confirm='Are you sure?'
          >
            Delete
          </a>
        </li>
      ) : null}
    </DropdownButton>
  )
}

export default TrailerBookingDropdown
