import React from 'react'

import DropDown from '../../reactive/common/Forms/DropDown'
import CustomsNctsShipmentApi from '../../reactive/apis/customs/CustomsNctsShipmentApi'

// A simple wrapper around the standard drop-down for CCGs specifically.
export default function (props) {
  return (
    <DropDown
      source={CustomsNctsShipmentApi.ccgOptions}
      format={ccg => `${ccg.id} (${ccg.description})`}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
    />
  )
}
