import BaseApi from '../BaseApi'

// This class handles all API interactions related to printers.
class PrintQueueApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/printers/queues.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/printers/queues/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/printers/queues.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/printers/queues/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/printers/queues/${id}.json`)
  }

  static categories () {
    return BaseApi.sendRequest('GET', 'api/printers/queues/categories.json')
  }

  static devices () {
    return BaseApi.sendRequest('GET', 'api/printers/queues/devices.json')
  }
}

export default PrintQueueApi
