import React from 'react'

// Components:
import NotificationViewButton from './NotificationViewButton'
import NotificationActionButton from './NotificationActionButton'

// Helpers:
import DateHelper from '../../helpers/DateHelper'

// An individual item within a notification list.
function NotificationListItem (props) {
  return (
    <div className={`notification-list-item ${props.item.severity}`}>
      <div className="title">{props.item.title}</div>
      <div className="description">{props.item.message}</div>
      <div className="severity">{props.item.severity}</div>
      <div className="datetime">{DateHelper.formatDateTime(props.item.createdAt)}</div>
      <NotificationViewButton item={props.item} onClick={props.onView} />
      <NotificationActionButton item={props.item} onClick={props.onAction} />
    </div>
  )
}

export default NotificationListItem
