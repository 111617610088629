import React from 'react'

// Problems icon for individual job parts.
function PlanningUnitProblems (props) {
  const severity = props.unit.problems
  if (!severity) { return null }

  const href = `/trailer_planners/units/${props.unit.id}`
  const title = `${severity.toUpperCase()} problems may exist. Click to check.`
  const className = `trailer-plannning-planning-unit-problems ${severity}`

  return (
    <a className={className} href={href} data-remote={true} title={title}>
      {severity.toUpperCase()} problems found
    </a>
  )
}

export default PlanningUnitProblems
