import React from 'react'

import CcgDropdown from './CcgDropdown'
import NctsJobList from './NctsJobList'
// Common components:
import ErrorList from '../../reactive/common/ErrorList'
import DropDown from '../../reactive/common/Forms/DropDown'
import FieldList from '../../reactive/common/Forms/FieldList'
import FormField from '../../reactive/common/Forms/FormField'
// APIs:
import CustomsNctsShipmentApi from '../../reactive/apis/customs/CustomsNctsShipmentApi'

// This is the form we display in the "manual creation" modal for NCTS records.
export default function (props) {
  return (
    <div className='manual-ncts-shipment-form'>
      <ErrorList errors={props.errors} />

      <div className='alert alert-warning'>
        <i className='fa fa-exclamation-triangle'></i>&nbsp;
        You are about to create a &quot;manual&quot; NCTS record; these details
        will <i>not</i> be uploaded to the CustomsPro API.
      </div>

      <FieldList>
        <FormField label='Partner'>
          <input
            className='form-control'
            disabled={true}
            value={props.partner}
          />
        </FormField>
        <FormField label='Reference'>
          <input
            className='form-control'
            autoFocus={true}
            value={props.reference}
            onChange={props.onReferenceChange}
          />
        </FormField>
        <FormField label='CCG'>
          <CcgDropdown
            value={props.selectedCcg}
            onChange={props.onCcgChange}
          />
        </FormField>
        <FormField label='ALCI'>
          <DropDown
            source={CustomsNctsShipmentApi.alciOptions}
            value={props.selectedAlci}
            onChange={props.onAlciChange}
          />
        </FormField>
      </FieldList>

      <div className='well'>
        <NctsJobList jobs={props.jobs} />
      </div>
    </div>
  )
}
