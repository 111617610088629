import React, { Component } from 'react'

import IconBadge from '../IconBadge'

// Phil wants a very specific order and abbreviation styles for some reason
const KEYS = {
  single_load:            "Single Load",
  do_not_stack:           "Do Not Stack",
  do_not_transship:       "Do Not Transship",
  waste:                  "Waste",
  foodstuffs:             "Food",
  temperature_controlled: "Temperature",
  hazardous:              "Hazardous",
  overlength:             "Overlength",
  must_load:              "Must Load",
  unaccompanied:          "Unaccompanied",
  ferry:                  "Ferry",
  tall_packages:          "Tall packages",
  tail_lift_on_collection: "Tail Lift (Col)",
  tail_lift_on_delivery:   "Tail Lift (Del)"
}

export default function IconKey (props) {
  return (
    <div className="icon-key consignment_flags">
      {KEYS.keys().map(f => <IconBadge name={f} key={f} text={KEYS[f]} />)}
    </div>
  )
}
