import React from 'react'

// This simply displays a list of jobs.
export default function (props) {
  // FMS-8422: "Also, please add warning if a DOT is included on [a] bulk NCTS"
  const dotWarning = (
    <i
      className='fa fa-exclamation-circle text-danger'
      title='This job is marked as DOT'
    />
  )

  const jobs = props.jobs.map(job => (
    <span key={job.id}>
      {props.jobs.length > 1 && job.deliveryOnTrailer && dotWarning}
      <a href={`/jobs/${job.id}`} target='_blank'>{job.jobReference}</a>
    </span>
  ))

  return <div className='ncts-job-list'>{jobs}</div>
}
