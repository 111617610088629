import React, { Component } from 'react'

import Button from '../Button'

import StateManager from '../StateManager'

export default class ExpandAll extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerExpandAll(this)
    this.state = this.manager.getExpandAllState()
  }

  redraw() {
    // Should check if we are mounted!
    this.setState(this.manager.getExpandAllState())
  }

  render() {
    let icon = this.state.expanded ? 'up' : 'down'

    return <span
      className="btn btn-sm btn-danger btn-expand-all"
      onClick={this.onClick.bind(this)}
      title={`${this.state.expanded ? 'Collapse' : 'Expand'} all visible bookings`}
    >
      <i className={`fa fa-fw fa-arrow-${icon}`} />
    </span>
  }

  onClick() {
    this.manager.expandAll()
  }

}

