import React from 'react'

import DropDown from '../../common/Forms/DropDown'
import OfficeLocationApi from '../../apis/customs/CustomsOfficeLocationApi'

// A simple wrapper around the standard drop-down for customs offices.
export default function (props) {
  return (
    <DropDown
      source={OfficeLocationApi.all}
      format={office => `${office.nctsCode} - ${office.name}`}
      value={props.value}
      onChange={props.onChange}
    />
  )
}
