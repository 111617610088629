import React from 'react'

import DepartmentApi from '../../api/DepartmentApi'

// Get an alphabetical list of origin/destination countries without duplicates:
function countriesFrom (department, countryField) {
  const countries = department.jobTypes.map(jt => jt[countryField].countryCode)
  return Array.from(new Set(countries)).sort().join() // Example: "AA,BB,CC"
}

// Filter by "department" i.e. a predefined list of origins and destinations.
class DepartmentFilter extends React.Component {
  constructor (props) {
    super(props)

    this.state = { departments: [] }
  }

  componentDidMount () {
    DepartmentApi.all().then(
      json => this.setState({ departments: json.items }),
      json => console.log('ERROR:', json)
    )
  }

  // We don't specify this directly -- we infer it from the selected countries:
  currentDepartmentId () {
    const origins = this.props.originCountry.split(',').sort().join()
    const destinations = this.props.destinationCountry.split(',').sort().join()

    const department = this.state.departments.find(d => {
      if (countriesFrom(d, 'originCountry') !== origins) { return false }
      return countriesFrom(d, 'destinationCountry') === destinations
    })

    return department ? department.id : null
  }

  // This basically just sets multiple origin/destination countries in one go:
  handleChange = (event) => {
    const selectedValue = Number(event.target.value)
    const department = this.state.departments.find(d => d.id === selectedValue)
    if (!department) { return } // Should never happen -- but just in case...

    const origins = countriesFrom(department, 'originCountry')
    this.props.onOriginCountryChange({ target: { value: origins } })

    const destinations = countriesFrom(department, 'destinationCountry')
    this.props.onDestinationCountryChange({ target: { value: destinations } })
  }

  render () {
    const options = this.state.departments ? this.state.departments.map(d => (
      <option key={d.id} value={d.id}>{d.name}</option>
    )) : null

    return (
      <select
        className='trailer-planning-department-filter'
        required={true}
        value={this.currentDepartmentId() || ''}
        onChange={this.handleChange}
      >
        <option value='' disabled={true}>Select department...</option>
        {options}
      </select>
    )
  }
}

export default DepartmentFilter
