import React from 'react'

// Show information about an individual dimensions line item.
function DimensionsLine (props) {
  return (
    <div className='trailer-planning-dimensions-line'>
      <div className='trailer-planning-dimensions-line-quantity'>
        {props.dimensionsLine.quantity}
      </div>
      <div className='trailer-planning-dimensions-line-type'>
        {props.dimensionsLine.package_type}
      </div>
      <div className='trailer-planning-dimensions-line-length'>
        {props.dimensionsLine.length_cm}
      </div>
      <div className='trailer-planning-dimensions-line-width'>
        {props.dimensionsLine.width_cm}
      </div>
      <div className='trailer-planning-dimensions-line-height'>
        {props.dimensionsLine.height_cm}
      </div>
    </div>
  )
}

export default DimensionsLine
