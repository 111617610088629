import React from 'react'

import StandardIcon from './StandardIcon'

export default function (props) {
  const cssClass = `badge badge-${props.color || 'warning'}`

  return (
    <span className={cssClass} title={props.title}>
      <StandardIcon type={props.icon} />
    </span>
  )
}
