import React from 'react'

import DropDown from '../../common/Forms/DropDown'
import CountryApi from '../../apis/places/CountryApi'

// A simple wrapper around the standard drop-down for countries specifically.
function CountryDropdown (props) {
  return (
    <DropDown
      source={CountryApi.all}
      format={country => country.name}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

export default CountryDropdown
