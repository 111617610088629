import React, { Component } from 'react'
import Select from 'react-select'
import Modal from 'react-modal'

import Button from './Button'

import StateManager from './StateManager'

const customStyles = {
  content: {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '35em',
    padding               : 0,
    overflow              : 'visible'
  }
}

export default class BookingSelector extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerBookingSelector(this)

    this.state = {
      modalIsOpen: false,
      options:     [],
      unit_id:     null,
      booking_id:  null,
      name:        null
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.submit = this.submit.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  afterOpenModal() {
    this.select.focus()
  }

  show() {
    let state = this.manager.getBookingSelectorState()
    state.modalIsOpen = true
    this.setState(state)
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.submit.bind(this)}
          onAfterOpen={this.afterOpenModal}
          contentLabel={this.state.title || 'Bethanies are great'}
          style={customStyles}
        >
          <div className="booking-selector-modal modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {`${this.state.type} for ${this.state.name}`}
              </h4>
            </div>
            <div className="modal-body">
              <Select
                options={this._options()}
                simpleValue={true}
                placeholder={`${this.state.type} trailer...`}
                value={this.state.booking_id}
                onChange={this.setBooking.bind(this)}
                ref={x => this.select = x}
              />
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={this.submit}>
                Set {this.state.type}
              </button>
              <button className="btn btn-default" onClick={this.closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  setBooking(id) {
    this.setState({ booking_id: id })
  }

  submit() {
    this.manager.changeColivery(
      this.state.unit_id,
      this.state.type,
      this.state.booking_id
    )
    this.closeModal()
  }

  _options() {
    return this.state.options.map(b => ({
      value: b.id,
      label: this._label(b)
    }))
  }

  _label(b) {
    return [
      b.trailer_numbers,
      b.vehicle_registration,
      b.supplier,
      b.groupage,
      (b.date_of_use && b.date_of_use.format("DD/MM/YY"))
    ].select(x => x).join(" / ")
  }

}
