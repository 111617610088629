import React from 'react'

import SimpleTable from '../../../common/SimpleTable'
// Helpers:
import DateHelper from '../../../helpers/DateHelper'
// I18N:
import enums from '../../../i18n/enums'

// The "Communications" tab of the "Show NCTS Discrepancy" page.
export default function (props) {
  const headers = [
    { label: 'Kind' },
    { label: 'Date' },
    { label: 'Details' },
    { label: 'Actions' }
  ]

  const items = props.discrepancy.communications.map(communication => (
    <tr key={communication.id}>
      <td>{enums.en.customs.discrepancies.communications.kinds[communication.kind] || communication.kind}</td>
      <td>{DateHelper.formatDate(communication.date)}</td>
      <td>{communication.details}</td>
      <td>
        <button
          className='btn btn-xs'
          onClick={() => props.onUpdate(communication)}
        >
          Edit
        </button>
        &nbsp;
        <button
          className='btn btn-xs btn-danger'
          onClick={() => props.onDelete(communication)}
        >
          Destroy
        </button>
      </td>
    </tr>
  ))

  return (
    <div className='ncts-discrepancy-show-communications'>
      <SimpleTable caption='Communications' headers={headers}>
        {items}
      </SimpleTable>
      <button className='btn btn-labeled btn-success' onClick={props.onCreate}>
        <i className='btn-label fa fa-plus' /> Add Communication
      </button>
    </div>
  )
}
