import React from 'react'

// Components:
import NotificationListItem from './NotificationListItem'

// Displays a list of notifications (for the navbar or "dropdown" buttons).
function NotificationList (props) {
  const href = '/reactive/events/notifications'

  let items = <div className='notification-none'>Nothing to display</div>
  if (!props.items) {
    items = <div className='notification-none'>Loading...</div>
  } else if (props.items.length) {
    items = props.items.map(item => (
      <NotificationListItem
        key={item.id}
        item={item}
        onView={props.onView}
        onAction={props.onAction}
      />
    ))
  }

  return (
    <div className="notification-list">
      <div className='notification-item-container'>{items}</div>
      <a className='view-all' href={href} onClick={props.onViewAll}>
        View all notifications
      </a>
    </div>
  )
}

export default NotificationList
