import React from 'react'

// This is used to filter job/trailers ia free-type text.
function TextFilter (props) {
  return (
    <input
      className='trailer-planning-text-filter'
      placeholder='Search Trailers...'
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
    />
  )
}

export default TextFilter
