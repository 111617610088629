import React, { Component } from 'react'

import Routes, { url_for } from '../Routes'
import AjaxRequest from '../AjaxRequest'

// This should really be a react component, but need to improve state management
export default class Notes {

  constructor(props) {
    this.props = props
  }

  render() {
    return (
      <div className="notes-flags">
        {this._notes()}
        {this._address()}
      </div>
    )
  }

  onClickNotes(e) {
    this._getDetails(`${this._noteTypes()[0]}_notes`)
    e.stopPropagation() // don't select this row
  }

  onClickAddress(e) {
    this._getDetails('address_details')
    e.stopPropagation() // don't select this row
  }

// private

  _address() {
    if (!this.props.address_status || this.props.address_status == 'none') { return null }
    return <span
      className="badge address-details"
      onClick={this.onClickAddress.bind(this)}
      title={`${this.props.address_status.humanize()} details may exist. Click to check.`}
    >
      <i className="fa fa-address-card" />
    </span>
  }

  _noteTypes() {
    let types = []
    if (this.props.job) { types.push('job') }
    if (this.props.customer) { types.push('customer') }
    return types
  }

  _notes() {
    let types = this._noteTypes()
    if (types.empty()) { return null }

    return <span
      className="badge badge-success"
      onClick={this.onClickNotes.bind(this)}
      title={`${types.join(' and ').humanize()} notes may exist. Click to check.`}
    >
      <i className="fa fa-exclamation-circle" />
    </span>
  }

  _getDetails(tab) {
    new AjaxRequest({
      method: "GET",
      url: url_for("trailer_planners_unit_path", this.props.id, { active_tab: tab })
    })
  }

}
