import React from 'react'

import CustomsPricingCategory from './CustomsPricingCategory'

// writeme
class CustomsChargesForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      category: '',
      quantity: ''
    }
  }

  handleCategoryChange = (event) => {
    this.setState({ category: event.target.value })
  }

  handleCategorySubmit = () => {
    this.props.onAddCategory(this.state.category)
    this.setState({ category: '' })
  }

  handleQuantityChange = (event) => {
    const value = Number(event.target.value.replace(/\D/g, ''))
    this.setState({ quantity: value > 0 ? String(value) : '' })
  }

  handleQuantitySubmit = () => {
    this.props.onAddQuantity(Number(this.state.quantity))
    this.setState({ quantity: '' })
  }

  render () {
    const rows = Object.keys(this.props.categories).sort().map(category => (
      <CustomsPricingCategory
        key={category}
        name={category}
        data={this.props.categories[category]}
        onRemoveQuantity={this.props.onRemoveQuantity}
        onRemoveCategory={this.props.onRemoveCategory}
        onChange={this.props.onValueChange}
      />
    ))

    return (
      <div className='customs-charges-form'>
        <div className='customs-charges-form-rows'>{rows}</div>
        <div className='customs-charges-form-new-category'>
          <label>
            Add new category
          </label>
          <input
            className='form-control'
            value={this.state.category}
            onChange={this.handleCategoryChange}
          />
          <button
            className='btn btn-success'
            disabled={!this.state.category}
            onClick={this.handleCategorySubmit}
          >
            Insert row
          </button>
        </div>
        <div className='customs-charges-form-new-quantity'>
          <label>
            Add new band
          </label>
          <input
            className='form-control'
            type='number'
            min='1'
            max='99'
            value={this.state.quantity}
            onChange={this.handleQuantityChange}
          />
          <button
            className='btn btn-success'
            disabled={!this.state.quantity}
            onClick={this.handleQuantitySubmit}
          >
            Insert column
          </button>
        </div>
        <div className='customs-charges-form-actions'>
          <button className='btn btn-default' onClick={this.props.onReset}>
            Reset
          </button>
          <button className='btn btn-primary' onClick={this.props.onSubmit}>
            Save
          </button>
        </div>
      </div>
    )
  }
}

export default CustomsChargesForm
