import React from 'react'

import CustomsSubmissionApi from '../reactive/apis/customs/CustomsSubmissionApi'
import showErrors from './showErrors'

// This is used on the Groupage screen to submit details to an ICS API.
class IcsButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = { reference: props.reference }
  }

  handleSubmit = () => {
    const lines = [
      'Upload ICS customs data? The following settings will be used:',
      '',
      `• FERRY: ${this.props.ferry ? 'Yes' : 'No (Eurotunnel)'}`,
      `• SUPPLIER: ${this.props.supplier}`,
      `• ACTIVE CARRIER: ${this.props.carrier}`,
      '',
      'NOTE: A wagon number should be provided for non-Ferry movements.'
    ]

    if (!window.confirm(lines.join('\n'))) { return }

    CustomsSubmissionApi.submitIcsMovement(this.props.id).then(
      json => this.setState({ reference: json.reference }),
      json => showErrors(json.errors || [json.error])
    )
  }

  render () {
    if (this.state.reference) {
      return (
        <button className='btn btn-labeled btn-sm btn-success' onClick={this.handleSubmit}>
          <i className='btn-label fa fa-upload' /> ENS: {this.state.reference}
        </button>
      )
    }

    return (
      <button className='btn btn-labeled btn-sm btn-default' onClick={this.handleSubmit}>
        <i className='btn-label fa fa-upload' /> ENS: Upload
      </button>
    )
  }
}

export default IcsButton
