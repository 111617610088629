import React from 'react'
import moment from 'moment'

import AjaxRequest from './AjaxRequest'
import Routes, { url_for } from './Routes'

import Growl from './Growl'

import PlannerCollection from './manager/PlannerCollection'
import BookingCheck from './manager/BookingCheck'
import EventTracker from './manager/EventTracker'
import TimePeriods from './manager/TimePeriods'

let singleton = null

window.unitManagerDebug = function() {
  const manager = new StateManager()
  return {
    units: manager.units,
    blocks: manager.blocks,
    bookings: manager.bookings,
    planner: manager.planner,
    job_unit_count_cache: manager.job_unit_count_cache,
    manager: manager,
    event_tracker: manager.event_tracker
  }
}

window.websocketUpdate = function(data) {
  return new StateManager().websocketUpdate(data)
}

const COLUMNS_WITH_SORT_TYPE = {
  name:                 'string',
  customer:             'string',
  shipper:              'string',
  shipping_region:      'string',
  shipping_postcode:    'string',

  consignee:            'string',
  consignee_region:     'string',
  consignee_postcode:   'string',
  package_details:      'number',
  gross_weight_kg:      'number',

  cubic_metres:         'number',
  loading_metres:       'number',
  taxable_weight:       'number',
  notes_status:         'none',
  consignment_flags:    'none',

  dangerous_goods:      'none',
  ready_date:           'string', // In the future we will probably use 'date' here
  delivery_required_by: 'string',
  collection_date:      'string',
  due_in_date:          'string',

  customs_status:       'string',
  problems:             'none',
  origin_hub:           'string',
  destination_hub:      'string',
  collection:           'string',
  delivery:             'string'
}

export const COLUMNS = COLUMNS_WITH_SORT_TYPE.keys()

const DEFAULT_COLUMNS = COLUMNS.reject(
  c => ["customer", "shipping_region", "consignee_region"].includes(c)
)

// Default is '1'
const COLUMN_WIDTHS = {
  name:                 1.54,
  shipper:              1.40,
  consignee:            1.40,
  package_details:      0.80,
  taxable_weight:       0.80,
  loading_metres:       0.80,
  consignment_flags:    2.00,
  dangerous_goods:      1.85,
  collection:           2.00,
  delivery:             2.00,
  ready_date:           0.73, // It must be exactly 50px at 1920px width, because 癲
  delivery_required_by: 0.73,
  collection_date:      0.73,
  due_in_date:          0.73,
  customs_status:       1.25,
  problems:             0.55,
}

const SEARCHABLE = [
  "name", "customer", "consignee", "shipper", "shipping_region", "consignee_region",
  "origin_hub", "destination_hub"
]

const DEFAULT_PROGRESSES = ["pending", "in_progress", "arrived"]

const VALID_PROGRESSES = ["pending", "arrived", "in_progress", "complete"]

export const debug = msg => console.log(msg)

export default class StateManager {

  constructor() {
    if (singleton) { return singleton }
    singleton = this
    moment.locale('en-GB')
    this.units = {}
    this.blocks = {}
    this.bookings = {}
    this.planner = {}
    this.columns = DEFAULT_COLUMNS
    this.expanded = {}
    this.page = {}
    this.sort_method = {}
    this._initColumnWidths()
    this.groupage_financials_components = {}
    this.groupage_financials = {}
    this.booking_components = {}
    this.week_filters = {}

    return singleton
  }

  unitControlProps(id) {
    let unit = this.unitDetails(id) || {}
    let booking = this.bookingDetails(unit.block_id) || {}

    return {
      id:                  id,
      is_whole_job:        this.jobUnitCount(unit.job_id) == 1,
      job_groupage_id:     unit.groupage_id,
      job_id:              unit.job_id,
      booking_groupage_id: booking.groupage_id,
      job_road_details_id: unit.job_road_details_id,
      show_on_planner:     unit.show_on_planner
    }
  }

  jobUnitCount(job_id) {
    this.job_unit_count_cache = this.job_unit_count_cache || this._countJobUnits()
    return this.job_unit_count_cache[job_id]
  }

  visibleColumns() {
    return this.columns
  }

  columnWidth(c) {
    return `${this.column_widths[c]}%`
  }

  multiColumnWidth(cols) {
    return cols.map(c => this.column_widths[c]).sum()
  }

  columnOffset(column) {
    this.column_offsets[column] = this.column_offsets[column] || this._columnOffset(column)
    return this.column_offsets[column]
  }

  setPopOut(bool) {
    this.popout = bool
    this.planner.instance.redraw()
  }

  registerPlanner(planner) {
    this.planner = {
      instance:         planner,
      departments:      {},
      multi_window:     planner.props.multi_window,
      start_date:       moment(planner.props.start_date),
      end_date:         moment(planner.props.end_date),
      bookings_periods: new TimePeriods(planner.props.bookings_start, planner.props.bookings_end),
      progress:         DEFAULT_PROGRESSES,
      pending_requests: 0,
      countries:        planner.props.countries
    }
    planner.props.departments.each(d => this.planner.departments[d.id] = d)
    this._setLocalFilters()
    this.event_tracker = new EventTracker(planner.props.event_id)
  }

  getPlannerState() {
    return {
      bookings:         this.plannerBookings(),
      popout:           this.popout,
      pending_requests: this.planner.pending_requests
    }
  }

  registerDateFilter(date_filter) {
    this.planner.date_filter = date_filter
  }

  getDateRange() {
    return { start: this.planner.start_date.clone(), end: this.planner.end_date.clone() }
  }

  isDateRangeSingle() {
    return this.planner.start_date.isSame(this.planner.end_date)
  }

  setDateRange(start, end) {
    this.planner.start_date = start
    this.planner.end_date = end
    this.planner.date_filter.redraw()
    this._fetchWeekIfMissing(start, end)
    this._preemptLoadWeeks(start, end)
    this.expand_all = false
    this.expand_all_instance.redraw()
    this.planner.instance.redraw()
  }

  registerProgressFilter(filter) {
    this.planner.progress_filter = filter
  }

  getProgresses() {
    return { progress: this.planner.progress || "" }
  }

  setProgresses(progress) {
    localStorage.setItem('tp-progress', progress)
    this.planner.progress = progress
    this.planner.instance.redraw()
    this.planner.progress_filter.redraw()
  }

  getDepartments() {
    return this.planner.departments.values()
  }

  getDepartmentId() {
    return this.planner.department_id
  }

  setDepartment(id) {
    this.setOrigin(id && this.planner.departments[id].origin)
    this.setDestination(id && this.planner.departments[id].destination)
    this.planner.department_id = id
  }

  registerCountryFilter(country_filter) {
    this.planner.country_filter = country_filter
  }

  getCountries() {
    return { origin: this.planner.origin, destination: this.planner.destination }
  }

  setOrigin(countries) {
    this.planner.origin = countries
    this._countriesChange()
  }

  setDestination(countries) {
    this.planner.destination = countries
    this._countriesChange()
  }

  registerBlock(block) {
    this.blocks[block.props.id] = block
  }

  setDropTarget(obj) {
    this.drop_target && this.drop_target.setState({ dragover: false })
    this.drop_target = obj
  }

  clearDropTarget() {
    this.drop_target = null
  }

  getSortMethod(id) {
    return this.sort_method[id] || { column: this.visibleColumns()[0] }
  }

  setSortMethod(id, method) {
    let previous_state = this.getSortMethod(id)
    method.previous = previous_state.column
    if (method.column == method.previous) { method.previous = previous_state.previous }
    this.sort_method[id] = method
    this._updateBlock(id)
  }

  getOnTrailer(id, colivery) {
    return this.units[id][`${colivery}_on_trailer`]
  }

  getMovements(id, colivery) {
    let is_delivery = colivery == 'delivery'
    return (this.units[id].movements || []).select(m => m.is_delivery == is_delivery)
  }

  requiresMovement(id, colivery) {
    let country = colivery == 'collection' ? 'origin_country' : 'destination_country'
    return this.units[id][country] == 'GB'
  }

  getColiveryBooking(id, colivery) {
    return this.bookings[this.units[id][`${colivery}_booking`]] || {}
  }

  unitDetails(id) {
    return this.units[id]
  }

  getBookingGroupageId(id) {
    return this.bookings[id] && this.bookings[id].groupage_id
  }

  getBlockPage(id) {
    return this.page[id] || 0
  }

  setBlockPage(id, page) {
    this.page[id] = page
  }

  isBlockExpanded(id) {
    return this.expanded[id]
  }

  toggleBlockExpanded(id) {
    let status = !this.expanded[id]
    this.expanded[id] = status
    this._updateBlock(id)
    return status
  }

  getWeekFilter(id) {
    return this.week_filters[id] || 'All Weeks'
  }

  setWeekFilter(id, week) {
    this.week_filters[id] = week
    this._updateBlock(id)
  }

  getSearchFilter(id) {
    return this.search_filters && this.search_filters[id]
  }

  setSearchFilter(id, value) {
    this.search_filters = this.search_filters || {}
    this.search_filters[id] = value
    this._updateBlock(id)
  }

  registerBooking(details, type) {
    let existing = this.bookings[details.id]
    if (existing && existing.updated_at > details.updated_at) {
      debug(`Misordered booking update? Ignoring (id: ${existing.id})`)
      return existing
    }

    details.date_of_use = moment(details.date_of_use)
    this.bookings[details.id] = details.except('units')

    if (type == 'update') {
      details.units.each(u => this._updateUnit(u))
    } else {
      details.units.each(u => this.registerUnit(u))
    }

    return this.bookings[details.id]
  }

  getBookingState(id) {
    return this.bookings[id]
  }

  clearBookingAnimation(id) {
    this.bookings[id].action = null
  }

  registerBookingComponent(booking) {
    this.booking_components[booking.props.id] = booking
  }

  deregisterBookingComponent(booking) {
    this.booking_components[booking.props.id] = null
  }

  registerUnit(props) {
    let existing = this.units[props.id]
    if (existing && existing.updated_at > props.updated_at) {
      debug(`Misordered unit update? Ignoring (id: ${props.id})`)
    } else {
      this.units[props.id] = props
    }
    return this.units[props.id]
  }

  bookingDetails(id) {
    return this.bookings[id]
  }

  registerProgressSelector(ps) {
    this.progress_selector_modal = ps
  }

  registerBookingSelector(bs) {
    this.booking_selector_modal = bs
  }

  showProgressSelect(booking_id) {
    this.progress_selector_modal.show({
      booking_id: booking_id,
      progress:   this.bookings[booking_id].progress
    })
  }

  changeBookingProgress(booking_id, progress) {
    new AjaxRequest({
      method: "PATCH",
      url: url_for("update_progress_trailer_planners_booking_path", booking_id),
      params: { progress: progress }
    })
  }

  getTrailerSearch() {
    return this.trailer_search
  }

  setTrailerSearch(query) {
    this.planner.search = query
    this.planner.instance.redraw()
  }

  showBookingSelect(options) {
    this.booking_selector = options
    this.booking_selector_modal.show()
  }

  getBookingSelectorState() {
    let bs = this.booking_selector
    if (!bs) { return {} }
    return {
      type:       bs.type,
      unit_id:    bs.id,
      booking_id: this.getColiveryBooking(bs.id, bs.type).id,
      name:       this.units[bs.id].name,
      options:    this._activeBookingsDateSorted()
    }
  }

  bookingProblems(id) {
    return new BookingCheck({
      booking: this.bookings[id],
      units:   this._unitsForBlock(id)
    }).problems
  }

  excelExport() {
    window.location = url_for("excel_trailer_planners_path", {
      bookings:   this.plannerBookings().map(b => b.id).join(","),
      unassigned: this.blockUnits('active', true).map(u => u.id).join(","),
      pending:    this.blockUnits('pending', true).map(u => u.id).join(",")
    })
  }

  registerExpandAll(instance) {
    this.expand_all_instance = instance
  }

  getExpandAllState() {
    return { expanded: this.expand_all }
  }

  // This is potentially expensive where there are a lot of bookings, hence
  // we avoid redundant redraws.
  // If every is already expanded, collapse everything instead
  expandAll() {
    this.expand_all = !this.expand_all
    let new_expanded = {}
    let bookings = this.plannerBookings()
    let expand = bookings.any(b => this.expanded[b.id] != this.expand_all)
    this.plannerBookings().each(b => {
      new_expanded[b.id] = this.expand_all
      if (!!this.expanded[b.id] != this.expand_all) { this.toggleBlockExpanded(b.id) }
    })
    this.expanded = new_expanded // collapse non visible for faster week cycle
    this.expand_all_instance.redraw()
  }

  fetchDimensionsLines(job_id) {
    new AjaxRequest({
      method: "GET",
      url: url_for("dimensions_lines_trailer_planners_job_path", job_id)
    })
  }

  plannerBookings() {
    if (this.planner.multi_window) { return [] }
    return new PlannerCollection(this.bookings.values()).where({
      start_date:  this.planner.start_date,
      end_date:    this.planner.end_date,
      origin:      this.planner.origin,
      destination: this.planner.destination,
      progress:    this.planner.progress,
      search:      this.planner.search,
      units:       this.units
    })
  }

  blockUnits(block_id, unassigned) {
    let units = this._unitsForBlock(block_id)
    if (unassigned) {
      units = new PlannerCollection(units).where({
        origin: this.planner.origin,
        destination: this.planner.destination
      })
    }

    units = this._searchUnits(units, this.getSearchFilter(block_id))
    units = this._weekUnits(units, this.getWeekFilter(block_id))

    // skip sorting if no one can see
    if (this.isBlockExpanded(block_id)) {
      units = this._sortUnits(units, this.getSortMethod(block_id))
    }

    return units
  }

  registerGroupageFinancials(component) {
    this.groupage_financials_components[component.props.booking_id] = component
  }

  deregisterGroupageFinancials(component) {
    this.groupage_financials_components[component.props.booking_id] = null
  }

  getGroupageFinancials(id) {
    return this.groupage_financials[id] || {
      groupage_id: this.bookings[id] && this.bookings[id].groupage_id
    }
  }

  websocketUpdate(data) {
    debug(`websocket update from ${data.origin}`)
    if (data.assignments) {
      this._moveLocalUnits(data.assignments, "websocket-update")
    }
    if (data.booking) {
      this._websocketBooking(data.booking)
    }
    if (data.bookings) {
      data.bookings.each(b => this._websocketBooking(b))
    }
    if (data.units) {
      this._websocketUnit(data.units)
    }
    if (data.groupage_financials) {
      this._websocketGroupageFinancials(data.groupage_financials)
    }
    if (data.event_id) {
      this.event_tracker.receive(data.event_id)
    }
  }

  _websocketGroupageFinancials(data) {
    this.groupage_financials[data.booking_id] = data
    this._redrawGroupageFinancials(data.booking_id)
  }

  _redrawGroupageFinancials(booking_id) {
    let component = this.groupage_financials_components[booking_id]
    component && component.redraw()
  }

  _websocketUnit(units) {
    units.each(u => u.destroy ? this._destroyUnit(u.id) : this._updateUnit(u))
  }

  _destroyUnit(id) {
    let block_id = this.units[id] && (this.units[id].block_id || this.units[id].status)
    delete this.units[id]
    this.job_unit_count_cache = null
    block_id && this._updateBlock(block_id)
  }

  _compareUnits(a, b) {
    if (a === b) { return true }
    let [aa, bb] = [a,b].map(x => x.except('selected', 'update_status'))
    return aa.equals(bb)
  }

  _updateUnit(unit) {
    let old = this.units[unit.id]
    let next = this.registerUnit(unit)

    let action
    if (!old) {
      action = 'create'
      this.job_unit_count_cache = null
    }
    else if (!this._compareUnits(old, next)) {
      action = 'update'
    }
    else {
      debug('nothing changed')
      return // Nothing actually changed
    }
    debug(`${action} unit!`)
    this.units[unit.id].update_status = action

    if (old && old.block_id != next.block_id) { this._updateBlock(old.block_id || old.status) }
    this._updateBlock(next.block_id || next.status)
  }

  // fucking moments..
  // If you can't catch a == b to block redraws, its gonna be slow AF
  _compareBookings(a, b) {
    if (a === b) { return true }
    let [aa, bb] = [a.except('date_of_use', 'action'), b.except('date_of_use', 'action')]
    return aa.equals(bb) && a.date_of_use.equals(b.date_of_use)
  }

  _websocketBooking(booking) {
    if (booking.destroy) { this._destroyBooking(booking.id) }
    else { this._updateBooking(booking) }
  }

  _destroyBooking(id) {
    delete this.bookings[id]
    this.planner.instance.redraw()
  }

  _updateBooking(booking) {
    let old = this.bookings[booking.id]
    let next = this.registerBooking(booking, 'update')
    if (!old) {
      debug('create booking!')
      this.bookings[booking.id].action = 'create'
      this._redrawGroupageFinancials(booking.id)
      this.planner.instance.redraw()
    }
    else if (!this._compareBookings(old, next)) {
      debug('update booking!')
      this.bookings[booking.id].action = 'update'
      let component = this.booking_components[booking.id]
      component && component.redraw()
    }
    else {
      debug('nothing changed')
      return // Nothing actually changed
    }
  }

  // args: { unit_id: '123', target_id: '456' }
  transfer(args) {
    if (args.target_id == this.units[args.unit_id]) { return } // No transfer
    var unit_ids = args.selected_ids.split(",").select(id => id)
    unit_ids.push(args.unit_id)

    var assignments = {}
    unit_ids.uniq().each(id => assignments[id] = { block_id: args.target_id })

    var transfers = this._moveLocalUnits(assignments, "pending")

    this._ajaxAssignUnits(transfers)
  }

  // Don't count on transfer (not) running as well, or after
  multiWindowDrop(unit_id, selected_ids) {
    // If the selected_ids from event match ours, its same window
    // so we can leave #transfer to handle cosmetics
    if (!selected_ids.equals(this.selectedUnits())) { return }
    var blocks = []
    selected_ids.each(id => {
      this.units[id].selected = false
      this.units[id].update_status = 'offwindow-pending'
      blocks.push(this.units[id].block_id)
    })
    blocks.uniq().each(b => this._updateBlock(b))
  }

  ajaxFetchBookingsComplete(e) {
    this.planner.pending_requests -= 1
    e.responseJSON.each(b => this.registerBooking(b))
    this.planner.instance.redraw()
  }

  ajaxAssignmentComplete(e) {
    var blocks = []
    e.responseJSON.each(r => {
      blocks.push(this.units[r.id].block_id) // attempted
      blocks.push(r.block_id) // outcome
      this.units[r.id].block_id = r.block_id
      this.units[r.id].update_status = r.success ? 'success' : 'failure'
      if (r.errors) { window.assignmentErrors(r.id, r.errors) }
    })
    blocks.uniq().each(b => this._updateBlock(b))
  }

  getSelected(unit_id) {
    return this.units[unit_id].selected
  }

  // status only applies to next render, not forever
  getUpdateStatus(unit_id) {
    let status = this.units[unit_id].update_status || ''
    this.units[unit_id].update_status = ''
    return status
  }

  toggleSelected(unit_id, multi) {
    if (!multi) {
      this.units.values().select(u => u.selected && u.id != unit_id).each(u => {
        u.selected = false
        this._updateBlock(u.block_id)
      })
    }
    let next = !this.units[unit_id].selected
    this.units[unit_id].selected = next
    return next
  }

  selectedUnits() {
    let selected = []
    this.units.each((k,v) => v.selected ? selected.push(k) : '')
    return selected
  }

  getColiveryState(unit_id, type) {
    let u = this.units[unit_id]
    return {
      status:           u[`${type}_status`],
      warehouse:        u[`${type}_at_warehouse`],
      same_trailer:     u.block_id == u[`${type}_booking`],
      company_hub:      u[`${type}_company_hub`]
    }
  }

  changeColivery(unit_id, type, booking_id) {
    if (this.getColiveryBooking(unit_id, type).id == booking_id) { return } // no change
    this._setUnitUpdateStatus(unit_id, 'pending')
    new AjaxRequest({
      method: "POST",
      url: url_for("movement_planning_unit_path", unit_id),
      params: {
        movement_type: type,
        trailer_booking_id: booking_id
      }
    })
  }

// private

  _fetchWeekIfMissing(start, end) {
    if (this.planner.bookings_periods.excludes(start, end)) {
      this._fetchBookings(start, end)
    }
  }

  // load the previous and next weeks to a given range, if missing
  // This is useful for selecting COT/DOTs in different weeks and makes
  // week cycling feel faster
  _preemptLoadWeeks(start, end) {
    setTimeout(() => {
      this._fetchWeekIfMissing(start.clone().add(1, 'week'), end.clone().add(1, 'week'))
      this._fetchWeekIfMissing(start.clone().subtract(1, 'week'), end.clone().subtract(1, 'week'))
    }, 500)
  }

  // We use localStorage to store the department id from other browser tab.
  // This is a string that potentially persists forever, so be sure to validate!
  // department needs to be a number for fucking react-select, I guess cos the
  // bastard is using === somewhere...
  _setLocalFilters() {
    let foreign_id = localStorage.getItem('tp-department')
    if (this.planner.departments.keys().find(id => foreign_id)) {
      this.planner.department_id = Number(foreign_id)
    }

    let valid = this.planner.countries.map(c => c.country_code)

    const keys = ['origin', 'destination']
    keys.each(od => this.planner[od] =
      String(localStorage.getItem(`tp-${od}s`)).split(",")
      .select(c => valid.includes(c)).join(",")
    )

    let progress = localStorage.getItem('tp-progress')
    if (progress) {
      this.planner.progress = progress.split(",")
        .select(p => VALID_PROGRESSES.includes(p)).join(",")
    }
  }

  _fetchBookings(start, end) {
    start = start.clone().startOf('week')
    end = end.clone().endOf('week')

    this.planner.pending_requests += 1
    new AjaxRequest({
      method: "GET",
      url:    "trailer_planners/bookings",
      params: { start: start.format("YYYY/MM/DD"), end: end.format("YYYY/MM/DD") },
      complete: this.ajaxFetchBookingsComplete.bind(this)
    })

    this.planner.bookings_periods.add(start, end)
  }

  // Should cache this
  _unitsForBlock(id) {
    return this.units.values().select(u => u.block_id == id)
  }

  _countJobUnits() {
    let count = {}
    this.units.values().each(
      u => count[u.job_id] ? count[u.job_id] += 1 : count[u.job_id] = 1
    )
    return count
  }

  _setUnitUpdateStatus(id, status) {
    this.units[id].update_status = 'pending'
    this._updateBlock(this.units[id].block_id)
  }

  _activeBookingsDateSorted() {
    return this.bookings.values().reject(b => b.groupage_status == "complete").sort((a, b) =>
      ((b.date_of_use && b.date_of_use.format()) || "").localeCompare(
        (a.date_of_use && a.date_of_use.format()) || "")
    )
  }

  _columnOffset(column) {
    let offset = 0
    for (var c of this.visibleColumns()) {
      if (c == column) { break }
      offset += this.column_widths[c]
    }
    return offset
  }

  // 1.5% for icon, 1.7% for controls, so 96.8% for columns... :/
  _initColumnWidths() {
    let total = 0
    this.visibleColumns().each(c => total += (COLUMN_WIDTHS[c] || 1))
    this.column_widths = {}
    this.column_offsets = {}
    this.visibleColumns().each(
      c => this.column_widths[c] = (COLUMN_WIDTHS[c] || 1) * 96.8 / total
    )
  }

  _weekUnits(units, week) {
    let date
    if (week == "This Week") {
      date = moment()
    }
    else if (week == "Next Week") {
      date = moment().add(1, 'week')
    }
    else {
      return units
    }
    return units.select(u => u.ready_date && date.isSame(u.ready_date, 'week'))
  }

  _searchUnits(units, term) {
    if (!term) { return units }

    term = term.downcase()
    return units.select(u => SEARCHABLE.any(s => u[s] && u[s].downcase().startsWith(term)))
  }

  _sortUnits(units, sort_method) {
    // https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    // need something more complex to handle numbers and moments
    // TODO: Spec
    units = units.sort((a, b) => {
      if (sort_method.reverse) { [a, b] = [b, a] }
      let value = this._sortValue(a, b, sort_method.column)
      if (value == 0 && sort_method.previous) { // settle ties
        value = this._sortValue(a, b, sort_method.previous)
      }
      return value
    })
    return units
  }

  // Returns positive, zero or negative number for sorting
  _sortValue(a, b, column) {
    let type = COLUMNS_WITH_SORT_TYPE[column]
    let [fa, fb] = [a, b].map(unit => unit[column])

    if (type == 'none') { return 0 }
    if (type == 'number') { return parseFloat(fa || 0) - parseFloat(fb || 0) }
    if (type == 'date') { [fa, fb] = [fa, fb].map(x => x && x.format()) }
    return (fa || '').localeCompare(fb || '')
  }

  _countriesChange(countries) {
    this.planner.instance.redraw()
    this._updateBlock("active")
    this._updateBlock("pending")
    this.planner.country_filter.redraw()
  }

  _moveLocalUnits(assignments, update_status) {
    var transfers = []
    var sources = []
    var targets = []

    assignments.each((id, changes) => {
      var source = this.units[id].block_id
      var target = changes.block_id || this.units[id].status // status for unassigned
      sources.push(source)
      targets.push(target)
      this.units[id].selected = false

      if (source != target) { // Ignore non-changes
        transfers.push(`${id}:${source}->${target}`) // see TrailerPlanners::Assignment
        this._warnCompleted(id, source, target)
        this.units[id].block_id = target
        this.units[id].update_status = update_status
      }
    })

    sources.uniq().each(s => this._updateBlock(s))
    targets.uniq().each(t => this._updateBlock(t))

    return transfers
  }

  _warnCompleted(unit_id, source, target) {
    if (this.bookings[source] && this.bookings[source].progress == 'complete') {
      new Growl({
        title:     `Previous Trailer Booking Complete`,
        message:   `Unit ${this.units[unit_id].name} was reassigned from a completed booking`,
      })
    }
    if (this.bookings[target] && this.bookings[target].progress == 'complete') {
      new Growl({
        title:     `New Trailer Booking Complete`,
        message:   `Unit ${this.units[unit_id].name} has been assigned to a completed booking`,
      })
    }
  }

  _updateBlock(block_id) {
    let block = this.blocks[block_id]
    block && block.redraw()
  }

  _ajaxAssignUnits(transfers) {
    if (transfers.length == 0) { return }
    new AjaxRequest({
      method:   "PATCH",
      url:      `/trailer_planners/assign_units`,
      params:   { assignments: transfers.join(',') },
      complete: this.ajaxAssignmentComplete.bind(this)
    })
  }

}

