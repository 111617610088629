import React from 'react'

import EmissionsBreakdown from './EmissionsBreakdown'
import JourneyDetails from './JourneyDetails'
import EmissionsHelper from '../reactive/helpers/EmissionsHelper'
// I18N:
import enums from '../reactive/i18n/enums'

// Describe the kind of vehicle used for a particular journey.
function transportVehicleText (journey) {
  const s = enums.en.emissions.vehicleCategories[journey.vehicleType]
  return (s || `${journey.mode} freight`)?.replace(/^\w/, s => s.toUpperCase())
}

// Describe the underlying model (data source) -- and fuel type, if possible.
function transportVehicleInfo (journey) {
  if (!journey.id) { return 'Implicit journey (not stored in database)' }

  const model = journey.modelName?.replace('::Job', ' ')?.replace('rB', 'r B')
  const fuelType = journey.fuelType?.replace(/_/g, ' ')
  const currentLoad = journey.totalWeight && `${journey.totalWeight}kg present`
  const weightLimit = journey.weightLimit && `${journey.weightLimit}kg maximum`

  return [model, fuelType, currentLoad, weightLimit].filter(s => s).join(' / ')
}

function firstColumnFor (journey) {
  const classAndMode = `job-emissions-vehicle ${journey.mode}`

  return (
    <span className={classAndMode} title={transportVehicleInfo(journey)}>
      {transportVehicleText(journey)}
    </span>
  )
}

// Used to display details for a single job.
function JobBreakdown (props) {
  const items = props.data.map((journey, idx) => (
    <JourneyDetails
      key={idx}
      journey={journey}
      firstColumn={firstColumnFor(journey)}
      useKm={false}
    >
      {journey.refrigerated && (
        <i className='fa fa-snowflake-o text-primary' title='Refrigerated' />
      )}
    </JourneyDetails>
  ))

  const wtt = props.data.reduce((total, journey) => {
    const f = EmissionsHelper.calculateTonneKilometres(journey, journey.weight)
    return total + (EmissionsHelper.calculateWellToTank(journey, f) || 0)
  }, 0)

  const ttw = props.data.reduce((total, journey) => {
    const f = EmissionsHelper.calculateTonneKilometres(journey, journey.weight)
    return total + (EmissionsHelper.calculateTankToWheel(journey, f) || 0)
  }, 0)

  return (
    <EmissionsBreakdown label='Transport Type' wtt={wtt} ttw={ttw}>
      {items}
    </EmissionsBreakdown>
  )
}

export default JobBreakdown
