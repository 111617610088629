import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import DropDown from '../../common/Forms/DropDown'
import ErrorList from '../../common/ErrorList'
import FieldList from '../../common/Forms/FieldList'
import FormField from '../../common/Forms/FormField'
import FormFooter from '../../common/Forms/FormFooter'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import CustomsRouteApi from '../../apis/customs/CustomsRouteApi'

// This is the "edit" page for a specific customs route.
class CustomsRouteForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.match.params.id || '',
      loading: true,
      errors: []
    }
  }

  componentDidMount () {
    if (!this.state.id) { return this.initCreate() }

    CustomsRouteApi.show(this.state.id).then(
      this.initUpdate,
      this.handleErrors
    )
  }

  initCreate = () => {
    document.title = 'New Customs Route'

    this.setState({
      id: null,
      gvmsCode: '',
      direction: '',
      carrierId: '',
      departurePortId: '',
      arrivalPortId: '',
      loading: false,
      errors: []
    })
  }

  initUpdate = (json) => {
    document.title = `Editing ${json.name}`

    this.setState({
      id: json.id,
      gvmsCode: json.gvmsCode,
      direction: json.direction,
      carrierId: json.carrier.id,
      departurePortId: json.departurePort.id,
      arrivalPortId: json.arrivalPort.id,
      loading: false,
      errors: []
    })
  }

  handleGvmsCodeChange = (event) => {
    this.setState({ gvmsCode: event.target.value })
  }

  handleDirectionChange = (event) => {
    this.setState({ direction: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const id = this.state.id

    const params = {
      route: {
        gvmsCode: this.state.gvmsCode,
        direction: this.state.direction,
        carrierId: this.state.carrierId,
        departurePortId: this.state.departurePortId,
        arrivalPortId: this.state.arrivalPortId
      }
    }

    if (this.state.id) {
      CustomsRouteApi.update(id, params).then(this.handleSuccess, this.handleErrors)
    } else {
      CustomsRouteApi.create(params).then(this.handleSuccess, this.handleErrors)
    }
  }

  handleSuccess = (json) => {
    this.props.history.push('/reactive/customs/routes')
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/routes': 'Customs Routes',
      '/reactive/customs/routes/new': 'Edit'
    }

    const cancelUrl = '/reactive/customs/routes'

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>{document.title}</h3>
        <ErrorList errors={this.state.errors} />
        <TabGroup labels={['General']}>
          <TabContent>
            <FormSection title='General'>
              <FieldList>
                <FormField label='Code'>
                  <input className='form-control' value={this.state.gvmsCode} onChange={this.handleGvmsCodeChange} />
                </FormField>
                <FormField label='Direction'>
                  <DropDown
                    source={CustomsRouteApi.directions}
                    value={this.state.direction}
                    onChange={this.handleDirectionChange}
                  />
                </FormField>
              </FieldList>
            </FormSection>
          </TabContent>
        </TabGroup>
        <FormFooter>
          <button className='btn btn-primary' onClick={this.handleSubmit}>
            Save Changes
          </button>
          <Link className='btn btn-labeled' to={cancelUrl}>
            <i className='btn-label fa fa-times' />
            Cancel
          </Link>
        </FormFooter>
      </div>
    )
  }
}

export default CustomsRouteForm
