import React from 'react'

// Notes icon for job parts.
function PlanningUnitNotes (props) {
  if (!props.unit.job_notes && !props.unit.customer_notes) { return null }

  const href = `/trailer_planners/units/${props.unit.id}`
  const title = 'Notes may exist. Click to check.'
  const className = 'trailer-plannning-planning-unit-notes'

  return (
    <a className={className} href={href} data-remote={true} title={title}>
      Notes found
    </a>
  )
}

export default PlanningUnitNotes
