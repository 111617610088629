import React from 'react'

import BreadcrumbBar from '../../common/BreadcrumbBar'
import CustomsChargesTab from './CustomsChargesTab'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'

// Used to display/edit customs pricing information.
class CustomsChargesPage extends React.Component {
  // This is the only reason we're not using `function CustomsChargesPage()`...
  componentDidMount () { document.title = 'Customs Charges' }

  render () {
    const breadcrumbs = { '/reactive/customs/charges': 'Customs Charges' }

    return (
      <React.Fragment>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>Customs Charges</h3>
        <TabGroup labels={['Count-based', 'Value-based']}>
          <TabContent>
            <CustomsChargesTab basedOnValue={false} />
          </TabContent>
          <TabContent>
            <CustomsChargesTab basedOnValue={true} />
          </TabContent>
        </TabGroup>
      </React.Fragment>
    )
  }
}

export default CustomsChargesPage
