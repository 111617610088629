import React from 'react'

import Dropzone from '../common/Dropzone'
import PlanningUnitList from '../Jobs/PlanningUnitList'

import FilteringHelper from '../helpers/FilteringHelper'

// This represents a single "table" within the unassigned section.
class UnassignedTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      week: '',
      searchText: ''
    }
  }

  handleWeekChange = (event) => {
    this.setState({ week: event.target.value })
  }

  handleSearchTextChange = (event) => {
    this.setState({ searchText: event.target.value })
  }

  render () {
    const role = this.props.role
    const bookings = this.props.cotDotBookings

    // NOTE: This runs AFTER the "main" filter (countries, full-loads, etc.)
    const units = FilteringHelper.filterUnits(this.props.units, {
      week: this.state.week,
      searchText: this.state.searchText,
      showFullLoad: true // Don't filter them out if they are provided!
    })

    return (
      <Dropzone bookingId={role}>
        <PlanningUnitList role={role} units={units} cotDotBookings={bookings}>
          <div className='unassigned-table-header'>
            <div className='unassigned-table-header-label'>
              {this.props.label}
            </div>
            <select
              className='unassigned-table-header-week'
              value={this.state.week}
              onChange={this.handleWeekChange}
            >
              <option value=''>Everything</option>
              <option value='this'>Ready This Week</option>
              <option value='next'>Ready Next Week</option>
            </select>
            <input
              className='unassigned-table-header-search'
              placeholder='Search...'
              value={this.state.searchText}
              onChange={this.handleSearchTextChange}
            />
          </div>
        </PlanningUnitList>
      </Dropzone>
    )
  }
}

export default UnassignedTable
