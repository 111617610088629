import sendRequest from './sendRequest'

// Encapsulates all AJAX calls related to departments.
class DepartmentApi {
  static all () {
    return sendRequest('GET', '/api/jobs/departments')
  }

  static show (id) {
    return sendRequest('GET', `/api/jobs/departments/${id}`)
  }
}

export default DepartmentApi
