import BaseApi from '../BaseApi'
import buildGetQuery from '../buildGetQuery'

// This class handles all API interactions related to CO² emissions.
class EmissionsApi {
  static all (params) {
    params = buildGetQuery(params || {})
    return BaseApi.sendRequest('GET', 'api/jobs/emissions.json', params)
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/jobs/emissions/${id}.json`)
  }
}

export default EmissionsApi
