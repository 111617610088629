// This class provides utility functions related to text.
class TextHelper {
  static humanize (text) {
    return text.replace(/_/g, ' ').replace(/^[a-z]/, c => c.toUpperCase())
  }

  static pluralize (text) {
    if (text.toLowerCase().endsWith('f')) { return text.slice(0, -1) + 'ves' }
    if (text.toLowerCase().endsWith('y')) { return text.slice(0, -1) + 'ies' }

    return text + 's'
  }

  static underscore (text) {
    const value = text.replace(/[a-z][A-Z]/g, c => c.split('').join('_'))
    return value.replace('::', '/').toLowerCase() // Foo::Bar_Baz > foo/bar_baz
  }
}

export default TextHelper
