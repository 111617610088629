import React from 'react'

import CustomsSubmissionApi from '../reactive/apis/customs/CustomsSubmissionApi'
import showErrors from './showErrors'

// This is used on the Job screen to submit a shipment to customs.
class SubmitShipmentButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = { reference: props.reference }
  }

  handleSubmit = () => {
    const lines = [
      'Upload customs data?',
      '',
      `• QUANTITY: ${this.props.quantity} (${this.props.packageMode} packages)`,
      `• NET WEIGHT: ${this.props.netSum} / ${this.props.netTotal} kg`,
      `• GROSS WEIGHT: ${this.props.grossSum} / ${this.props.grossTotal} kg`
    ]
    if (!window.confirm(lines.join('\n'))) { return }

    CustomsSubmissionApi.submitShipment(this.props.id).then(
      json => this.setState({ reference: json.trackingNumber }),
      json => showErrors(json.errors || [json.error])
    )
  }

  render () {
    if (this.state.reference) {
      return (
        <button className='btn btn-labeled btn-sm btn-success' disabled={true}>
          <i className='btn-label fa fa-upload' /> {this.state.reference}
        </button>
      )
    }

    return (
      <button className='btn btn-labeled btn-sm btn-warning' onClick={this.handleSubmit}>
        <i className='btn-label fa fa-upload' /> Submit customs
      </button>
    )
  }
}

export default SubmitShipmentButton
