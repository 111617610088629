// Text formatting logic used internally by the PlanningUnitMovement component.
class MovementHelper {
  // Generate "description" text for a potential COT/DOT trailer booking:
  static bookingSummary (booking) {
    const parts = [
      booking.trailer_numbers,
      booking.vehicle_registration,
      booking.supplier,
      booking.groupage,
      booking.date_of_use // TODO: Should be DD/MM/YY -- use `DateHelper` here!
    ]

    return parts.filter(s => s).join(' / ')
  }

  // TODO: This could probably just be generated for us by the server...
  static domesticSummary (movements, isDelivery) {
    movements = movements.filter(m => m.is_delivery === isDelivery)

    if (movements.length < 1) {
      return 'No details'
    } else if (movements.length > 1) {
      return 'Multiple'
    } else if (movements[0].handled_by_client) {
      return 'Direct by client'
    }

    return `DOM / ${movements[0].haulier.short_name}`
  }
}

export default MovementHelper
