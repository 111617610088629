import React, { Component } from 'react'

export default class DropDownMenu extends Component {

  render() {
    let css = `btn btn-sm btn-${this.props.btnType || 'warning'} dropdown-toggle`
    return (
      <div className="dropdown">
        <a className={css} data-toggle="dropdown">
          <span className={`fa fa-${this.props.icon}`}>{this.props.title || ''}</span>
        </a>
        <ul className="dropdown-menu dropdown-menu-right">
          {this._options()}
        </ul>
      </div>
    )
  }

  // private

  _options() {
    return this.props.options.map((o, i) => (
      <li key={i}>
        <a {...o.except('name')}>{o.name}</a>
      </li>
    ))
  }
}
