import React, { Component } from 'react'

import DropDownMenu from '../DropDownMenu'
import Routes, { url_for } from '../Routes'

export default class Controls extends Component {

  render() {
    return (
      <td className="cell controls" onClick={e => e.stopPropagation()}>
        <DropDownMenu icon="caret-down" options={this._options()} btnType="info" />
      </td>
    )
  }

// private

  _options() {
    const id = this.props.id

    return [
      {
        'data-remote' : true,
        name: "Check for problems",
        href: url_for("trailer_planners_unit_path", id)
      },
      {
        'data-remote' : true,
        name: "Edit details",
        href: url_for("edit_planning_unit_path", id),
      },
      {
        'data-remote' : true,
        name: "Split this part",
        href: url_for("edit_planning_unit_path", id, { view: 'split' }),
      },
      {
        'data-remote' : true,
        name:   'Delete this part',
        href:   url_for("planning_unit_path", id),
        'data-method':  'delete',
        'data-confirm': 'Are you sure that you want to delete this part of the job?',
        unless: this.props.is_whole_job
      },
      {
        'data-remote' : true,
        name:   this.props.booking_groupage_id ? 'Assign groupage' : 'Remove groupage',
        href:   url_for("assign_job_trailer_planners_groupages_path", {
                  groupage_id: this.props.booking_groupage_id || '',
                  job_id:      this.props.job_id
        }),
        'data-method':  'patch',
        'data-confirm': 'Are you sure that you want to change the groupage for this job?',
        unless: this.props.job_groupage_id == this.props.booking_groupage_id
      },
      {
        'data-remote' : true,
        name:   "Unpin from planner",
        href:   url_for("planning_unit_path", id, {
          "job[road_details_attributes][id]": this.props.job_road_details_id,
          "job[road_details_attributes][show_on_planner]": "if_filters_match"
        }),
        'data-method':  'patch',
        'data-confirm': "Remove the 'always show' property from this job?",
        unless: this.props.show_on_planner != "always"
      }
    ].reject(o => o.unless).map(o => o.except('unless'))
  }

}

