import React from 'react'

// Part of the top bar.
function NavbarNew (props) {
  return (
    <li className='dropdown'>
      <a href='#' className='dropdown-toggle' data-toggle='dropdown' id='nav-new'>New</a>
      <ul className='dropdown-menu'>
        <li className='dropdown-header'>Quote</li>
        <li><a data-remote='true' href='/quotes/new?mode=Road'>Road</a></li>
        <li className='disabled' style={{ opacity: 0.5 }}><a>Air</a></li>
        <li className='disabled' style={{ opacity: 0.5 }}><a>Sea</a></li>
        <li className='disabled' style={{ opacity: 0.5 }}><a>Rail</a></li>
        <li className='divider' />
        <li className='dropdown-header'>Job</li>
        <li><a data-remote='true' href='/jobs/new?mode=Road'>Road</a></li>
        <li><a data-remote='true' href='/jobs/new?mode=Air'>Air</a></li>
        <li><a data-remote='true' href='/jobs/new?mode=Sea'>Sea</a></li>
        <li><a data-remote='true' href='/jobs/new?mode=Rail'>Rail</a></li>
      </ul>
    </li>
  )
}

export default NavbarNew
