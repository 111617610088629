// Show an array of error messages in a Bootbox modal:
function showErrors (errors, message) {
  const html = document.createElement('div')

  const p = html.appendChild(document.createElement('p'))
  p.innerHTML = message || 'Sorry, something went wrong:'

  const ul = html.appendChild(document.createElement('ul'))
  errors.forEach(error => (
    ul.appendChild(document.createElement('li')).textContent = error
  ))

  bootbox.alert(html.innerHTML)
}

export default showErrors
