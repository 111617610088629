import React from 'react'

// A simple on/off button.
function FilterToggle (props) {
  const variant = props.enabled ? 'on' : 'off'
  const cssName = `trailer-planning-filter-toggle ${variant}`

  return (
    <button className={cssName} onClick={props.onClick}>
      {props.children}
    </button>
  )
}

export default FilterToggle
