
import moment from 'moment'

export default class TimePeriods {
  constructor(start, end) {
    this.periods = []
    this.add(start, end)
  }

  add(start, end) {
    start = moment(start)
    end = moment(end)
    if (!this.includes(start, end)) { 
      this.periods.push([start, end])
    }
  }

  includes(start, end) {
    start = moment(start)
    end = (end && moment(end)) || start
    return this.periods.any(
      p => start.isBetween(...p, 'day', '[]') && end.isBetween(...p, 'day', '[]')
    )
  }

  excludes(start, end) {
    return !this.includes(start, end)
  }

}
