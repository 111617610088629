import React from 'react'

import MovementHelper from '../helpers/MovementHelper'
import PlanningUnitApi from '../api/PlanningUnitApi'

// Represents a collection or delivery for a job part.
class PlanningUnitMovement extends React.Component {
  constructor (props) {
    super(props)

    this.state = { loading: false }
  }

  // Are we currently expecting a "trailer" movement or "domestic" one?
  onTrailer = () => this.props.unit[`${this.props.purpose}_on_trailer`]

  // If we're expecting a "trailer" movement, have we selected a booking yet?
  currentBookingId = () => this.props.unit[`${this.props.purpose}_booking`]

  // Toggle "COT/DOT" vs. "Domestic" mode:
  handleToggle = () => {
    const params = {}
    params[`${this.props.purpose}_on_trailer`] = !this.onTrailer()

    this.setState({ loading: true })
    PlanningUnitApi.update(this.props.unit.id, params).then(
      json => this.setState({ loading: false }),
      json => this.setState({ loading: false })
    )
  }

  // Change -- or remove -- the trailer booking used for COT/DOT:
  handleBookingChange = (event) => {
    const kind = this.props.purpose
    const bookingId = event.target.value

    this.setState({ loading: true })
    PlanningUnitApi.assignMovement(this.props.unit.id, kind, bookingId).then(
      json => this.setState({ loading: false }),
      json => this.setState({ loading: false })
    )
  }

  options () {
    const currentId = this.currentBookingId()

    // By default, no trailer booking will be selected:
    let options = [<option key='0' value=''>None</option>]

    // These are the trailer bookings known to be available for selection:
    options = options.concat(this.props.cotDotBookings.map(booking => (
      <option key={booking.id} value={booking.id}>
        {MovementHelper.bookingSummary(booking)}
      </option>
    )))

    // If we're already assigned to a trailer booking not on that list, add it:
    if (currentId && !this.props.cotDotBookings.find(b => b.id === currentId)) {
      options.push(
        <option key={currentId} value={currentId}>
          Booking ID #{currentId}
        </option>
      )
    }

    return options
  }

  render () {
    const status = this.props.unit[`${this.props.purpose}_status`] || ''
    const lock = this.onTrailer() && status && status !== 'pending'

    const css = 'trailer-planning-planning-unit-movement'
    const btnCss = 'trailer-planning-planning-unit-movement-toggle'
    const valueCss = `trailer-planning-planning-unit-movement-detail ${status}`
    const infoText = status.replace('_', ' ') // "in_progress" -> "in progress"

    const placeholderOption = (
      <option value={this.currentBookingId() || ''} disabled={true}>
        Saving...
      </option>
    )

    const domesticMovementsText = MovementHelper.domesticSummary(
      this.props.unit.movements,
      this.props.purpose === 'delivery'
    )

    return this.onTrailer() ? (
      <div className={`${css} trailer`}>
        <button className={btnCss} disabled={lock} onClick={this.handleToggle}>
          On Trailer
        </button>
        <select
          className={valueCss}
          title={infoText}
          value={this.currentBookingId() || ''}
          disabled={lock}
          required={this.state.loading}
          onChange={this.handleBookingChange}
        >
          {this.state.loading ? placeholderOption : this.options()}
        </select>
      </div>
    ) : (
      <div className={`${css} domestic`}>
        <button className={btnCss} disabled={lock} onClick={this.handleToggle}>
          Not On Trailer
        </button>
        <div className={valueCss} title={infoText}>
          {this.state.loading ? 'Saving...' : domesticMovementsText}
        </div>
      </div>
    )
  }
}

export default PlanningUnitMovement
