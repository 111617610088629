import React from 'react'

// Components:
import NotificationShow from '../reactive/events/notifications/ShowModal'
import NotificationConfirm from '../reactive/events/notifications/ConfirmModal'

// We don't have a single "root" component yet, but this lets us pretend we do:
function withModal (WrappedComponent) {
  return class extends React.Component {
    constructor (props) {
      super(props)

      this.state = { kind: null }
    }

    handleCloseModal = () => {
      this.setState({ kind: null })
    }

    handleViewNotification = (id) => {
      this.setState({ kind: 'notification-view', id: id })
    }

    handleNotificationPrompt = (id) => {
      this.setState({ kind: 'notification-prompt', id: id })
    }

    render () {
      let modal = null

      if (this.state.kind === 'notification-view') {
        modal = <NotificationShow id={this.state.id} onClose={this.handleCloseModal} />
      } else if (this.state.kind === 'notification-prompt') {
        modal = <NotificationConfirm id={this.state.id} onClose={this.handleCloseModal} />
      }

      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            onViewNotification={this.handleViewNotification}
            onNotificationPrompt={this.handleNotificationPrompt}
          />
          {modal}
        </React.Fragment>
      )
    }
  }
}

export default withModal
