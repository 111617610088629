import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import CheckBox from '../../common/Forms/CheckBox'
import DropDown from '../../common/Forms/DropDown'
import ErrorList from '../../common/ErrorList'
import FieldList from '../../common/Forms/FieldList'
import FormField from '../../common/Forms/FormField'
import FormFooter from '../../common/Forms/FormFooter'
import FormHeader from '../../common/Forms/FormHeader'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import IncotermApi from '../../apis/financials/IncotermApi'

// This is the "edit" page for a specific incoterm.
class IncotermForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.match.params.id || '',
      loading: true,
      errors: []
    }
  }

  componentDidMount () {
    if (!this.state.id) { return this.initCreate() }

    IncotermApi.show(this.state.id).then(this.initUpdate, this.handleErrors)
  }

  initCreate = () => {
    document.title = 'New Incoterm'

    this.setState({
      id: null,
      incoterm: '',
      name: '',
      description: '',
      incoterms2010: false,
      incoterms2020: true,
      road: true,
      sea: true,
      air: true,
      rail: true,
      requireExporterCos: false,
      requireFreightValue: false,
      freight: '',
      shipperResponsible: '',
      invoicingRule: '',
      loading: false,
      errors: []
    })
  }

  initUpdate = (json) => {
    document.title = `Editing Incoterm ${json.incoterm}`

    this.setState({
      id: json.id,
      incoterm: json.incoterm || '',
      name: json.name || '',
      description: json.description || '',
      incoterms2010: json.incoterms2010,
      incoterms2020: json.incoterms2020,
      road: json.road,
      sea: json.sea,
      air: json.air,
      rail: json.rail,
      requireExporterCos: json.requireExporterCos,
      requireFreightValue: json.requireFreightValue,
      freight: json.freight || '',
      shipperResponsible: json.shipperResponsible || '',
      invoicingRule: json.invoicingRule || '',
      loading: false,
      errors: []
    })
  }

  handleIncotermChange = (event) => {
    this.setState({ incoterm: event.target.value })
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value })
  }

  handleIncoterms2010Change = (event) => {
    this.setState({ incoterms2010: !this.state.incoterms2010 })
  }

  handleIncoterms2020Change = (event) => {
    this.setState({ incoterms2020: !this.state.incoterms2020 })
  }

  handleRoadChange = (event) => {
    this.setState({ road: !this.state.road })
  }

  handleSeaChange = (event) => {
    this.setState({ sea: !this.state.sea })
  }

  handleAirChange = (event) => {
    this.setState({ air: !this.state.air })
  }

  handleRailChange = (event) => {
    this.setState({ rail: !this.state.rail })
  }

  handleRequireExporterCosChange = (event) => {
    this.setState({ requireExporterCos: !this.state.requireExporterCos })
  }

  handleRequireFreightValueChange = (event) => {
    this.setState({ requireFreightValue: !this.state.requireFreightValue })
  }

  handleFreightChange = (event) => {
    this.setState({ freight: event.target.value })
  }

  handleShipperResponsibleChange = (event) => {
    this.setState({ shipperResponsible: event.target.value })
  }

  handleInvoicingRuleChange = (event) => {
    this.setState({ invoicingRule: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const id = this.state.id
    const params = { incoterm: this.state }

    if (this.state.id) {
      IncotermApi.update(id, params).then(this.handleSuccess, this.handleErrors)
    } else {
      IncotermApi.create(params).then(this.handleSuccess, this.handleErrors)
    }
  }

  handleSuccess = (json) => {
    this.props.history.push(`/reactive/financials/incoterms/${json.id}`)
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/financials/incoterms': 'Incoterms',
      '/reactive/financials/incoterms/new': 'Edit'
    }

    let cancelUrl = '/reactive/financials/incoterms'
    if (this.state.id) { cancelUrl = `/reactive/financials/incoterms/${this.state.id}` }

    const appliesToHeader = (
      <FormHeader title='Applies To'>
        <label>
          Incoterms 2010
          <CheckBox value={this.state.incoterms2010} onChange={this.handleIncoterms2010Change} />
        </label>
        <label>
          Incoterms 2020
          <CheckBox value={this.state.incoterms2020} onChange={this.handleIncoterms2020Change} />
        </label>
      </FormHeader>
    )

    const miscellaneousHeader = (
      <FormHeader title='Miscellaneous'>
        <label>
          Exporter needs CoS for partner jobs?
          <CheckBox value={this.state.requireExporterCos} onChange={this.handleRequireExporterCosChange} />
        </label>
        <label>
          Freight Value required?
          <CheckBox value={this.state.requireFreightValue} onChange={this.handleRequireFreightValueChange} />
        </label>
      </FormHeader>
    )

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>{document.title}</h3>
        <ErrorList errors={this.state.errors} />
        <TabGroup labels={['General']}>
          <TabContent>

            <FormSection title='General'>
              <FieldList>
                <FormField label='Incoterm'>
                  <input className='form-control' value={this.state.incoterm} onChange={this.handleIncotermChange} />
                </FormField>
                <FormField label='Name'>
                  <input className='form-control' value={this.state.name} onChange={this.handleNameChange} />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label='Description' size='wide'>
                  <input className='form-control' value={this.state.description} onChange={this.handleDescriptionChange} />
                </FormField>
              </FieldList>
            </FormSection>

            <FormSection title={appliesToHeader}>
              <FieldList>
                <FormField label='Road' size='narrow'>
                  <CheckBox value={this.state.road} onChange={this.handleRoadChange} />
                </FormField>
                <FormField label='Sea' size='narrow'>
                  <CheckBox value={this.state.sea} onChange={this.handleSeaChange} />
                </FormField>
                <FormField label='Air' size='narrow'>
                  <CheckBox value={this.state.air} onChange={this.handleAirChange} />
                </FormField>
                <FormField label='Rail' size='narrow'>
                  <CheckBox value={this.state.rail} onChange={this.handleRailChange} />
                </FormField>
              </FieldList>
            </FormSection>

            <FormSection title={miscellaneousHeader}>
              <FieldList>
                <FormField label='Freight'>
                  <DropDown
                    source={IncotermApi.freightOptions}
                    value={this.state.freight}
                    onChange={this.handleFreightChange}
                  />
                </FormField>
                <FormField label='Shipper Responsible'>
                  <DropDown
                    source={IncotermApi.shipperOptions}
                    value={this.state.shipperResponsible}
                    onChange={this.handleShipperResponsibleChange}
                  />
                </FormField>
              </FieldList>

              <FieldList>
                <FormField label='Invoicing Rule'>
                  <DropDown
                    source={IncotermApi.invoicingRules}
                    value={this.state.invoicingRule}
                    onChange={this.handleInvoicingRuleChange}
                  />
                </FormField>
              </FieldList>
            </FormSection>

          </TabContent>
        </TabGroup>
        <FormFooter>
          <button className='btn btn-primary' onClick={this.handleSubmit}>
            Save Changes
          </button>
          <Link className='btn btn-labeled' to={cancelUrl}>
            <i className='btn-label fa fa-times' />
            Cancel
          </Link>
        </FormFooter>
      </div>
    )
  }
}

export default IncotermForm
