import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import CustomsCarrierApi from '../../apis/customs/CustomsCarrierApi'

// This is the index page, which lists all customs carriers.
class CustomsCarrierIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'Customs Carriers'

    CustomsCarrierApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    CustomsCarrierApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/carriers': 'Customs Carriers'
    }

    const headers = [
      { label: 'Code' },
      { label: 'Name' },
      { label: 'Entity' },
      { label: 'Country' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(carrier => (
      <tr key={carrier.id}>
        <td>{carrier.gvmsCode}</td>
        <td>{carrier.name}</td>
        <td>{(carrier.entity || {}).name}</td>
        <td>{carrier.country.name}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/customs/carriers/${carrier.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(carrier.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/customs/carriers/new'>
              <i className='btn-label icon fa fa-plus' />
              New Carrier
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <SimpleTable caption='Customs Carriers' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default CustomsCarrierIndex
