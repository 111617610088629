import React from 'react'

import TrailerBookingDropdown from './TrailerBookingDropdown'
import TrailerBookingGroupage from './TrailerBookingGroupage'
import TrailerBookingNotes from './TrailerBookingNotes'
import TrailerBookingNumbers from './TrailerBookingNumbers'
import TrailerBookingProblems from './TrailerBookingProblems'
import TrailerBookingProgress from './TrailerBookingProgress'
import HazardDetails from '../common/HazardDetails'
import PlannerIcon from '../common/PlannerIcon'

import DateHelper from '../helpers/DateHelper'

// This represents the "header" of an individual trailer booking.
function TrailerHeader (props) {
  return (
    <div className='trailer-planning-trailer-header'>
      <div className='trailer-planning-trailer-header-route'>
        {props.booking.origin_country}{props.booking.destination_country}
      </div>
      <div className='trailer-planning-trailer-header-date-of-use'>
        {DateHelper.formatLong(props.booking.date_of_use)}
      </div>
      <div className='trailer-planning-trailer-header-supplier'>
        {props.booking.supplier}
      </div>
      <div className='trailer-planning-trailer-header-trailer-numbers'>
        <TrailerBookingNumbers booking={props.booking} />
      </div>
      <div className='trailer-planning-trailer-header-notes'>
        <TrailerBookingNotes notes={props.booking.notes} />
      </div>
      <div className='trailer-planning-trailer-header-hazards'>
        <HazardDetails hazards={props.booking.dangerous_goods} />
      </div>
      <div className='trailer-planning-trailer-header-status'>
        <TrailerBookingProgress booking={props.booking} />
      </div>
      <div className='trailer-planning-trailer-header-problems'>
        <TrailerBookingProblems booking={props.booking} />
      </div>
      <div className='trailer-planning-trailer-header-origin-hub'>
        {props.booking.origin_hub}
      </div>
      <div className='trailer-planning-trailer-header-destination-hub'>
        {props.booking.destination_hub}
      </div>
      <div className='trailer-planning-trailer-header-flags'>
        <PlannerIcon
          kind='prioritise'
          show={props.booking.prioritise}
          title='This trailer has been flagged as high-priority'
        />
        <PlannerIcon
          kind='unaccompanied'
          show={props.booking.unaccompanied}
          title='Trailers will not travel with vehicle'
        />
        <PlannerIcon
          kind='temperature'
          show={props.booking.temperature_controlled}
          title='This vehicle is temperature-controlled'
        />
        <PlannerIcon
          kind='hazardous'
          show={props.booking.adr}
          title='This vehicle is able to carry hazardous goods'
        />
        <PlannerIcon
          kind='ferry'
          show={props.booking.ferry}
          title='Travelling via ferry'
        />
        <PlannerIcon
          kind='waste'
          show={props.booking.waste}
          title='This vehicle is able to carry waste'
        />
      </div>
      <div className='trailer-planning-trailer-header-groupage'>
        <TrailerBookingGroupage booking={props.booking} />
      </div>
      <div className='trailer-planning-trailer-header-actions'>
        <TrailerBookingDropdown booking={props.booking} />
      </div>
    </div>
  )
}

export default TrailerHeader
