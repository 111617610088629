import BaseApi from './BaseApi'

// This class handles all API interactions related to searching.
class QuickFindApi {
  static options () {
    return BaseApi.sendRequest('GET', 'api/quick_find/options.json')
  }
}

export default QuickFindApi
