import React from 'react'

import DropDown from '../common/Forms/DropDown'
import UserApi from '../apis/UserApi'

// A simple wrapper around the standard drop-down for users specifically.
function UserDropdown (props) {
  return (
    <DropDown
      source={UserApi.all}
      format={user => `${user.firstName} ${user.lastName}`}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

export default UserDropdown
