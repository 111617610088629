import BaseApi from '../BaseApi'

// This class handles all API interactions related to incoterms.
class IncotermApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/financials/incoterms.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/financials/incoterms/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/financials/incoterms.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/financials/incoterms/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/financials/incoterms/${id}.json`)
  }

  static freightOptions () {
    return BaseApi.sendRequest('GET', 'api/financials/incoterms/freight_options.json')
  }

  static shipperOptions () {
    return BaseApi.sendRequest('GET', 'api/financials/incoterms/shipper_options.json')
  }

  static invoicingRules () {
    return BaseApi.sendRequest('GET', 'api/financials/incoterms/invoicing_rules.json')
  }
}

export default IncotermApi
