import React, { Component } from 'react'

import EuroTunnel from '../dg/EuroTunnel'
import Ferry from '../dg/Ferry'
import Info from '../dg/Info'

export default class DangerousGoods extends Component {
  render() {
    return (
      <td className="cell dangerous_goods" style={this.props.style} >
        {this.props.dgli_classes ? <Info {...this.props} /> : ''}
        {this.props.ferry        ? <Ferry info={this.props.ferry} /> : ''}
        {this.props.eurotunnel   ? <EuroTunnel info={this.props.eurotunnel} /> : ''}
      </td>
    )
  }
}

