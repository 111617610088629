import React, { Component } from 'react'
import moment from 'moment'

import StandardIcon from '../StandardIcon'

import DangerousGoods from './DangerousGoods'
import Colivery from './Colivery'

export default class Cell extends Component {
  constructor(props) {
    super(props)
    this.style = { width: this.props.width }
  }

  render() {
    if (this.props.name == 'customs_status') { return this._customs_status() }
    if (this.props.name == 'consignment_flags') { return this._consignment_flags() }
    if (this.props.name == 'dangerous_goods') { return this._dangerous_goods() }
    if (['collection', 'delivery'].includes(this.props.name)) { return this._colivery() }
    return this.props.content.secondary ? this._double() : this._single()
  }

// private

  _single() {
    let attrs = {
      className: `cell single ${this.props.name}`,
      style:     this.style
    }

    return <td {...attrs}>{this.props.content.primary}</td>
  }

  _subCell(c, i) {
    let attrs = { className: "subcell", key: i }
    if (typeof(c) == "string" && c.length > 10) { attrs.title = c }
    return <div {...attrs}>{c}</div>
  }

  _double() {
    let css = `cell double ${this.props.name}`
    return (
      <td className={css} style={this.style} >
        {this._subCell(this.props.content.primary, 0)}
        {this._subCell(this.props.content.secondary, 1)}
      </td>
    )
  }

  // content is a hash
  _customs_status() {
    return (
      <td
        className="cell single customs_status"
        style={this.style}
        title={this.props.content.title || '(No MRN)'}
      >{this.props.content.text}</td>
    )
  }

  // content is a hash
  _consignment_flags() {
    return (
      <td className="cell consignment_flags" style={this.style} >
        {this.props.content.keys().map(
          (c, i) => <Flag title={c} details={this.props.content[c]} key={i} />
        )}
      </td>
    )
  }

  _dangerous_goods() {
    return <DangerousGoods style={this.style} {...this.props.content.primary} />
  }

  _colivery() {
    return <Colivery style={this.style} {...this.props.content} />
  }
}

class Flag extends Component {
  render() {
    const css = `badge ${this.props.title}`
    return (
      <span className={css} title={this._tooltip()}>
        <StandardIcon type={this.props.title} />
      </span>
    )
  }

  _tooltip() {
    return this.props.details == true ? this.props.title.humanize() : this.props.details
  }
}
