import BaseApi from '../BaseApi'

// This class handles all API interactions related to currencies.
class CurrencyApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/financials/currencies.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/financials/currencies/${id}.json`)
  }
}

export default CurrencyApi
