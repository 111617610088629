import sendRequest from './sendRequest'

// Encapsulates all AJAX calls related to groupages.
class GroupageApi {
  static profit (id) {
    return sendRequest('GET', `/trailer_planners/groupages/${id}/financials`)
  }
}

export default GroupageApi
