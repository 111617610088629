import React, { Component } from 'react'

import Head from '../trailer_planners/Head'
import StateManager from '../trailer_planners/StateManager'
import Booking from '../trailer_planners/Booking'
import Unassigned from '../trailer_planners/Unassigned'

export default class Planner extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerPlanner(this)
    props.bookings.each(b => this.manager.registerBooking(b))
    props.unassigned.each(u => this.manager.registerUnit(u))
    
    this.state = this.manager.getPlannerState()
  }

  redraw() {
    this.setState(this.manager.getPlannerState())
  }

  render() {
    return (
      <div>
        <Head
          multi_window={this.props.multi_window}
          countries={this.props.countries}
          permissions={this.props.permissions}
        />
        {this._loadingSpinner()}
        {this.state.bookings.map(b => <Booking id={b.id} key={b.id} />)}
        {this._unassigned()}
      </div>
    )
  }

// private

  _loadingSpinner() {
    if (this.state.pending_requests < 1) { return null }
    return <span className="loading-spinner">
      <i className="fa fa-spinner fa-spin" />
    </span>
  }

  _unassigned() {
    if (this.state.popout) { return null }
    return <div id="unassigned">
      <p />
      <Unassigned title="Unassigned" id="active" />
      <p />
      <Unassigned title="Pending" id="pending" />
    </div>
  }
}
