import React from 'react'

// Basically just a section with a heading.
function InfoBox (props) {
  return (
    <div className='trailer-planning-info-box'>
      <div className='trailer-planning-info-box-header'>{props.header}</div>
      <div className='trailer-planning-info-box-content'>{props.children}</div>
    </div>
  )
}

export default InfoBox
