import React from 'react'

import JobSummary from './JobSummary'
import JobBreakdown from './JobBreakdown'
import CollapsableSection from '../reactive/common/CollapsableSection'

// Displays a list of jobs.
function JobList (props) {
  const items = props.jobs.map(job => (
    <CollapsableSection key={job.id} label={<JobSummary job={job} />}>
      <JobBreakdown data={job.breakdown} />
    </CollapsableSection>
  ))

  return <div className='emissions-job-list'>{items}</div>
}

export default JobList
