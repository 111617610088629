import React, { useState } from 'react'

// Represents a section which can be expanded by clicking on the "title" part.
export default function (props) {
  const [open, setState] = useState(false)
  const handleToggle = () => setState(!open)

  return (
    <div className={`reactive-collapsable-section ${open ? 'expanded' : ''}`}>
      <div className='head' onClick={handleToggle}>{props.label}</div>
      {open && <div className='body'>{props.children}</div>}
    </div>
  )
}
