import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import CreateEmailButton from '../../../emails/envelopes/CreateEmailButton'
import Ribbon from '../../../common/Ribbon'

// This component contains the various buttons at the top of the page.
export default function (props) {
  return (
    <Ribbon>
      <CreateEmailButton
        modelId={props.discrepancy.id}
        templateType='general_discrepancy'
      />
      <div className='pull-right'>
        <Link
          className='btn btn-sm btn-labeled btn-warning'
          to={`/reactive/customs/discrepancies/${props.discrepancy.id}/edit`}
        >
          <i className='btn-label icon fa fa-edit' />
          Edit Discrepancy
        </Link>
        &nbsp;
        <button
          className='btn btn-sm btn-labeled btn-danger'
          onClick={props.onDestroy}
        >
          <i className='btn-label icon fa fa-trash' />
          Delete Discrepancy
        </button>
      </div>
    </Ribbon>
  )
}
