import React from 'react'

function ConnectionIndicator (props) {
  const cssClass = props.connected ? 'connected' : 'not-connected'

  return (
    <div className={`trailer-planning-connection-indicator ${cssClass}`}>
      {props.connected ? 'Connected' : 'Not connected'}
    </div>
  )
}

export default ConnectionIndicator
