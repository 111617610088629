import React from 'react'

// Components:
import DropDown from '../../common/Forms/DropDown'
// APIs:
import QuickFindApi from '../../apis/QuickFindApi'

// Part of the top bar.
class NavbarSearch extends React.Component {
  constructor (props) {
    super(props)

    const kind = localStorage.getItem('quickfind') || 'job'
    this.state = { kind: kind, text: '' }
  }

  handleTextChange = (event) => {
    this.setState({ text: event.target.value })
  }

  handleKindChange = (event) => {
    const newKind = event.target.value
    this.setState({ kind: newKind })
    localStorage.setItem('quickfind', newKind)
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const params = { search_type: this.state.kind, search: this.state.text }
    $.ajax('/quick_find/search.js', { data: params }) // use the old controller
  }

  render () {
    return (
      <li>
        <form className="navbar-form pull-left input-group" onSubmit={this.handleSubmit}>
          <DropDown
            source={QuickFindApi.options}
            value={this.state.kind}
            onChange={this.handleKindChange}
          />

          <input
            placeholder="Quick find..."
            className="form-control enter-submit"
            onChange={this.handleTextChange}
          />

          <button style={{ display: 'none' }}/>
        </form>
      </li>
    )
  }
}

export default NavbarSearch
