import React, { Component } from 'react'

import Popover from '../Popover'

export default class Info extends Component {
  render() {
    return (
      <Popover title="Dangerous Goods Info" content={this._details()}>
        <span className="label label-danger">
          <i className="fa fa-flask" />&nbsp;{this.props.dgli_classes}
        </span>
      </Popover>
    )
  }

  _details() {
    let titles = this.props.dgli_titles.split('$$')
    let summaries = this.props.dgli_summaries.split('$$')

    return titles.map((title, i) =>
      `<li class="list-group-item">
        <b>${title}</b>
        <br />
        ${summaries[i]}
      </li>`
    ).join('<br />')
  }
}
