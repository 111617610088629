import React from 'react'

import Navbar from './Header/Navbar'
import FilterToolbar from './Header/FilterToolbar'
import GeneralActions from './Header/GeneralActions'
import TrailerSection from './Trailers/TrailerSection'
import UnassignedSection from './Unassigned/UnassignedSection'

import TrailerBookingApi from './api/TrailerBookingApi'
import PlanningUnitApi from './api/PlanningUnitApi'
import RailsSocket from './api/RailsSocket'

import DateHelper from './helpers/DateHelper'
import FilteringHelper from './helpers/FilteringHelper'

// This is the root planner component - it stores the general state of things.
class TrailerPlanner extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: DateHelper.startOfWeek(new Date()),
      rangeType: 'week',
      originCountry: '',
      destinationCountry: '',
      showPending: true,
      showArrived: true,
      showInProgress: true,
      showComplete: false,
      showFullLoad: false,
      searchText: ''
    }
  }

  componentDidMount () {
    this.reconnect()
    this.fetchBookings()
    this.fetchUnassigned()
  }

  reconnect () {
    console.log('Connecting to websocket server...')
    this.setState({ connected: false })

    const ws = window.location.protocol.replace('http', 'ws') // https: -> wss:
    this.websocket = new RailsSocket(this.handleWebsocketEvent)
    this.websocket.connect(`${ws}//${window.location.host}/cable`)
  }

  handleWebsocketEvent = (json) => {
    switch (json.type) {
      case 'ping': return
      case 'welcome': return this.websocket.subscribe('TrailerPlannerChannel')
      case 'confirm_subscription': return this.setState({ connected: true })
      case 'disconnect': return this.reconnect() // The server has restarted...
      default: break // See below...
    }

    /* eslint brace-style: "off" */
    if (json.type) { return console.log('Unhandled ActionCable Event:', json) }
    else if (json.message.booking) { this.onBookingEvent(json.message.booking) }
    else if (json.message.units) { this.onUnitsEvent(json.message.units) }
    else if (json.message.assignments) { /* Ignore this for now... */ }
    else { return console.log('Unknown websocket message:', json) }
  }

  // Called when a trailer booking is created, updated, or destroyed.
  onBookingEvent (booking) {
    console.debug('TRAILER EVENT:', booking)
    this.fetchBookings() // TODO: Avoid this request; update the local state.
    this.fetchUnassigned() // TODO: Avoid this request; update the local state.
  }

  // Called when a trailer booking is created, updated, or destroyed.
  onUnitsEvent (units) {
    console.debug('UNITS EVENT:', units)
    this.fetchBookings() // TODO: Avoid this request; update the local state.
    this.fetchUnassigned() // TODO: Avoid this request; update the local state.
  }

  fetchBookings = () => {
    const days = this.state.rangeType === 'week' ? 6 : 0
    const endDate = DateHelper.addDays(this.state.startDate, days)

    TrailerBookingApi.all(this.state.startDate, endDate).then(
      json => this.setState({ bookings: json }),
      json => console.log('FAILURE:', json)
    )
  }

  fetchUnassigned = () => {
    PlanningUnitApi.unassigned().then(
      json => this.setState({ unassigned: json }),
      json => console.log('FAILURE:', json)
    )
  }

  handlePriorDate = () => {
    const offset = this.state.rangeType === 'week' ? 7 : 1
    const date = DateHelper.addDays(this.state.startDate, -offset)
    this.setState({ startDate: date }, this.fetchBookings)
  }

  handleNextDate = () => {
    const offset = this.state.rangeType === 'week' ? 7 : 1
    const date = DateHelper.addDays(this.state.startDate, offset)
    this.setState({ startDate: date }, this.fetchBookings)
  }

  handleRangeTypeToggle = () => {
    const newRange = this.state.rangeType === 'week' ? 'day' : 'week'
    const date = DateHelper.startOfWeek(this.state.startDate)
    this.setState({ startDate: date, rangeType: newRange }, this.fetchBookings)
  }

  handleOriginCountryChange = (event) => {
    this.setState({ originCountry: event.target.value })
  }

  handleDestinationCountryChange = (event) => {
    this.setState({ destinationCountry: event.target.value })
  }

  handlePendingToggle = () => {
    this.setState({ showPending: !this.state.showPending })
  }

  handleArrivedToggle = () => {
    this.setState({ showArrived: !this.state.showArrived })
  }

  handleInProgressToggle = () => {
    this.setState({ showInProgress: !this.state.showInProgress })
  }

  handleCompleteToggle = () => {
    this.setState({ showComplete: !this.state.showComplete })
  }

  handleFullLoadToggle = () => {
    this.setState({ showFullLoad: !this.state.showFullLoad })
  }

  handleSearchTextChange = (event) => {
    this.setState({ searchText: event.target.value })
  }

  handleMultiWindowToggle = () => {
    const url = [window.location.pathname, 'mode=unassigned'].join('?')
    const features = 'resizable,scrollbars,width=1910'

    if (this.state.subwindow) {
      this.state.subwindow.close()
      this.setState({ subwindow: null })
    } else {
      const subwindow = window.open(url, 'unassigned', features)
      this.setState({ subwindow: subwindow })
    }
  }

  // See .handleMultiWindowToggle() -- this detects if we ARE the subwindow!
  isSubWindow = () => (
    (new URLSearchParams(window.location.search)).get('mode') === 'unassigned'
  )

  render () {
    // To avoid lots of `{ x: this.state.x }` for bookings, just use all of it:
    const bookings = FilteringHelper.filterBookings(this.state.bookings, this.state)

    // We only use a few bits of the state to filter units, so do it properly:
    const units = FilteringHelper.filterUnits(this.state.unassigned, {
      originCountry: this.state.originCountry,
      destinationCountry: this.state.destinationCountry,
      showFullLoad: this.state.showFullLoad
    })

    return (
      <div className='trailer-planning-trailer-planner'>
        <Navbar logo={this.props.logo} connected={this.state.connected} />
        <FilterToolbar
          {...this.state}
          unassignedOnly={this.isSubWindow()}
          onPriorDate={this.handlePriorDate}
          onNextDate={this.handleNextDate}
          onRangeTypeToggle={this.handleRangeTypeToggle}
          onOriginCountryChange={this.handleOriginCountryChange}
          onDestinationCountryChange={this.handleDestinationCountryChange}
          onPendingToggle={this.handlePendingToggle}
          onArrivedToggle={this.handleArrivedToggle}
          onInProgressToggle={this.handleInProgressToggle}
          onCompleteToggle={this.handleCompleteToggle}
          onFullLoadToggle={this.handleFullLoadToggle}
          onSearchTextChange={this.handleSearchTextChange}
        />
        {this.isSubWindow() ? null : (
          <GeneralActions
            units={units}
            bookings={bookings}
            multiWindow={!!this.state.subwindow}
            onMultiWindowToggle={this.handleMultiWindowToggle}
          />
        )}
        <div className='trailer-planning-trailer-planner-contents'>
          {this.isSubWindow() ? null : (
            <TrailerSection bookings={bookings} cotDotBookings={this.state.bookings} />
          )}
          {this.state.subwindow ? null : (
            <UnassignedSection units={units} cotDotBookings={this.state.bookings} />
          )}
        </div>
      </div>
    )
  }
}

export default TrailerPlanner
