import React from 'react'

// Show information about an individual trailer within a booking.
function TrailerInformation (props) {
  return (
    <div className='trailer-planning-trailer-information'>
      <div className='trailer-planning-trailer-information-number'>
        {props.trailer.number}
      </div>
      <div className='trailer-planning-trailer-information-length'>
        {props.trailer.length_m}
      </div>
      <div className='trailer-planning-trailer-information-width'>
        {props.trailer.width_m}
      </div>
      <div className='trailer-planning-trailer-information-height'>
        {props.trailer.height_m}
      </div>
    </div>
  )
}

export default TrailerInformation
