import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import ClaimTypeApi from '../../apis/claims/ClaimTypeApi'

// This is the index page, which lists all claim types.
class ClaimTypeIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'Claim Types'

    ClaimTypeApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    ClaimTypeApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/claims/types': 'Claim Types'
    }

    const headers = [
      { label: 'Name' },
      { label: 'Actions' }
    ]

    const items = this.state.json.map(claimType => (
      <tr key={claimType.id}>
        <td>{claimType.name}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/claims/types/${claimType.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(claimType.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/claims/types/new'>
              <i className='btn-label icon fa fa-plus' />
              New Claim Type
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <SimpleTable caption='Claim Types' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default ClaimTypeIndex
