import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BooleanIcon from '../../common/Forms/BooleanIcon'
import BreadcrumbBar from '../../common/BreadcrumbBar'
import FieldList from '../../common/Forms/FieldList'
import FormHeader from '../../common/Forms/FormHeader'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import ShowField from '../../common/Forms/ShowField'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import IncotermApi from '../../apis/financials/IncotermApi'

// This is the "show" page for a specific incoterm.
class IncotermShow extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    IncotermApi.show(this.props.match.params.id).then(
      json => {
        document.title = `Incoterm ${json.incoterm}`
        this.setState({ json: json })
      },
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/financials/incoterms': 'Incoterms',
      [`/reactive/financials/incoterms/${this.state.json.id}`]: this.state.json.incoterm
    }

    const appliesToHeader = (
      <FormHeader title='Applies To'>
        <label>
          Incoterms 2010
          <BooleanIcon value={this.state.json.incoterms2010} />
        </label>
        <label>
          Incoterms 2020
          <BooleanIcon value={this.state.json.incoterms2020} />
        </label>
      </FormHeader>
    )

    const miscellaneousHeader = (
      <FormHeader title='Miscellaneous'>
        <label>
          Exporter needs CoS for partner jobs?
          <BooleanIcon value={this.state.json.requireExporterCos} />
        </label>
        <label>
          Freight Value required?
          <BooleanIcon value={this.state.json.requireFreightValue} />
        </label>
      </FormHeader>
    )

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <div className='page-profile'>
          <Ribbon>
            <div className='pull-right'>
              <Link className='btn btn-sm btn-labeled btn-warning' to={`/reactive/financials/incoterms/${this.state.json.id}/edit`}>
                <i className='btn-label icon fa fa-edit' />
                Edit Incoterm
              </Link>
            </div>
          </Ribbon>
          <h3>{this.state.json.incoterm} | {this.state.json.name}</h3>
          <TabGroup labels={['General']}>
            <TabContent>
              {this.state.json.description && (
                <div className='alert alert-info'>{this.state.json.description}</div>
              )}
              <FormSection title={appliesToHeader}>
                <FieldList>
                  <ShowField label='Road' value={this.state.json.road} size='narrow' />
                  <ShowField label='Sea' value={this.state.json.sea} size='narrow' />
                  <ShowField label='Air' value={this.state.json.air} size='narrow' />
                  <ShowField label='Rail' value={this.state.json.rail} size='narrow' />
                </FieldList>
              </FormSection>
              <FormSection title={miscellaneousHeader}>
                <FieldList>
                  <ShowField label='Freight' value={this.state.json.freight} />
                  <ShowField label='Shipper Responsible' value={this.state.json.shipperResponsible} />
                  <ShowField label='Invoicing Rule' value={this.state.json.invoicingRule} />
                </FieldList>
              </FormSection>
            </TabContent>
          </TabGroup>
        </div>
      </div>
    )
  }
}

export default IncotermShow
