import React from 'react'

import StandardIcon from './StandardIcon'

function IconBadge (props) {
  return (
    <span className={`badge ${props.name}`} title={props.title}>
      <StandardIcon type={props.name} />
      {props.text}
    </span>
  )
}

export default IconBadge
