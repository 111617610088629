import React, { Component } from 'react'

import Popover from '../Popover'

export const DIMENSIONS_LINES_PROPS = [
  'name',
  'dimensions_lines'
]

const MISSING = `<i class="text-primary">???</i>`

export default class DimensionsLines extends Component {

  render() {
    return <Popover title={this.props.name} content={this._content()}>
      {this.props.children}
    </Popover>
  }

  // Popover content neds to be raw string
  _content() {
    return `<ul class='list-group'>
      ${this.props.dimensions_lines.length == 0 ? this._blank() : this._lines()}
    </ul>`
  }

  _blank() {
    return `<li class='planner-popover'>
      No specific package dimensions have has been provided yet.
    </li>`
  }

  _lines() {
    return this.props.dimensions_lines.map(t => (
      `<li class='planner-popover'>
         <b>${t.quantity || MISSING} ${t.package_type || MISSING}</b> ${
           t.length_cm || MISSING} x ${t.width_cm || MISSING} x ${t.height_cm || MISSING} cm
         </li>`
    )).join('')
  }

}
