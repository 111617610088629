import React from 'react'

// Represents a single field on "edit" views.
function FormField (props) {
  let css = null

  switch (props.size) {
    case 'narrow':
      css = {
        outer: 'col-xs-12 col-sm-6 col-lg-3',
        label: 'control-label col-xs-8 col-sm-8',
        value: 'col-xs-4 col-sm-4'
      }
      break

    case 'wide':
      css = {
        outer: 'col-sm-12 col-lg-12',
        label: 'control-label col-sm-4 col-lg-2',
        value: 'col-sm-8 col-lg-10'
      }
      break

    default:
      css = {
        outer: 'col-sm-12 col-lg-6',
        label: 'control-label col-sm-4',
        value: 'col-sm-8'
      }
      break
  }

  return (
    <div className={css.outer}>
      <div className='form-group'>
        <label className={css.label}>{props.label}</label>
        <div className={css.value}>{props.children}</div>
      </div>
    </div>
  )
}

export default FormField
