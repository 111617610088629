import React from 'react'

import DropDown from './DropDown'
import CurrencyApi from '../../apis/financials/CurrencyApi'

// A combined currency + value entry field. TODO: Force 2dp somehow.
function CurrencyAndValueField (props) {
  return (
    <div className='input-group reactive-currency-and-value-field'>
      <span className='input-group-addon'>
        <DropDown
          source={CurrencyApi.all}
          format={currency => `${currency.currencyCode} - ${currency.name}`}
          value={props.currencyId}
          onChange={props.onCurrencyIdChange}
          tabIndex='-1'
        />
      </span>
      <input
        className='form-control'
        type='number'
        step='0.01'
        min='0'
        value={props.value || ''}
        onChange={props.onValueChange}
      />
    </div>
  )
}

export default CurrencyAndValueField
