import React from 'react'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import NotificationTabs from './NotificationTabs'

// This is the index page, which lists all notifications for the current user.
class NotificationIndex extends React.Component {
  componentDidMount () {
    document.title = 'Notifications'
  }

  render () {
    const breadcrumbs = {
      '/reactive/events/notifications': 'Notifications'
    }

    return (
      <React.Fragment>
        <BreadcrumbBar route={breadcrumbs} />
        <NotificationTabs />
      </React.Fragment>
    )
  }
}

export default NotificationIndex
