import React from 'react'

// Components:
import NavbarHeader from './top/NavbarHeader'
import NavbarLinks from './top/NavbarLinks'
import NavbarMisc from './top/NavbarMisc'
import NavbarNew from './top/NavbarNew'
import NotificationIndicatorTile from '../events/notifications/NotificationIndicatorTile'
import NavbarSearch from './top/NavbarSearch'
import NavbarToggleLeft from './top/NavbarToggleLeft'
import NavbarToggleRight from './top/NavbarToggleRight'
import NavbarUser from './top/NavbarUser'

// Part of the top bar.
function Navbar (props) {
  return (
    <div id="main-navbar" className="navbar navbar-inverse">
      <NavbarToggleLeft />

      <div className="navbar-inner">
        <NavbarHeader logoImage={props.freightsoftLogo} />

        <div id="main-navbar-collapse" className="collapse navbar-collapse main-navbar-collapse">
          <div>

            <ul className="nav navbar-nav">
              <NavbarLinks user={props.user} />
              <NavbarNew />
            </ul>

            <div className="right clearfix">
              <ul className="nav navbar-nav pull-right right-navbar-nav">
                <NavbarMisc logoImage={props.hazculatorLogo} />
                <NotificationIndicatorTile
                  onViewNotification={props.onViewNotification}
                  onNotificationPrompt={props.onNotificationPrompt}
                />
                <NavbarSearch />
                <NavbarUser user={props.user} avatar={props.avatar} />
                <NavbarToggleRight />
              </ul>
            </div>

          </div>
        </div>

      </div>

    </div>
  )
}

export default Navbar
