import React from 'react'

// A basic section component.
function FormSection (props) {
  return (
    <div className='panel'>
      <div className='panel-heading'>
        <span className='panel-title'>{props.title}</span>
      </div>
      <div className='panel-body'>
        {props.children}
      </div>
    </div>
  )
}

export default FormSection
