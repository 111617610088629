import React from 'react'

// Indicates split/pinned/etc. status
function PlanningUnitStatus (props) {
  const split = props.unit.split_job
  const pinned = props.unit.show_on_planner === 'always'
  const groupageMismatch = props.unit.groupage_id !== (props.groupageId || null)

  const features = [
    split ? 'Split Job' : null,
    pinned ? 'Pinned' : null,
    groupageMismatch ? 'Groupage Mismatch' : null
  ].filter(s => s)

  // Add a CSS class for each "feature" so that we can highlight it somehow:
  const extras = features.map(s => s.replace(' ', '-')).join(' ').toLowerCase()
  const className = `trailer-planning-planning-unit-status ${extras}`

  return (
    <div className={className} title={features.join(' / ') || null}>
      {features.length ? 'INFO' : null}
    </div>
  )
}

export default PlanningUnitStatus
