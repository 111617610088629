import React from 'react'

import ModalContent from './ModalContent'
// Common components:
import Modal from '../../../../common/Modal'
import PleaseWait from '../../../../common/PleaseWait'
// APIs:
import CustomsDiscrepancyApi from '../../../../apis/customs/CustomsDiscrepancyApi'

// This is the Create/Edit Communication modal on the Show Discrepancy page.
export default class extends React.Component {
  constructor (props) {
    super(props)

    this.state = { loading: true, errors: [] }
  }

  componentDidMount () {
    const communication = this.props.communication || {}
    const today = (new Date()).toISOString().split('T')[0] // "2022-09-26"

    this.setState({
      id: communication.id,
      kind: communication.kind || '',
      date: communication.date || today,
      details: communication.details || '',
      loading: false,
      errors: []
    })
  }

  handleKindChange = e => this.setState({ kind: e.target.value })
  handleDateChange = e => this.setState({ date: e.target.value })
  handleDetailsChange = e => this.setState({ details: e.target.value })

  handleSubmit = () => {
    const params = {
      nctsDiscrepancy: { communicationsAttributes: [this.state] }
    }

    this.setState({ loading: true })
    CustomsDiscrepancyApi.update(this.props.nctsDiscrepancyId, params).then(
      this.props.onClose, this.handleErrors
    )
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    const title = this.props.communication ? (
      'Edit Communication'
    ) : (
      'New Communication'
    )

    const footer = (
      <React.Fragment>
        <button className='btn btn-primary' onClick={this.handleSubmit}>
          Save
        </button>
        <button className='btn' onClick={() => this.props.onClose()}>
          Cancel
        </button>
      </React.Fragment>
    )

    return (
      <Modal title={title} onClose={() => this.props.onClose()} footer={footer}>
        {this.state.loading ? <PleaseWait /> : (
          <ModalContent
            {...this.state}
            onKindChange={this.handleKindChange}
            onDateChange={this.handleDateChange}
            onDetailsChange={this.handleDetailsChange}
          />
        )}
      </Modal>
    )
  }
}
