// This class provides utility functions related to event rules.
class RuleHelper {
  static triggerCondition (rule) {
    const a = ['a', 'e', 'i', 'o', 'u'].includes(rule.model[0]) ? 'an' : 'a'
    const prefix = `When ${a} ${rule.model.replace(/_/g, ' ')}`

    const field = String(rule.field).replace(/_/g, ' ')
    const value = rule.value || 'not blank'

    if (rule.action === 'on_create') {
      if (!rule.field) { return `${prefix} is created` }
      return `${prefix} is created (and "${field}" is ${value})`
    } else if (rule.action === 'on_destroy') {
      if (!rule.field) { return `${prefix} is deleted` }
      return `${prefix} is deleted (and "${field}" is ${value})`
    } else if (rule.updateKey) {
      const key = rule.updateKey.replace(/_/g, ' ')
      return `${prefix} changes "${key}" (and "${field}" is ${value})`
    } else {
      if (!rule.field) { return `${prefix} is updated` }
      if (!rule.value) { return `${prefix} updates "${field}"` }
      return `${prefix} updates "${field}" to "${value}"`
    }
  }

  static notifyParty (text) {
    return text ? text.replace(/_/g, ' ') : '---'
  }
}

export default RuleHelper
