// Connect to an ActionCable websocket server.
class RailsSocket {
  constructor (onMessage) {
    this.connection = null
    this.onMessage = onMessage
  }

  // Connect to an ActionCable server:
  connect (server) {
    if (this.connection) { return this.connection }

    const protocols = ['actioncable-v1-json', 'actioncable-unsupported']
    this.connection = new WebSocket(server, protocols)

    this.connection.onmessage = (event) => {
      if (this.onMessage) { this.onMessage(JSON.parse(event.data)) }
    }
  }

  // Subscribe to an ActionCable channel:
  subscribe (channel) {
    if (!this.connection) { return }

    const identifier = JSON.stringify({ channel: channel })
    const message = { command: 'subscribe', identifier: identifier }
    this.connection.send(JSON.stringify(message))
  }

  // Send a message to an ActionCable channel:
  send (channel, action, params) {
    if (!this.connection) return

    params = params || {}
    params.action = action

    const message = {
      command: 'message',
      identifier: JSON.stringify({ channel: channel }),
      data: JSON.stringify(params)
    }

    this.connection.send(JSON.stringify(message))
  }
}

export default RailsSocket
