import React from 'react'

// Part of the top bar.
function NavbarHeader (props) {
  return (
    <div className='navbar-header'>
      <a href='/' className='navbar-brand' dangerouslySetInnerHTML={{ __html: props.logoImage }} />
      <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-navbar-collapse">
        <i className="navbar-icon fa fa-bars"></i>
      </button>
    </div>
  )
}

export default NavbarHeader
