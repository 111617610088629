import AjaxRequest from '../AjaxRequest'
import Routes, { url_for } from '../Routes'

import StateManager, { debug } from '../StateManager'

export default class EventTracker {

  constructor(current) {
    this.current = current
    this.manager = new StateManager()
    this.missed = []
    this.requested = []
  }

  receive(id) {
    debug(`ET: received event #${id}, expected #${this.current + 1}`)
    if (id == this.current + 1) {
      // Pass, all good
    } else if (id > this.current) { // We missed event(s)
      let i = this.current + 1
      while (i < id) { this.missed.push(i++) }
      this._queueRequest()
    } else {
      this.missed = this.missed.reject(x => x == id)
    }
    this.current = id
  }

  missedRequests(e) {
    let data = e.responseJSON
    if (!data || !data.event_ids) { return this._serverDown(e) }

    this.requested = this.requested.reject(x => data.event_ids.includes(x))
    this.missed = this.missed.reject(x => data.event_ids.includes(x))
    this.manager.websocketUpdate(data)
  }

  // If the server is down, try again after a delay
  // We randomly delay 30-90 seconds to prevent all users reconnecting at same
  // time, which could DDOS the server just after it wakes up
  _serverDown(e) {
    console.log(`ET: Ajax request for missed events failed! status: ${e.status}`)
    let ids = this.requested.slice(0)
    ids.each(i => this.missed.push(i))
    let delay = 30000 + (Math.random() * 60000)

    setTimeout(() => { this._requestMissing(ids) }, delay)
  }

  // If you missed an excessive number of events, probably because your
  // computer was hibernating for a few days, force a full reload.
  // Otherwise we can exceed GET max length and this causes issues.
  _forceReload() {
    $.notify(
      "Planner has been disconnected too long to resync. Reloading page."
    )
    window.location.reload(true)
  }

  _requestMissing(ids) {
    debug(`ET: requestMissing(${ids.join(",")}): Current missed: ${this.missed.join(",")}`)
    ids = this.missed.select(x => ids.includes(x))
    if (ids.empty()) { return }
    if (ids.length > 500) { return this._forceReload() }

    new AjaxRequest({
      method:   "GET",
      url:      url_for("missed_events_trailer_planners_path"),
      params:   { ids: ids.join(',') },
      complete: this.missedRequests.bind(this)
    })

    ids.each(i => this.requested.push(i))
    this.missed = this.missed.reject(i => ids.includes(i))
  }

  _queueRequest() {
    let ids = this.missed.slice(0)
    setTimeout(() => { this._requestMissing(ids) }, 5000)
  }

}

