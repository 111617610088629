import React from 'react'

// Emulates a standard Bootstrap v3 dropdown.
function DropdownButton (props) {
  return (
    <div className='dropdown'>
      <button className={`btn ${props.size} btn-${props.theme} dropdown-toggle`} data-toggle='dropdown'>
        {props.label}
        {props.label && <React.Fragment>&nbsp;&nbsp;</React.Fragment>}
        <i className='fa fa-caret-down'></i>
      </button>
      <ul className='dropdown-menu dropdown-menu-right'>
        {props.children}
      </ul>
    </div>
  )
}

export default DropdownButton
