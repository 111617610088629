import React, { Component } from 'react'

import Routes, { url_for } from '../Routes'
import AjaxRequest from '../AjaxRequest'

const ICON_COLORS = {
  major: 'danger',
  minor: 'warning'
}

const ICONS = {
  major: 'warning',
  minor: 'warning'
}

const WORD = {
  major: "Major problems",
  minor: "Minor problems"
}

// This should really be a react component, but need to improve state management
export default class Problems {
  constructor(props) {
    this.props = props
  }

  render() {
    let problems = this.props.problems
    if (WORD.keys().excludes(problems)) { return null }

    return <span
      className={`badge badge-${ICON_COLORS[problems]}`}
      onClick={this.fetchProblems.bind(this)}
      title={this._title()}
    >
      <i className={`fa fa-${ICONS[problems]}`} />
    </span>
  }

  _title() {
    return `${WORD[this.props.problems]} may exist. Click to check.`
  }

  fetchProblems(e) {
    new AjaxRequest({
      method: "GET",
      url: url_for("trailer_planners_unit_path", this.props.id)
    })
    e.stopPropagation() // don't select this row
  }
}

