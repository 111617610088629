import React from 'react'

// This class provides utility functions related to icons.
class IconHelper {
  static boolean (value) {
    if (value) { return <i className='fa fa-check text-success' /> }
    return <i className='fa fa-times text-muted' />
  }
}

export default IconHelper
