import BaseApi from '../BaseApi'

// This class handles all API interactions related to customs carriers.
class CustomsCarrierApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/customs/carriers.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/customs/carriers/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/customs/carriers.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/customs/carriers/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/customs/carriers/${id}.json`)
  }
}

export default CustomsCarrierApi
