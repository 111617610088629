import React, { Component } from 'react'

import StateManager from './StateManager'

export default class BlockExpander extends Component {
  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.state = { expanded: this.manager.isBlockExpanded(this.props.id) }
    return this
  }

  toggleExpanded() {
    this.setState({ expanded: this.manager.toggleBlockExpanded(this.props.id) })
  }

  render() {
    let chevron = `fa fa-chevron-${this.state.expanded ? 'up' : 'down'}`
    return(
      <div className="block-expander" onClick={this.toggleExpanded.bind(this)}>
        <i className={chevron} />
      </div>
    )
  }
}

