import React from 'react'

// Components:
import PleaseWait from './PleaseWait'

// A generic modal in the same style as the old .erb one.
function Modal (props) {
  const helpUrl = 'https://freightsoft.zendesk.com/hc'

  const closeIcon = props.onClose ? (
    <button type='button' className='close' onClick={props.onClose}>
      <i className='fa fa-times-circle text-danger' />
    </button>
  ) : null

  const footer = props.footer ? (
    <div className='modal-footer'>{props.footer}</div>
  ) : null

  return (
    <div className='reactive-modal-wrapper'>
      <div className={props.wide ? 'modal-dialog modal-lg' : 'modal-dialog'}>
        <div className='modal-content'>
          <div className='modal-header'>
            {closeIcon}
            <a className='modal-help-link' target='_blank' tabIndex='-1' href={helpUrl}>
              Help
            </a>
            <h4 className='modal-title'>{props.title || 'Loading...'}</h4>
          </div>
          <div className='modal-body-and-footer'>
            <div className='modal-body'>
              {props.title ? props.children : <PleaseWait />}
            </div>
            {footer}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
