// This file maps "internal" enum values to user-friendly text.
export default {
  en: {
    consolidations: {
      groupages: {
        statuses: {
          active: 'Active',
          in_progress: 'In Progress',
          arrived: 'Arrived',
          complete: 'Complete'
        }
      }
    },
    customs: {
      discrepancies: {
        communications: {
          kinds: {
            email: 'Email',
            phone: 'Telephone'
          }
        },
        kinds: {
          undischarged: 'Undischarged',
          shortage: 'Shortage',
          addition: 'Addition'
        },
        recoveredFroms: {
          customer: 'Customer',
          hmrc: 'HMRC'
        }
      }
    },
    emails: {
      recipients: {
        recipientTypes: {
          email_to: 'To',
          email_cc: 'CC',
          email_bcc: 'BCC'
        }
      }
    },
    emissions: {
      vehicleCategories: {
        average_van: 'Van (Average)',
        small_van: 'Van (Class I)',
        medium_van: 'Van (Class II)',
        large_van: 'Van (Class III)',
        average_rigid: 'Rigid (Average)',
        small_rigid: 'Rigid (up to 7.5t)',
        medium_rigid: 'Rigid (up to 17t)',
        large_rigid: 'Rigid (over 17t)',
        average_artic: 'Artic (Average)',
        small_artic: 'Artic (up to 33t)',
        // medium_artic: 'Does not exist!',
        large_artic: 'Artic (over 33t)',
        generic_hgv: 'Generic HGV'
      }
    },
    financials: {
      incoterms: {
        shipperOptions: {
          until_origin_port: 'Until origin port',
          until_destination_port: 'Until destination port',
          for_whole_journey: 'For whole journey'
        }
      }
    },
    movements: {
      trailerTypes: {
        trailersAttacheds: {
          no: 'No Trailers',
          single: 'Single Trailer',
          multiple: 'Multiple Trailers'
        },
        vehicleCategories: {
          average_van: 'Van (Average)',
          small_van: 'Van (Class I)',
          medium_van: 'Van (Class II)',
          large_van: 'Van (Class III)',
          average_rigid: 'Rigid (Average)',
          small_rigid: 'Rigid (up to 7.5t)',
          medium_rigid: 'Rigid (up to 17t)',
          large_rigid: 'Rigid (over 17t)',
          average_artic: 'Artic (Average)',
          small_artic: 'Artic (up to 33t)',
          // medium_artic: 'Does not exist!',
          large_artic: 'Artic (over 33t)',
          generic_hgv: 'Generic HGV'
        }
      }
    }
  }
}
