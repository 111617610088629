import React from 'react'

import EmissionsHelper from '../reactive/helpers/EmissionsHelper'
import TextHelper from '../reactive/helpers/TextHelper'
// I18N:
import enums from '../reactive/i18n/enums'

function formatVehicleType (vehicle) {
  const category = enums.en.emissions.vehicleCategories[vehicle.vehicleType]
  const suffix = vehicle.refrigerated && '(Refrigerated)'
  return [category || 'Unknown', suffix].filter(s => s).join(' ')
}

// Used to display details for a vehicle.
function VehicleSummary (props) {
  const vehicle = props.breakdown[0]

  const distance = props.breakdown.map(
    r => r.actualDistance || r.estimatedDistance
  ).filter(s => s).sum()

  const problems = props.breakdown.map(
    r => (r.actualDistance || r.estimatedDistance) ? null : 'Unknown Distance'
  ).filter(s => s)

  // Calculate the equivalent distance in miles, in case the user wants that:
  const miles = EmissionsHelper.kilometresToMiles(distance)
  const title = miles ? `${EmissionsHelper.formatDistance(miles)} miles` : null

  return (
    <div className='emissions-vehicle-summary'>
      <div className='emissions-vehicle-summary-type'>
        {formatVehicleType(vehicle)}
      </div>
      <div className='emissions-vehicle-summary-fuel'>
        {TextHelper.humanize(vehicle.fuelType || 'unknown')}
      </div>
      <div className='emissions-vehicle-summary-capacity'>
        {vehicle.weightLimit ? `${vehicle.weightLimit}kg` : 'Not specified'}
      </div>
      <div className='emissions-vehicle-summary-distance' title={title}>
        {problems.length ? (
          <i
            className='fa fa-exclamation-triangle text-warning'
            title='One or more parts of the journey could not be calculated.'
          />
        ) : null}
        {EmissionsHelper.formatDistance(distance)}km
      </div>
    </div>
  )
}

export default VehicleSummary
