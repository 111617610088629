import React from 'react'

// Show the groupage -- or a buttons to create one.
function TrailerBookingGroupage (props) {
  const textClass = 'trailer-planning-trailer-booking-groupage-text'
  const linkClass = 'trailer-planning-trailer-booking-groupage-button'
  const owner = props.booking.groupage_controller

  return props.booking.groupage ? (
    <div className='trailer-planning-trailer-booking-groupage'>
      <div className={textClass} title={`Controlled by ${owner}`}>
        {props.booking.groupage}
      </div>
      <a
        className={linkClass}
        href={`/groupages/${props.booking.groupage_id}`}
        target='_blank'
      >
        View
      </a>
    </div>
  ) : (
    <div className='trailer-planning-trailer-booking-groupage'>
      <div className={textClass}>
        No Groupage
      </div>
      <a
        className={linkClass}
        href={`/trailer_planners/bookings/${props.booking.id}/create_groupage`}
        data-remote={true}
        data-method='POST'
        data-confirm='Create a new Groupage for this trailer?'
      >
        Create
      </a>
    </div>
  )
}

export default TrailerBookingGroupage
