import React from 'react'

// A `<select>` component which can handle standard AJAX calls OR plain arrays.
class DropDown extends React.Component {
  constructor (props) {
    super(props)

    this.state = { options: null }
  }

  /* eslint object-curly-newline: "off" */
  componentDidMount () {
    // We want to store our options in `[[id, text], [id, text], ...]` format:
    const setData = json => this.setState({ options: Array.isArray(json) ? (
      // Plain array  of strings, possibly from an "enum options" API call:
      json.map(txt => [txt, this.props.format ? this.props.format(txt) : txt])
    ) : (
      // Standard API call; a `format` function MUST be provided in this case:
      json.items.map(item => [item.id, this.props.format(item)])
    ) })

    // If the source is literally just an array, we can work with it directly:
    if (Array.isArray(this.props.source)) { return setData(this.props.source) }
    // Otherwise, make an API call -- this might return an array, or an object:
    this.props.source().then(setData, this.props.onError)
  }

  render () {
    // Do not show anything if we're waiting for an API call to complete:
    if (!this.state.options) {
      return <div className='reactive-drop-down form-control'></div>
    }

    // We use the index (rather than id) as a key, since IDs may not be unique:
    const options = this.state.options.map((idAndText, idx) => (
      <option key={idx} value={idAndText[0]}>{idAndText[1]}</option>
    ))

    // TODO: Perhaps make the "blank" option something that can be excluded?
    return (
      <select
        className='reactive-drop-down form-control'
        value={this.props.value || ''}
        onChange={this.props.onChange}
        disabled={this.props.disabled || !this.props.onChange}
        tabIndex={this.props.tabIndex}
        autoFocus={this.props.autoFocus || false}
      >
        <option></option>
        {options}
      </select>
    )
  }
}

export default DropDown
