import React, { Component } from 'react'

import FormTable from './FormTable'

const tableFormat = ({ postcodes = [], rates = [], weights = [] } = {}) => (
  [
    ["Max Weight", ...postcodes],
    ...rates.map((r, i) => [weights[i], ...r])
  ]
)

const apiFormat = (cells) => {
  const [postcodes, ...rest] = cells
  postcodes.shift() // remove origin
  const weights = rest.map(row => row[0])
  const rates = rest.map(row => row.slice(1))

  return { weights, postcodes, rates }
}

export default class Rates extends Component {

  submit = (cells) => {
    $.ajax({
      url: `/tariffs/suppliers/${this.props.id}`,
      method: 'PUT',
      data: {
        utf8: "✓",
        tariffs_supplier: {
          data: apiFormat(cells)
        },
        commit: "Update Supplier",
        id: this.props.id
      }
    })
  }

  render = () => (
    <div className="tariffs-supplier-rates">
      <FormTable
        headRowPlaceholder="Postcodes..."
        originType="readOnly"
        headColType="number"
        cellType="number"
        cells={tableFormat(this.props)}
        submit={this.submit}
      />
    </div>
  )

}
