import React from 'react'

// Components:
import NotificationTableRow from './NotificationTableRow'
import PleaseWait from '../../common/PleaseWait'
import SimpleTable from '../../common/SimpleTable'
import withNotifications from './withNotifications'

// Helpers:
import TextHelper from '../../helpers/TextHelper'

// An individual table within a notifications tab set.
function NotificationTable (props) {
  if (!props.items) { return <PleaseWait /> }

  const headers = [
    { label: 'Date' },
    { label: 'Severity' },
    { label: 'Notified Parties' },
    { label: 'Parent' },
    { label: 'Title' },
    { label: 'Message' },
    { label: 'Actions' }
  ]

  const rows = props.items.map(item => (
    <NotificationTableRow key={item.id} item={item} {...props} />
  ))

  const category = props.status ? TextHelper.humanize(props.status) : ''

  return (
    <SimpleTable caption={`${category} Notifications`} headers={headers}>
      {rows}
    </SimpleTable>
  )
}

export default withNotifications(NotificationTable)
