import React from 'react'

import JobList from './JobList'
import PleaseWait from '../reactive/common/PleaseWait'
import DateHelper from '../reactive/helpers/DateHelper'
import EmissionsApi from '../reactive/apis/jobs/EmissionsApi'

// FMS-9115: Display carbon emissions information for a set of jobs.
class ForEntity extends React.Component {
  constructor (props) {
    super(props)

    const today = new Date()
    this.state = {
      lastHalf: today.getDate() >= 15,
      month: today.getMonth(), // NOTE: 0-11
      year: today.getFullYear()
    }
  }

  componentDidMount () { this.fetchData() }

  handleBack = () => {
    let newState = {}
    if (this.state.lastHalf) {
      newState = { lastHalf: false }
    } else if (this.state.month > 0) {
      newState = { lastHalf: true, month: this.state.month - 1 }
    } else {
      newState = { lastHalf: true, month: 11, year: this.state.year - 1 }
    }
    this.setState(newState, this.fetchData)
  }

  handleNext = () => {
    let newState = {}
    if (!this.state.lastHalf) {
      newState = { lastHalf: true }
    } else if (this.state.month < 11) {
      newState = { lastHalf: false, month: this.state.month + 1 }
    } else {
      newState = { lastHalf: false, month: 0, year: this.state.year + 1 }
    }
    this.setState(newState, this.fetchData)
  }

  // We always start from either the 1st or 15th day of THIS month/year:
  calculateStartDate = () => new Date(
    this.state.year, this.state.month, this.state.lastHalf ? 15 : 1
  )

  // The end date will be the 15th of THIS month or the 1st of NEXT month:
  calculateEndDate = () => new Date(
    this.state.year + (this.state.month === 11 && this.state.lastHalf ? 1 : 0),
    (this.state.month + (this.state.lastHalf ? 1 : 0)) % 12,
    this.state.lastHalf ? 1 : 15
  )

  fetchData () {
    const params = {
      startDate: DateHelper.formatDateISO(this.calculateStartDate()),
      endDate: DateHelper.formatDateISO(this.calculateEndDate()),
      entityId: this.props.entityId
    }

    this.setState({ jobs: null })
    EmissionsApi.all(params).then(
      json => this.setState({ jobs: json.items }),
      json => console.log(json.errors || [json.error])
    )
  }

  render () {
    if (!this.state.jobs) { return <PleaseWait /> }

    return (
      <div className='emissions-for-entity'>
        <div className='emissions-for-entity-filters'>
          <button className='prev-page' onClick={this.handleBack}>Back</button>
          <span className={this.state.lastHalf ? 'date mid' : 'date start'}>
            {DateHelper.formatDate(this.calculateStartDate())}
          </span>
          <button className='next-page' onClick={this.handleNext}>Next</button>
        </div>
        <div className='emissions-for-entity-results'>
          <JobList jobs={this.state.jobs} />
        </div>
      </div>
    )
  }
}

export default ForEntity
