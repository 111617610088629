import React from 'react'

// A list of reasons why what you just did was not a good idea.
function ErrorList (props) {
  if (props.errors.length < 1) { return null }

  const reasons = props.errors.map(text => <li key={text}>{text}</li>)

  return (
    <div className='alert alert-danger form-errors'>
      <b>{reasons.length} error(s) prohibited this item from being saved:</b>
      <ul>{reasons}</ul>
    </div>
  )
}

export default ErrorList
