import BaseApi from '../BaseApi'

// This class handles all API interactions related to CRM Sales Journal Types.
class SalesJournalEntryTypeApi {
  static all () {
    return BaseApi.sendRequest('GET', 'api/crm/sales_journal_entry_types.json')
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/crm/sales_journal_entry_types/${id}.json`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/crm/sales_journal_entry_types.json', params)
  }

  static update (id, params) {
    return BaseApi.sendRequest('PUT', `api/crm/sales_journal_entry_types/${id}.json`, params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/crm/sales_journal_entry_types/${id}.json`)
  }
}

export default SalesJournalEntryTypeApi
