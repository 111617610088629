import React from 'react'

import PlanningUnitApi from '../api/PlanningUnitApi'

// This handles the "drop" part of the drag-and-drop assignment logic.
class Dropzone extends React.Component {
  constructor (props) {
    super(props)

    this.state = { dropDepth: 0 }
  }

  handleDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ dropDepth: 0 })

    // Ensure that the dropped item actually has JSON data:
    const text = event.dataTransfer.getData('application/json')
    if (!text) { return } // The user has dropped something unexpected here...
    const json = JSON.parse(text)

    // The API currently requires us to specify the old parent as well:
    const units = json.units.map(unit => ({
      id: unit.id,
      oldParentId: unit.trailerBookingId,
      newParentId: this.props.bookingId
    }))

    // Don't do anything if we're dropping something back where it started:
    if (units.filter(u => u.oldParentId === u.newParentId).length) { return }

    // Re-assign the specified planning units:
    PlanningUnitApi.assign(units).then(
      null, // We don't need to do anything yet; we'll get a websockets update.
      json => console.log('Assignment Failed:', json)
    )
  }

  handleDragEnter = (event) => {
    event.preventDefault() // Allow dropping here.
    event.stopPropagation()
    this.setState({ dropDepth: this.state.dropDepth + 1 })
  }

  handleDragLeave = (event) => {
    event.stopPropagation()
    this.setState({ dropDepth: this.state.dropDepth - 1 })
  }

  render () {
    const extraCssClass = this.state.dropDepth ? 'awaiting-drop' : ''

    return (
      <div
        className={`trailer-planning-dropzone ${extraCssClass}`}
        onDrop={this.handleDrop}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Dropzone
