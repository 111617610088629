import React from 'react'

// Common Components:
import DropDown from '../../../../common/Forms/DropDown'
import ErrorList from '../../../../common/ErrorList'
import FieldList from '../../../../common/Forms/FieldList'
import FormField from '../../../../common/Forms/FormField'
// APIs:
import CustomsDiscrepancyApi from '../../../../apis/customs/CustomsDiscrepancyApi'
// I18N:
import enums from '../../../../i18n/enums'

// This is the actual visual layout for the Create/Edit Communication form.
export default function (props) {
  const formatKind = (str) => (
    enums.en.customs.discrepancies.communications.kinds[str] || str
  )

  return (
    <div className='customs-discrepancies-communication-form'>
      <ErrorList errors={props.errors} />
      <FieldList>
        <FormField label='Kind'>
          <DropDown
            autoFocus={true}
            source={CustomsDiscrepancyApi.communicationKinds}
            format={formatKind}
            value={props.kind}
            onChange={props.onKindChange}
          />
        </FormField>
        <FormField label='Date'>
          <input
            className='form-control'
            type='date'
            value={props.date || ''}
            onChange={props.onDateChange}
          />
        </FormField>
        <FormField label='Details' size='wide'>
          <textarea
            className='form-control'
            style={{ resize: 'vertical' }}
            value={props.details || ''}
            onChange={props.onDetailsChange}
          />
        </FormField>
      </FieldList>
    </div>
  )
}
