import React, { Component } from 'react'

// icon = http://fontawesome.io/icons/
export default class Button extends Component {

  render() {
    let css = `btn btn-sm btn-labeled btn-${this.props.type}`
    let icon = `fa fa-${this.props.icon || 'plus'} btn-label icon`
    return (
      <a 
        className={css}
        data-remote={this.props.remote || "true"}
        href={this.props.href}
        disabled={this.props.disabled}
        title={this.props.title || null}
        target={this.props.target || ''}
        onClick={this.props.onClick}
        data-method={this.props.method || 'get'}
      >
        <i className={icon} />{this.props.text || ''}
      </a>
    )
  }
}

