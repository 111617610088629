import React from 'react'

import DropdownButton from '../../reactive/common/DropdownButton'

// This contains the various option for the right-hand drop-down menu.
function PlanningUnitDropdown (props) {
  const id = props.unit.id
  const groupageId = props.groupageId || null

  return (
    <DropdownButton theme='info' size='btn-sm'>
      <li>
        <a href={`trailer_planners/units/${id}`} data-remote={true}>
          Check for problems
        </a>
      </li>

      <li>
        <a href={`planning_units/${id}/edit`} data-remote={true}>
          Edit details
        </a>
      </li>

      <li>
        <a href={`planning_units/${id}/edit?view=split`} data-remote={true}>
          Split this part
        </a>
      </li>

      {props.unit.split_job ? (
        <li>
          <a
            href={`/planning_units/${props.unit.id}`}
            data-remote={true}
            data-method='delete'
            data-confirm='Are you sure that you want to delete this part of the job?'
          >
            Delete this part
          </a>
        </li>
      ) : null}

      {props.unit.groupage_id !== groupageId ? (
        <li>
          <a
            href={`/trailer_planners/groupages/assign_job?groupage_id=${props.groupageId || ''}&job_id=${props.unit.job_id}`}
            data-remote={true}
            data-method='patch'
            data-confirm='Are you sure that you want to change the groupage for this job?'
          >
            {props.groupageId ? 'Assign' : 'Remove'} groupage
          </a>
        </li>
      ) : null}

      {props.unit.show_on_planner === 'always' ? (
        <li>
          <a
            href={`/planning_units/${props.unit.id}?job[road_details_attributes][show_on_planner]=if_filters_match`}
            data-remote={true}
            data-method='patch'
            data-confirm='Remove the "always show" property from this job?'
          >
            Unpin from planner
          </a>
        </li>
      ) : null}

    </DropdownButton>
  )
}

export default PlanningUnitDropdown
