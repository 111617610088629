import React from 'react'

// Displays a list of files attached to a given "parent" model.
export default class extends React.Component {
  // NOTE: For now, we just insert the old jQuery file browser into the page...
  componentDidMount () {
    const params = {
      parentId: this.props.model.id,
      parentType: this.props.model.modelName
    }

    $.ajax('/file_folders/tab_for_parent.js', { data: params })
  }

  // ...so we simply need to provide an "insertion point" for it:
  render () {
    return <div id='tab-attachments'></div>
  }
}
