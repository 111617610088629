import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import FieldList from '../../common/Forms/FieldList'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import ShowField from '../../common/Forms/ShowField'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import PrintQueueApi from '../../apis/printers/PrintQueueApi'

// This is the "show" page for a specific printer.
class PrinterShow extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    PrintQueueApi.show(this.props.match.params.id).then(
      json => {
        document.title = json.name
        this.setState({ json: json })
      },
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/printers/queues': 'Print Queues',
      [`/reactive/printers/queues/${this.state.json.id}`]: this.state.json.name
    }

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <div className='page-profile'>
          <Ribbon>
            <div className='pull-right'>
              <Link className='btn btn-sm btn-labeled btn-warning' to={`/reactive/printers/queues/${this.state.json.id}/edit`}>
                <i className='btn-label icon fa fa-edit' />
                Edit Print Queue
              </Link>
            </div>
          </Ribbon>
          <h3>{this.state.json.name}</h3>
          <TabGroup labels={['General']}>
            <TabContent>
              <FormSection title='General'>
                <FieldList>
                  <ShowField label='Name' value={this.state.json.name} />
                  <ShowField label='Status' value={this.state.json.status} />
                  <ShowField label='Device' value={this.state.json.systemName} />
                  <ShowField label='Category' value={this.state.json.category} />
                </FieldList>
              </FormSection>
            </TabContent>
          </TabGroup>
        </div>
      </div>
    )
  }
}

export default PrinterShow
