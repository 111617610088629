import React from 'react'

// Used for various kinds of icon -- including the icon key!
function PlannerIcon (props) {
  if (!props.show) { return null }

  return (
    <span className={`trailer-planning-icon ${props.kind}`} title={props.title}>
      {props.children}
    </span>
  )
}

export default PlannerIcon
