import React from 'react'

// Components:
import FieldList from '../../../common/Forms/FieldList'
import ShowField from '../../../common/Forms/ShowField'
// Helpers:
import DateHelper from '../../../helpers/DateHelper'
import NotificationHelper from '../../../helpers/events/NotificationHelper'

// The main content of the "show" view.
function Content (props) {
  const item = props.notification

  // Either of these may be null:
  const acknowledged = item.acknowledged || {}
  const dealtWith = item.dealtWith || {}

  const reasons = item.important && (
    <FieldList>
      <ShowField label='Acknowledged Text' value={acknowledged.text} />
      <ShowField label='Dealt With Text' value={dealtWith.text} />
    </FieldList>
  )

  return (
    <React.Fragment>
      <div className='well'>
        <i className='fa fa-info-circle' /> &nbsp;
        {item.message}
      </div>
      <FieldList>
        <ShowField label='Parent' value={NotificationHelper.parentLink(item)} />
        <ShowField label='Severity' value={item.severity} />
      </FieldList>
      <hr />
      <FieldList>
        <ShowField label='Notified Party 1' value={item.notify1.text} />
        <ShowField label='Notified At 1' value={DateHelper.formatDateTime(item.notify1.time)} />
        <ShowField label='Notified Party 2' value={item.notify2.text} />
        <ShowField label='Notified At 2' value={DateHelper.formatDateTime(item.notify2.time)} />
        <ShowField label='Notified Party 3' value={item.notify3.text} />
        <ShowField label='Notified At 3' value={DateHelper.formatDateTime(item.notify3.time)} />
      </FieldList>
      <hr />
      <FieldList>
        <ShowField label='Acknowledged By' value={acknowledged.name} />
        <ShowField label='Dealt With By' value={dealtWith.name} />
      </FieldList>
      {reasons}
    </React.Fragment>
  )
}

export default Content
