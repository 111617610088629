import React from 'react'

// Displays some text with (optional) Google Maps/Open Street Map hyperlinks.
function LocationWithMapLinks (props) {
  if (!props.place) { return null }

  const googleMapsUrl = 'https://www.google.com/maps/search/'
  const openStreetMapUrl = 'https://openstreetmap.org/'

  // NOTE: These are strings (not numbers), so whilst 0 == null, "0" == true.
  const lat = props.place.latitude
  const lon = props.place.longitude

  return (
    <div className='location-with-map-links'>
      <div className='location-with-map-links-description'>
        {props.place.reference || '(unknown)'}
      </div>
      {lat && lon && (
        <a href={`${googleMapsUrl}?api=1&query=${lat},${lon}`} target='_blank'>
          Google
        </a>
      )}
      {lat && lon && (
        <a href={`${openStreetMapUrl}?mlat=${lat}&mlon=${lon}`} target='_blank'>
          OSM
        </a>
      )}
    </div>
  )
}

export default LocationWithMapLinks
