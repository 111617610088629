import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import CheckBox from '../../common/Forms/CheckBox'
import ErrorList from '../../common/ErrorList'
import FieldList from '../../common/Forms/FieldList'
import FormField from '../../common/Forms/FormField'
import FormFooter from '../../common/Forms/FormFooter'
import FormHeader from '../../common/Forms/FormHeader'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import CustomsTradeTariffApi from '../../apis/customs/CustomsTradeTariffApi'

// This is the "edit" page for a specific customs carrier.
class CustomsTradeTariffForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.match.params.id || '',
      loading: true,
      errors: []
    }
  }

  componentDidMount () {
    if (!this.state.id) { return this.initCreate() }

    CustomsTradeTariffApi.show(this.state.id).then(
      this.initUpdate,
      this.handleErrors
    )
  }

  initCreate = () => {
    document.title = 'New Customs Trade Tariff'

    this.setState({
      id: null,
      exportCode: '',
      importCode: '',
      secondQuantityCode: '',
      thirdQuantityCode: '',
      description: '',
      vatCode: '',
      dutyRate: '',
      active: true,
      loading: false,
      errors: []
    })
  }

  initUpdate = (json) => {
    document.title = `Editing ${json.description}`

    this.setState({
      id: json.id,
      exportCode: json.exportCode || '',
      importCode: json.importCode || '',
      secondQuantityCode: json.secondQuantityCode || '',
      thirdQuantityCode: json.thirdQuantityCode || '',
      description: json.description || '',
      vatCode: json.vatCode || '',
      dutyRate: json.dutyRate || 0,
      active: json.active || false,
      loading: false,
      errors: []
    })
  }

  handleExportCodeChange = (event) => {
    this.setState({ exportCode: event.target.value })
  }

  handleImportCodeChange = (event) => {
    this.setState({ importCode: event.target.value })
  }

  handleSecondQuantityCodeChange = (event) => {
    this.setState({ secondQuantityCode: event.target.value })
  }

  handleThirdQuantityCodeChange = (event) => {
    this.setState({ thirdQuantityCode: event.target.value })
  }

  handleVatCodeChange = (event) => {
    this.setState({ vatCode: event.target.value })
  }

  handleDutyRateChange = (event) => {
    this.setState({ dutyRate: event.target.value })
  }

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value })
  }

  handleActiveChange = (event) => {
    this.setState({ active: !this.state.active })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const id = this.state.id

    const params = {
      tradeTariff: {
        exportCode: this.state.exportCode,
        importCode: this.state.importCode,
        secondQuantityCode: this.state.secondQuantityCode,
        thirdQuantityCode: this.state.thirdQuantityCode,
        vatCode: this.state.vatCode,
        dutyRate: this.state.dutyRate,
        description: this.state.description,
        active: this.state.active
      }
    }

    if (this.state.id) {
      CustomsTradeTariffApi.update(id, params).then(this.handleSuccess, this.handleErrors)
    } else {
      CustomsTradeTariffApi.create(params).then(this.handleSuccess, this.handleErrors)
    }
  }

  handleSuccess = (json) => {
    this.props.history.push('/reactive/customs/trade_tariffs')
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/trade_tariffs': 'Customs Trade Tariffs',
      '/reactive/customs/trade_tariffs/new': 'Edit'
    }

    const cancelUrl = '/reactive/customs/trade_tariffs'

    const sectionHeader = (
      <FormHeader title='General'>
        <label>
          Active
          <CheckBox value={this.state.active} onChange={this.handleActiveChange} />
        </label>
      </FormHeader>
    )

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>{document.title}</h3>
        <ErrorList errors={this.state.errors} />
        <TabGroup labels={['General']}>
          <TabContent>
            <FormSection title={sectionHeader}>
              <FieldList>
                <FormField label='Export Code'>
                  <input className='form-control' value={this.state.exportCode} onChange={this.handleExportCodeChange} />
                </FormField>
                <FormField label='Import Code'>
                  <input className='form-control' value={this.state.importCode} onChange={this.handleImportCodeChange} />
                </FormField>
                <FormField label='2nd Quantity Code'>
                  <input className='form-control' value={this.state.secondQuantityCode} onChange={this.handleSecondQuantityCodeChange} />
                </FormField>
                <FormField label='3rd Quantity Code'>
                  <input className='form-control' value={this.state.thirdQuantityCode} onChange={this.handleThirdQuantityCodeChange} />
                </FormField>
                <FormField label='VAT Code'>
                  <input className='form-control' value={this.state.vatCode} onChange={this.handleVatCodeChange} />
                </FormField>
                <FormField label='Duty Rate'>
                  <input className='form-control' value={this.state.dutyRate} onChange={this.handleDutyRateChange} type='number' min='0' step='0.01' />
                </FormField>
                <FormField label='Description' size='wide'>
                  <input className='form-control' value={this.state.description} onChange={this.handleDescriptionChange} />
                </FormField>
              </FieldList>
            </FormSection>
          </TabContent>
        </TabGroup>
        <FormFooter>
          <button className='btn btn-primary' onClick={this.handleSubmit}>
            Save Changes
          </button>
          <Link className='btn btn-labeled' to={cancelUrl}>
            <i className='btn-label fa fa-times' />
            Cancel
          </Link>
        </FormFooter>
      </div>
    )
  }
}

export default CustomsTradeTariffForm
