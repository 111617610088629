import React from 'react'

// Components:
import ErrorList from '../../../common/ErrorList'

// The main content of the "confirm" view.
function Content (props) {
  return (
    <React.Fragment>
      <ErrorList errors={props.errors} />
      <div className='well'>
        Please confirm why you are ready to mark
        &quot;{props.notification.title}&quot;
        as {props.notification.acknowledged ? 'dealt with' : 'acknowledged'}.
        This will be recorded for future reference.
      </div>
      <input
        autoFocus={true}
        className='form-control'
        value={props.reason}
        onChange={event => props.onReasonChange(event.target.value)}
      />
    </React.Fragment>
  )
}

export default Content
