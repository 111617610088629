import React from 'react'

import Page from './show/Page'
import CommunicationModal from './show/communications/ModalForm'
// APIs:
import CustomsDiscrepancyApi from '../../apis/customs/CustomsDiscrepancyApi'

// This is the "show" page for a specific discrepancy.
export default class extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      discrepancy: null,
      modalOpen: false,
      communication: null,
      errors: []
    }
  }

  // Store an error (list) on failure:
  handleError = json => this.setState({ errors: json.errors || [json.error] })

  // When the page first loads, we need to fetch the Discrepancy from the API:
  componentDidMount () {
    CustomsDiscrepancyApi.show(this.props.match.params.id).then(
      json => {
        document.title = `Discrepancy ${json.reference}`
        this.setState({ discrepancy: json })
      },
      json => {
        document.title = 'Error'
        this.handleError(json)
      }
    )
  }

  // If we destroy a discrepancy, redirect to the index page:
  handleDestroy = () => {
    if (!confirm('Are you sure?')) { return }

    CustomsDiscrepancyApi.destroy(this.props.match.params.id).then(
      () => this.props.history.push('/reactive/customs/discrepancies'),
      this.handleError
    )
  }

  // The communications modal MAY update the state when it closes:
  handleModalClose = (newDiscrepancy) => {
    this.setState({ modalOpen: false })
    if (newDiscrepancy) { this.setState({ discrepancy: newDiscrepancy }) }
  }

  handleCreateCommunication = () => {
    this.setState({ modalOpen: true, communication: null })
  }

  handleUpdateCommunication = (communication) => {
    this.setState({ modalOpen: true, communication: communication })
  }

  handleDeleteCommunication = (communication) => {
    if (!confirm('Are you sure?')) { return }

    const params = {
      nctsDiscrepancy: {
        communicationsAttributes: [{ id: communication.id, _destroy: true }]
      }
    }

    CustomsDiscrepancyApi.update(this.props.match.params.id, params).then(
      json => this.setState({ discrepancy: json }),
      this.handleError
    )
  }

  render () {
    return (
      <React.Fragment>
        <Page
          discrepancy={this.state.discrepancy}
          errors={this.state.errors}
          onDestroy={this.handleDestroy}
          onError={this.handleError}
          onCreateCommunication={this.handleCreateCommunication}
          onUpdateCommunication={this.handleUpdateCommunication}
          onDeleteCommunication={this.handleDeleteCommunication}
        />
        {this.state.modalOpen && (
          <CommunicationModal
            nctsDiscrepancyId={this.state.discrepancy.id}
            communication={this.state.communication}
            onClose={this.handleModalClose}
          />
        )}
      </React.Fragment>
    )
  }
}
