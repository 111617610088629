import React from 'react'

import JobList from './JobList'
import PleaseWait from '../reactive/common/PleaseWait'
import EmissionsApi from '../reactive/apis/jobs/EmissionsApi'

// FMS-9115: Display carbon emissions information for a set of jobs.
class ForGroupage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    EmissionsApi.all({ groupageId: this.props.groupageId }).then(
      json => this.setState({ jobs: json.items }),
      json => console.log(json.errors || [json.error])
    )
  }

  render () {
    return this.state.jobs ? <JobList jobs={this.state.jobs} /> : <PleaseWait />
  }
}

export default ForGroupage
