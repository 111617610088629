import React from 'react'

import PlanningUnitDimensions from './PlanningUnitDimensions'
import PlanningUnitDropdown from './PlanningUnitDropdown'
import PlanningUnitMovement from './PlanningUnitMovement'
import PlanningUnitNotes from './PlanningUnitNotes'
import PlanningUnitProblems from './PlanningUnitProblems'
import PlanningUnitStatus from './PlanningUnitStatus'
import HazardDetails from '../common/HazardDetails'
import PlannerIcon from '../common/PlannerIcon'

import DateHelper from '../helpers/DateHelper'

// Convenience function: Link to an entity IF they are present:
function linkToEntity (text, id) {
  return text ? <a href={`/entities/${id}`} target='_blank'>{text}</a> : null
}

// This represents an individual job part.
function PlanningUnit (props) {
  // TODO: Allow multi-DnD via props and an onClick-if-CTRL-held callback.
  const f = (event) => {
    const json = { id: props.unit.id, trailerBookingId: props.unit.block_id }
    const data = { units: [json] }
    event.dataTransfer.setData('application/json', JSON.stringify(data))
  }

  return (
    <div className='trailer-planning-planning-unit' draggable={true} onDragStart={f}>
      <div className='trailer-planning-planning-unit-icon'>
        <PlanningUnitStatus unit={props.unit} groupageId={props.groupageId} />
      </div>
      <div className='trailer-planning-planning-unit-name'>
        <a href={`/jobs/${props.unit.job_id}`} target='_blank'>
          {props.unit.name}
        </a>
      </div>
      <div className='trailer-planning-planning-unit-customer'>
        {linkToEntity(props.unit.customer, props.unit.customer_id)}
      </div>
      <div className='trailer-planning-planning-unit-shipper'>
        {linkToEntity(props.unit.shipper, props.unit.shipper_id)}
      </div>
      <div className='trailer-planning-planning-unit-shipping-region'>
        {props.unit.shipping_region}
      </div>
      <div className='trailer-planning-planning-unit-shipping-postcode'>
        {props.unit.shipping_postcode}
      </div>
      <div className='trailer-planning-planning-unit-consignee'>
        {linkToEntity(props.unit.consignee, props.unit.consignee_id)}
      </div>
      <div className='trailer-planning-planning-unit-consignee-region'>
        {props.unit.consignee_region}
      </div>
      <div className='trailer-planning-planning-unit-consignee-postcode'>
        {props.unit.consignee_postcode}
      </div>
      <div className='trailer-planning-planning-unit-package-details'>
        {props.unit.package_details}
      </div>
      <div className='trailer-planning-planning-unit-gross-weight'>
        {props.unit.gross_weight_kg}
      </div>
      <div className='trailer-planning-planning-unit-cubic-metres'>
        <PlanningUnitDimensions unit={props.unit}>
          {props.unit.cubic_metres && parseFloat(props.unit.cubic_metres).toFixed(2)}
        </PlanningUnitDimensions>
      </div>
      <div className='trailer-planning-planning-unit-loading-metres'>
        {props.unit.loading_metres}
      </div>
      <div className='trailer-planning-planning-unit-taxable-weight'>
        {props.unit.taxable_weight}
      </div>
      <div className='trailer-planning-planning-unit-notes'>
        <PlanningUnitNotes unit={props.unit} />
      </div>
      <div className='trailer-planning-planning-unit-consignment-flags'>
        <PlannerIcon
          kind='must-load'
          show={props.unit.must_load}
          title='Must Load'
        />
        <PlannerIcon
          kind='single-load'
          show={props.unit.single_load}
          title='Single Load'
        />
        <PlannerIcon
          kind='do-not-stack'
          show={props.unit.do_not_stack}
          title='Do Not Stack'
        />
        <PlannerIcon
          kind='do-not-transship'
          show={props.unit.do_not_transship}
          title='Do Not Transship'
        />
        <PlannerIcon
          kind='waste'
          show={props.unit.waste}
          title='Waste'
        />
        <PlannerIcon
          kind='foodstuffs'
          show={props.unit.foodstuffs}
          title='Foodstuffs'
        />
        <PlannerIcon
          kind='temperature'
          show={props.unit.temperature_controlled}
          title='Temperature-Controlled'
        />
        <PlannerIcon
          kind='overlength'
          show={props.unit.overlength}
          title='Overlength'
        />
        <PlannerIcon
          kind='tall-packages'
          show={props.unit.tall_packages}
          title='Tall Packages'
        />
        <PlannerIcon
          kind='tail-lift'
          show={props.unit.tail_lift_on_collection || props.unit.tail_lift_on_delivery}
          title='Tail Lift'
        />
      </div>
      <div className='trailer-planning-planning-unit-dangerous-goods'>
        <HazardDetails hazards={props.unit.dangerous_goods} />
      </div>
      <div className='trailer-planning-planning-unit-ready-date'>
        {DateHelper.formatShort(props.unit.ready_date)}
      </div>
      <div className='trailer-planning-planning-unit-delivery-required-by'>
        {DateHelper.formatShort(props.unit.required_by)}
      </div>
      <div className='trailer-planning-planning-unit-collection-date'>
        {DateHelper.formatShort(props.unit.collection_date)}
      </div>
      <div className='trailer-planning-planning-unit-due-in-date'>
        {DateHelper.formatShort(props.unit.due_in_date)}
      </div>
      <div className='trailer-planning-planning-unit-customs-status'>
        <span title={props.unit.customs_mrn || '(No MRN)'}>
          {props.unit.customs_status}
        </span>
      </div>
      <div className='trailer-planning-planning-unit-problems'>
        <PlanningUnitProblems unit={props.unit} />
      </div>
      <div className='trailer-planning-planning-unit-origin-hub'>
        {props.unit.origin_hub}
      </div>
      <div className='trailer-planning-planning-unit-destination-hub'>
        {props.unit.destination_hub}
      </div>
      <div className='trailer-planning-planning-unit-collection'>
        <PlanningUnitMovement
          purpose='collection'
          unit={props.unit}
          cotDotBookings={props.cotDotBookings}
        />
      </div>
      <div className='trailer-planning-planning-unit-delivery'>
        <PlanningUnitMovement
          purpose='delivery'
          unit={props.unit}
          cotDotBookings={props.cotDotBookings}
        />
      </div>
      <div className='trailer-planning-planning-unit-actions'>
        <PlanningUnitDropdown unit={props.unit} groupageId={props.groupageId} />
      </div>
    </div>
  )
}

export default PlanningUnit
