/* eslint prefer-promise-reject-errors: "off" */
import sendRequest from './sendRequest'

// Encapsulates all AJAX calls related to planning units.
class PlanningUnitApi {
  static unassigned () {
    return sendRequest('GET', '/trailer_planners')
  }

  // This is a currently a non-JSON endpoint -- emulate one:
  static update (id, params) {
    return new Promise((resolve, reject) => {
      $.ajax(`/planning_units/${id}/change`, {
        method: 'POST',
        data: { planning_unit: params },
        success: () => resolve({ id: id }),
        error: (_xhr, _status, error) => reject({ error: error })
      })
    })
  }

  // This is a currently a non-JSON endpoint -- emulate one:
  static assignMovement (id, kind, bookingId) {
    return new Promise((resolve, reject) => {
      $.ajax(`/planning_units/${id}/movement`, {
        method: 'POST',
        data: { movement_type: kind, trailer_booking_id: bookingId },
        success: () => resolve({ id: id }),
        error: (_xhr, _status, error) => reject({ error: error })
      })
    })
  }

  static assign (units) {
    // Example: `46:31->32` means "Move Unit 46 from Trailer 31 to Trailer 32".
    const moves = units.map(a => `${a.id}:${a.oldParentId}->${a.newParentId}`)
    const params = { assignments: moves.join(',') }
    return sendRequest('PATCH', '/trailer_planners/assign_units', params)
  }
}

export default PlanningUnitApi
