import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import SimpleTable from '../../common/SimpleTable'
// APIs:
import IncotermApi from '../../apis/financials/IncotermApi'
// I18N:
import enums from '../../i18n/enums'

// Format the applicable incoterm "versions" as a simple string:
function incotermYears (incoterm) {
  const values = [
    incoterm.incoterms2010 ? '2010' : null,
    incoterm.incoterms2020 ? '2020' : null
  ]

  return values.filter(s => s).join(', ')
}

// Format the applicable modes of transport as a simple string:
function incotermModes (incoterm) {
  const values = [
    incoterm.road ? 'Road' : null,
    incoterm.sea ? 'Sea' : null,
    incoterm.air ? 'Air' : null,
    incoterm.rail ? 'Rail' : null
  ]

  return values.filter(s => s).join(', ')
}

// This is the index page, which lists all incoterms.
class IncotermIndex extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    document.title = 'Incoterms'

    IncotermApi.all().then(
      json => this.setState({ json: json.items }),
      error => console.log(error)
    )
  }

  handleDestroy (id) {
    if (!confirm('Are you sure?')) { return }

    IncotermApi.destroy(id).then(
      () => this.setState({ json: this.state.json.filter(x => x.id !== id) }),
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/financials/incoterms': 'Incoterms'
    }

    const headers = [
      { label: 'Incoterm' },
      { label: 'Name' },
      { label: 'Versions' },
      { label: 'Modes' },
      { label: 'Freight' },
      { label: 'Shipper Responsible' },
      { label: 'Invoicing Rules' },
      { label: 'Actions' }
    ]

    // We haven't implemented sorting for "reactive" tables yet, so do it here:
    const records = this.state.json.slice().sort((a, b) => a.incoterm > b.incoterm)

    const enumTextFor = (k, v) => enums.en.financials.incoterms[k][v] || v

    const items = records.map(incoterm => (
      <tr key={incoterm.id}>
        <td>
          <Link to={`/reactive/financials/incoterms/${incoterm.id}`}>{incoterm.incoterm}</Link>
        </td>
        <td>{incoterm.name}</td>
        <td>{incotermYears(incoterm)}</td>
        <td>{incotermModes(incoterm)}</td>
        <td>{incoterm.freight}</td>
        <td>{enumTextFor('shipperOptions', incoterm.shipperResponsible)}</td>
        <td>{incoterm.invoicingRule}</td>
        <td>
          <Link className='btn btn-xs' to={`/reactive/financials/incoterms/${incoterm.id}/edit`}>
            Edit
          </Link>
          <a className='btn btn-xs btn-danger' onClick={() => this.handleDestroy(incoterm.id)}>
            Destroy
          </a>
        </td>
      </tr>
    ))

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <Ribbon>
          <div className='pull-right'>
            <Link className='btn btn-sm btn-labeled btn-primary' to='/reactive/financials/incoterms/new'>
              <i className='btn-label icon fa fa-plus' />
              New Incoterm
            </Link>
          </div>
        </Ribbon>
        &nbsp;
        <SimpleTable caption='Incoterms' headers={headers}>
          {items}
        </SimpleTable>
      </div>
    )
  }
}

export default IncotermIndex
