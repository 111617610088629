import BaseApi from '../BaseApi'
import buildGetQuery from '../buildGetQuery'

// This class handles all API interactions related to notifications.
class NotificationApi {
  static all (params) {
    const data = buildGetQuery(params)
    return BaseApi.sendRequest('GET', 'api/events/notifications.json', data)
  }

  static forParent (params) {
    const url = 'api/events/notifications/for_parent.json'
    return BaseApi.sendRequest('GET', url, buildGetQuery(params))
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/events/notifications/${id}.json`)
  }

  static acknowledge (id, reason) {
    const url = `api/events/notifications/${id}/acknowledge.json`
    return BaseApi.sendRequest('POST', url, { reason: reason })
  }

  static dealWith (id, reason) {
    const url = `api/events/notifications/${id}/deal_with.json`
    return BaseApi.sendRequest('POST', url, { reason: reason })
  }
}

export default NotificationApi
