import React, { Component } from 'react'

import Button from '../Button'

import StateManager from '../StateManager'

export default class Excel extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
  }

  render() {
    return <span
      className="btn btn-sm btn-info btn-excel"
      onClick={this.onClick.bind(this)}
      title="Export current view to excel"
    >
      <i className="fa fa-fw fa-file-excel-o" />
    </span>
  }

  onClick() {
    this.manager.excelExport()
  }
}

