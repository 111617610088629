import BaseApi from '../BaseApi'

// This class handles all API interactions related to customs submissions.
class CustomsSubmissionApi {
  static submitShipment (jobId) {
    const url = 'api/customs/submissions/submit_shipment.json'
    return BaseApi.sendRequest('POST', url, { jobId: jobId })
  }

  static submitNctsShipment (groupageId, ccg, alci, jobIds) {
    const url = 'api/customs/submissions/submit_ncts_shipment.json'
    const foo = { groupageId: groupageId, ccg: ccg, alci: alci, jobIds: jobIds }
    return BaseApi.sendRequest('POST', url, foo)
  }

  static submitIcsMovement (groupageId) {
    const url = 'api/customs/submissions/submit_ics_movement.json'
    return BaseApi.sendRequest('POST', url, { groupageId: groupageId })
  }

  static submitGvmsMovement (groupageId) {
    const url = 'api/customs/submissions/submit_gvms_movement.json'
    return BaseApi.sendRequest('POST', url, { groupageId: groupageId })
  }

  static cancelGvmsMovement (groupageId) {
    const url = 'api/customs/submissions/cancel_gvms_movement.json'
    return BaseApi.sendRequest('POST', url, { groupageId: groupageId })
  }
}

export default CustomsSubmissionApi
