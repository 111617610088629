import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import CountryDropdown from '../../places/countries/CountryDropdown'
import ErrorList from '../../common/ErrorList'
import FieldList from '../../common/Forms/FieldList'
import FormField from '../../common/Forms/FormField'
import FormFooter from '../../common/Forms/FormFooter'
import FormSection from '../../common/Forms/FormSection'
import OfficeLocationDropdown from '../office_locations/OfficeLocationDropdown'
import PleaseWait from '../../common/PleaseWait'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import CustomsPortApi from '../../apis/customs/CustomsPortApi'

// This is the "edit" page for a specific customs port.
class CustomsPortForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.match.params.id || '',
      loading: true,
      errors: []
    }
  }

  componentDidMount () {
    if (!this.state.id) { return this.initCreate() }

    CustomsPortApi.show(this.state.id).then(
      this.initUpdate,
      this.handleErrors
    )
  }

  initCreate = () => {
    document.title = 'New Customs Port'

    this.setState({
      id: null,
      gvmsCode: '',
      name: '',
      cdsCode: '',
      chiefCode: '',
      countryId: '',
      officeLocationId: '',
      loading: false,
      errors: []
    })
  }

  initUpdate = (json) => {
    document.title = `Editing ${json.name}`

    this.setState({
      id: json.id,
      gvmsCode: json.gvmsCode || '',
      name: json.name || '',
      cdsCode: json.cdsCode || '',
      chiefCode: json.chiefCode || '',
      countryId: json.country?.id || '',
      officeLocationId: json.officeLocation?.id || '',
      loading: false,
      errors: []
    })
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleGvmsCodeChange = (event) => {
    this.setState({ gvmsCode: event.target.value })
  }

  handleCdsCodeChange = (event) => {
    this.setState({ cdsCode: event.target.value })
  }

  handleChiefCodeChange = (event) => {
    this.setState({ chiefCode: event.target.value })
  }

  handleCountryChange = (event) => {
    this.setState({ countryId: event.target.value })
  }

  handleOfficeLocationChange = (event) => {
    this.setState({ officeLocationId: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const id = this.state.id
    const params = { port: this.state }

    if (id) {
      CustomsPortApi.update(id, params).then(this.handleSuccess, this.handleErrors)
    } else {
      CustomsPortApi.create(params).then(this.handleSuccess, this.handleErrors)
    }
  }

  handleSuccess = (json) => {
    this.props.history.push('/reactive/customs/ports')
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/ports': 'Customs Ports',
      '/reactive/customs/ports/new': 'Edit'
    }

    const cancelUrl = '/reactive/customs/ports'

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>{document.title}</h3>
        <ErrorList errors={this.state.errors} />
        <TabGroup labels={['General']}>
          <TabContent>
            <FormSection title='General'>
              <FieldList>
                <FormField label='GVMS'>
                  <input
                    className='form-control'
                    value={this.state.gvmsCode}
                    onChange={this.handleGvmsCodeChange}
                  />
                </FormField>
                <FormField label='Name'>
                  <input
                    className='form-control'
                    value={this.state.name}
                    onChange={this.handleNameChange}
                  />
                </FormField>
                <FormField label='CDS'>
                  <input
                    className='form-control'
                    value={this.state.cdsCode}
                    onChange={this.handleCdsCodeChange}
                  />
                </FormField>
                <FormField label='CHIEF'>
                  <input
                    className='form-control'
                    value={this.state.chiefCode}
                    onChange={this.handleChiefCodeChange}
                  />
                </FormField>
                <FormField label='Country'>
                  <CountryDropdown
                    value={this.state.countryId}
                    onChange={this.handleCountryChange}
                  />
                </FormField>
                <FormField label='Office'>
                  <OfficeLocationDropdown
                    value={this.state.officeLocationId}
                    onChange={this.handleOfficeLocationChange}
                  />
                </FormField>
              </FieldList>
            </FormSection>
          </TabContent>
        </TabGroup>
        <FormFooter>
          <button className='btn btn-primary' onClick={this.handleSubmit}>
            Save Changes
          </button>
          <Link className='btn btn-labeled' to={cancelUrl}>
            <i className='btn-label fa fa-times' />
            Cancel
          </Link>
        </FormFooter>
      </div>
    )
  }
}

export default CustomsPortForm
