import React, { Component } from 'react'

import Button from '../Button'
import { url_for } from '../Routes'

export default class NewBooking extends Component {

  render() {
    return (
      <Button
        text="New Booking"
        type="primary"
        title="Create new trailer booking"
        disabled={!this.props.permissions.can_create_booking}
        href={url_for("new_trailer_planners_booking_path")}
      />
    )
  }

// private

}
