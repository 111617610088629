import React, { Component } from 'react'
import Select from 'react-select'

import StateManager from '../StateManager'

const PROGRESSES = ['pending', 'arrived', 'in_progress', 'complete']

const LABELS = {
  complete: 'Loaded/Discharged'
}

export default class ProgressFilter extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerProgressFilter(this)
    this.state = this.manager.getProgresses()
    this.options = PROGRESSES.map(p => ({
      value: p,
      label: LABELS[p] || p.humanize()
    }))
  }

  redraw() {
    // Should check if we are mounted!
    this.setState(this.manager.getProgresses())
  }

  setProgresses(value) {
    this.manager.setProgresses(value)
  }

  render() {
    return (
      <div className="progress-filter">
        <span className="filter-title">Filter Booking Progress</span>
        <Select
          options={this.options}
          multi={true}
          simpleValue={true}
          placeholder="All Bookings"
          value={this.state.progress}
          onChange={this.setProgresses.bind(this)}
        />
      </div>
    )
  }

}
