import React from 'react'

import CustomsSubmissionApi from '../reactive/apis/customs/CustomsSubmissionApi'
import showErrors from './showErrors'

// This is used on the Groupage screen to submit GVMS data.
class GvmsButton extends React.Component {
  constructor (props) {
    super(props)

    this.state = { reference: props.reference, status: props.status }
  }

  handleSubmit = () => {
    if (!window.confirm('Upload GVMS movement?')) { return }

    CustomsSubmissionApi.submitGvmsMovement(this.props.id).then(
      json => this.setState({ reference: json.reference, status: json.status }),
      json => showErrors(json.errors || [json.error])
    )
  }

  handleCancel = () => {
    if (!window.confirm('Cancel GVMS movement?')) { return }

    CustomsSubmissionApi.cancelGvmsMovement(this.props.id).then(
      json => this.setState({ reference: null, status: null }),
      json => showErrors(json.errors || [json.error])
    )
  }

  render () {
    // Prevent changes once the groupage is considered "finalised" by the GVMS:
    const locked = this.state.status && this.state.status !== 'NOT_FINALISABLE'

    // Give the "submit" button a different theme based on the current status:
    let theme = 'success'
    let icon = 'check-circle'

    if (this.state.status === 'NOT_FINALISABLE') {
      theme = 'warning'
      icon = 'repeat'
    } else if (!this.state.reference) {
      theme = 'default'
      icon = 'upload'
    }

    // Show the reference, if we have one:
    let label = this.state.reference || 'Create'
    if (locked && !this.state.reference) { label = 'Pending' }

    return (
      <React.Fragment>
        <button
          className={`btn btn-labeled btn-sm btn-${theme}`}
          onClick={this.handleSubmit}
          disabled={locked}
        >
          <i className={`btn-label fa fa-${icon}`} /> GVMS: {label}
        </button>
        &nbsp;
        <button
          className='btn btn-labeled btn-sm btn-danger'
          onClick={this.handleCancel}
          disabled={!this.state.reference}>
          <i className='btn-label fa fa-trash' /> Cancel GVMS
        </button>
      </React.Fragment>
    )
  }
}

export default GvmsButton
