import React from 'react'

import BooleanIcon from './BooleanIcon'
import FormField from './FormField'

// Represents a single field on "show" views.
function ShowField (props) {
  if (props.value === true || props.value === false) {
    // Boolean values are displayed differently:
    return (
      <FormField label={props.label} size={props.size}>
        <div className='form-control no-border no-bg no-left-padding'>
          <BooleanIcon value={props.value} />
        </div>
      </FormField>
    )
  } else if (!props.prefix && !props.suffix) {
    // Fields without a suffix/prefix do not need to be part of an input group:
    return (
      <FormField label={props.label} size={props.size}>
        <div className='form-control'>{props.value}</div>
      </FormField>
    )
  }

  // If a suffix or prefix is required, use a Bootstrap "input group" tag:
  return (
    <FormField label={props.label} size={props.size}>
      <div className='input-group'>
        {props.prefix && <div className='input-group-addon'>{props.prefix}</div>}
        <div className='form-control'>{props.value}</div>
        {props.suffix && <div className='input-group-addon'>{props.suffix}</div>}
      </div>
    </FormField>
  )
}

export default ShowField
