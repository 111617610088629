import React from 'react'

// Part of the top bar.
function NavbarLinks (props) {
  return (
    <li className='dropdown'>
      <a href='#' className='dropdown-toggle' data-toggle='dropdown' id='nav-quick-links'>Quick Links</a>
      <ul className='dropdown-menu'>
        <li>
          <a href='/entities?category=Customers'>Customers</a>
        </li>
        <li>
          <a href='/entities?category=Suppliers'>Suppliers</a>
        </li>
        <li>
          <a href='/entities?category=Partners'>Partners</a>
        </li>
        <li>
          <a href='/entities?category=Forwarding+Agents'>Forwarding Agents</a>
        </li>
        <li className='divider'></li>
        <li>
          <a href={`/jobs?controller_id=${props.user.id}`}>Your Jobs</a>
        </li>
        <li>
          <a href={`/quotes?quote_creator_id=${props.user.id}`}>Your Quotes</a>
        </li>
        <li className='divider'></li>
        <li>
          <a className='js-show-spinner' data-remote='true' href='/emails/new'>Send Email</a>
        </li>
      </ul>
    </li>
  )
}

export default NavbarLinks
