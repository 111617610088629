import React, { Component } from 'react'
import Select from 'react-select'

import StateManager from '../StateManager'

let options = null 

export default class CountryFilter extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.manager.registerCountryFilter(this)
    this.state = this.manager.getCountries()
    this._initOptions(props.options)
  }

  redraw() {
    // Should check if we are mounted!
    this.setState(this.manager.getCountries())
  }

  render() {
    return (
      <div className="country-filters-wrapper">
        <div className="country-filter">
          <span className="filter-title">Filter Origin Country</span>
          <Select 
            options={options}
            multi={true}
            simpleValue={true}
            placeholder="All origins"
            value={this.state.origin}
            onChange={this.setOrigin.bind(this)}
          />
        </div>
        <div className="country-filter">
          <span className="filter-title">Filter Destination Country</span>
          <Select 
            options={options}
            multi={true}
            simpleValue={true}
            placeholder="All destinations"
            value={this.state.destination}
            onChange={this.setDestination.bind(this)}
          />
        </div>
      </div>
    )
  }

  setOrigin(countries)  {
    this.manager.setOrigin(countries)
  }

  setDestination(countries) {
    this.manager.setDestination(countries)
  }

  _initOptions(countries) {
    if (options) { return }
    options = countries.map(c => ({
      value: c.country_code,
      label: `${c.name} (${c.country_code})`
    }))
  }
}

