import React from 'react'

import DateHelper from '../../helpers/DateHelper'

// Show "Tue 01/23/4567" or "11/22/3333 to 44/55/6666" based on the range type.
function dateText (date, mode) {
  const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const dayName = daysOfTheWeek[date.getDay()]
  if (mode !== 'week') { return `${dayName} ${DateHelper.formatLong(date)}` }

  const endDate = DateHelper.addDays(date, 6)
  return `${DateHelper.formatLong(date)} to ${DateHelper.formatLong(endDate)}`
}

// This represents the date range widget and associated buttons.
function DateRangeFilter (props) {
  return (
    <div className='trailer-planning-date-range-filter'>
      <button
        className='trailer-planning-date-range-filter-mode'
        disabled={props.disabled}
        onClick={props.onRangeTypeToggle}
      >
        {props.rangeType}
      </button>
      <button
        className='trailer-planning-date-range-filter-back'
        disabled={props.disabled}
        onClick={props.onPriorDate}
      >
        Back
      </button>
      <div className='trailer-planning-date-range-filter-value'>
        {props.disabled ? null : dateText(props.startDate, props.rangeType)}
      </div>
      <button
        className='trailer-planning-date-range-filter-next'
        disabled={props.disabled}
        onClick={props.onNextDate}
      >
        Next
      </button>
    </div>
  )
}

export default DateRangeFilter
