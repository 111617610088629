import React from 'react'

import GroupageValue from '../Trailers/GroupageValue'

// This is just easier to read:
const sumOf = (values) => values.reduce((total, value) => (total + value), 0)

// This represents the "footer" of a planning unit list.
function PlanningUnitFooter (props) {
  const jobCount = new Set(props.units.map(unit => unit.job_id)).size
  const quantities = props.units.map(unit => parseFloat(unit.package_details))
  const grossWeights = props.units.map(unit => unit.gross_weight_kg)
  const cubicMetres = props.units.map(unit => Number(unit.cubic_metres))
  const loadingMetres = props.units.map(unit => Number(unit.loading_metres))
  const taxableWeights = props.units.map(unit => Number(unit.taxable_weight))

  const capacityRemaining = (props.capacity || 0) - sumOf(grossWeights)
  const overweightCss = capacityRemaining < 0 ? 'overweight' : 'within-limits'

  return (
    <div className={`trailer-planning-planning-unit-footer ${overweightCss}`}>
      <div className='trailer-planning-planning-unit-footer-job-count'>
        {jobCount} {jobCount === 1 ? 'job' : 'jobs'}
      </div>
      <div className='trailer-planning-planning-unit-footer-capacity'>
        {props.capacity ? (
          <span className={capacityRemaining < 0 ? 'acceptable' : 'overweight'}>
            Available: {capacityRemaining} kg
          </span>
        ) : null}
      </div>
      <div className='trailer-planning-planning-unit-footer-package-details'>
        {sumOf(quantities)} pkg
      </div>
      <div className='trailer-planning-planning-unit-footer-gross-weight'>
        {sumOf(grossWeights)} kg
      </div>
      <div className='trailer-planning-planning-unit-footer-cubic-metres'>
        {sumOf(cubicMetres).toFixed(2)} m³
      </div>
      <div className='trailer-planning-planning-unit-footer-loading-metres'>
        {sumOf(loadingMetres)} m
      </div>
      <div className='trailer-planning-planning-unit-footer-taxable-weight'>
        {sumOf(taxableWeights)}
      </div>
      <div className='trailer-planning-planning-unit-footer-value'>
        <GroupageValue groupageId={props.groupageId} />
      </div>
    </div>
  )
}

export default PlanningUnitFooter
