import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import ClaimTriggerIndex from './claims/triggers/ClaimTriggerIndex'
import ClaimTriggerForm from './claims/triggers/ClaimTriggerForm'

import ClaimTypeIndex from './claims/types/ClaimTypeIndex'
import ClaimTypeForm from './claims/types/ClaimTypeForm'

import ComplaintTypeIndex from './complaints/types/ComplaintTypeIndex'
import ComplaintTypeForm from './complaints/types/ComplaintTypeForm'

import ContactMethodIndex from './crm/contact_methods/ContactMethodIndex'
import ContactMethodForm from './crm/contact_methods/ContactMethodForm'

import CustomsCarrierIndex from './customs/carriers/CustomsCarrierIndex'
import CustomsCarrierForm from './customs/carriers/CustomsCarrierForm'

import CustomsChargesPage from './customs/charges/CustomsChargesPage'

import CustomsDiscrepancyIndex from './customs/discrepancies/CustomsDiscrepancyIndex'
import CustomsDiscrepancyForm from './customs/discrepancies/CustomsDiscrepancyForm'
import CustomsDiscrepancyShow from './customs/discrepancies/CustomsDiscrepancyShow'

import CustomsInspectionIndex from './customs/inspections/CustomsInspectionIndex'

import CustomsOfficeLocationIndex from './customs/office_locations/CustomsOfficeLocationIndex'
import CustomsOfficeLocationForm from './customs/office_locations/CustomsOfficeLocationForm'

import CustomsPortIndex from './customs/ports/CustomsPortIndex'
import CustomsPortForm from './customs/ports/CustomsPortForm'

import CustomsRouteIndex from './customs/routes/CustomsRouteIndex'
import CustomsRouteForm from './customs/routes/CustomsRouteForm'

import CustomsTradeTariffIndex from './customs/trade_tariffs/CustomsTradeTariffIndex'
import CustomsTradeTariffForm from './customs/trade_tariffs/CustomsTradeTariffForm'

import EventIndex from './events/rules/EventIndex'
import EventForm from './events/rules/EventForm'

import IncotermIndex from './financials/incoterms/IncotermIndex'
import IncotermForm from './financials/incoterms/IncotermForm'
import IncotermShow from './financials/incoterms/IncotermShow'

import LeadSourceIndex from './crm/lead_sources/LeadSourceIndex'
import LeadSourceForm from './crm/lead_sources/LeadSourceForm'

import NotificationIndex from './events/notifications/NotificationIndex'

import PrinterIndex from './printers/queues/PrinterIndex'
import PrinterForm from './printers/queues/PrinterForm'
import PrinterShow from './printers/queues/PrinterShow'

import SalesJournalEntryTypeIndex from './crm/sales_journal_entry_types/SalesJournalEntryTypeIndex'
import SalesJournalEntryTypeForm from './crm/sales_journal_entry_types/SalesJournalEntryTypeForm'

import SalesMarketLostReasonIndex from './crm/sales_market_lost_reasons/SalesMarketLostReasonIndex'
import SalesMarketLostReasonForm from './crm/sales_market_lost_reasons/SalesMarketLostReasonForm'

import SalesMarketStatusIndex from './crm/sales_market_statuses/SalesMarketStatusIndex'
import SalesMarketStatusForm from './crm/sales_market_statuses/SalesMarketStatusForm'

import TrailerTypeIndex from './movements/trailer_types/TrailerTypeIndex'
import TrailerTypeForm from './movements/trailer_types/TrailerTypeForm'
import TrailerTypeShow from './movements/trailer_types/TrailerTypeShow'

import UnknownRoute from './UnknownRoute'

export default function App () {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={ClaimTriggerForm} exact path='/reactive/claims/triggers/new' />
        <Route component={ClaimTriggerForm} exact path='/reactive/claims/triggers/:id/edit' />
        <Route component={ClaimTriggerIndex} exact path='/reactive/claims/triggers' />

        <Route component={ClaimTypeForm} exact path='/reactive/claims/types/new' />
        <Route component={ClaimTypeForm} exact path='/reactive/claims/types/:id/edit' />
        <Route component={ClaimTypeIndex} exact path='/reactive/claims/types' />

        <Route component={ComplaintTypeForm} exact path='/reactive/complaints/types/new' />
        <Route component={ComplaintTypeForm} exact path='/reactive/complaints/types/:id/edit' />
        <Route component={ComplaintTypeIndex} exact path='/reactive/complaints/types' />

        <Route component={ContactMethodForm} exact path='/reactive/crm/contact_methods/new' />
        <Route component={ContactMethodForm} exact path='/reactive/crm/contact_methods/:id/edit' />
        <Route component={ContactMethodIndex} exact path='/reactive/crm/contact_methods' />

        <Route component={CustomsCarrierForm} exact path='/reactive/customs/carriers/new' />
        <Route component={CustomsCarrierForm} exact path='/reactive/customs/carriers/:id/edit' />
        <Route component={CustomsCarrierIndex} exact path='/reactive/customs/carriers' />

        <Route component={CustomsChargesPage} exact path='/reactive/customs/charges' />

        <Route component={CustomsDiscrepancyForm} exact path='/reactive/customs/discrepancies/new' />
        <Route component={CustomsDiscrepancyForm} exact path='/reactive/customs/discrepancies/:id/edit' />
        <Route component={CustomsDiscrepancyIndex} exact path='/reactive/customs/discrepancies' />
        <Route component={CustomsDiscrepancyShow} exact path='/reactive/customs/discrepancies/:id' />

        <Route component={CustomsInspectionIndex} exact path='/reactive/customs/inspections' />

        <Route component={CustomsOfficeLocationForm} exact path='/reactive/customs/office_locations/new' />
        <Route component={CustomsOfficeLocationForm} exact path='/reactive/customs/office_locations/:id/edit' />
        <Route component={CustomsOfficeLocationIndex} exact path='/reactive/customs/office_locations' />

        <Route component={CustomsPortForm} exact path='/reactive/customs/ports/new' />
        <Route component={CustomsPortForm} exact path='/reactive/customs/ports/:id/edit' />
        <Route component={CustomsPortIndex} exact path='/reactive/customs/ports' />

        <Route component={CustomsRouteForm} exact path='/reactive/customs/routes/new' />
        <Route component={CustomsRouteForm} exact path='/reactive/customs/routes/:id/edit' />
        <Route component={CustomsRouteIndex} exact path='/reactive/customs/routes' />

        <Route component={CustomsTradeTariffForm} exact path='/reactive/customs/trade_tariffs/new' />
        <Route component={CustomsTradeTariffForm} exact path='/reactive/customs/trade_tariffs/:id/edit' />
        <Route component={CustomsTradeTariffIndex} exact path='/reactive/customs/trade_tariffs' />

        <Route component={EventForm} exact path='/reactive/events/rules/new' />
        <Route component={EventForm} exact path='/reactive/events/rules/:id/edit' />
        <Route component={EventIndex} exact path='/reactive/events/rules' />

        <Route component={IncotermForm} exact path='/reactive/financials/incoterms/new' />
        <Route component={IncotermShow} exact path='/reactive/financials/incoterms/:id' />
        <Route component={IncotermForm} exact path='/reactive/financials/incoterms/:id/edit' />
        <Route component={IncotermIndex} exact path='/reactive/financials/incoterms' />

        <Route component={LeadSourceForm} exact path='/reactive/crm/lead_sources/new' />
        <Route component={LeadSourceForm} exact path='/reactive/crm/lead_sources/:id/edit' />
        <Route component={LeadSourceIndex} exact path='/reactive/crm/lead_sources' />

        <Route component={NotificationIndex} exact path='/reactive/events/notifications' />

        <Route component={PrinterForm} exact path='/reactive/printers/queues/new' />
        <Route component={PrinterShow} exact path='/reactive/printers/queues/:id' />
        <Route component={PrinterForm} exact path='/reactive/printers/queues/:id/edit' />
        <Route component={PrinterIndex} exact path='/reactive/printers/queues' />

        <Route component={SalesJournalEntryTypeForm} exact path='/reactive/crm/sales_journal_entry_types/new' />
        <Route component={SalesJournalEntryTypeForm} exact path='/reactive/crm/sales_journal_entry_types/:id/edit' />
        <Route component={SalesJournalEntryTypeIndex} exact path='/reactive/crm/sales_journal_entry_types' />

        <Route component={SalesMarketLostReasonForm} exact path='/reactive/crm/sales_market_lost_reasons/new' />
        <Route component={SalesMarketLostReasonForm} exact path='/reactive/crm/sales_market_lost_reasons/:id/edit' />
        <Route component={SalesMarketLostReasonIndex} exact path='/reactive/crm/sales_market_lost_reasons' />

        <Route component={SalesMarketStatusForm} exact path='/reactive/crm/sales_market_statuses/new' />
        <Route component={SalesMarketStatusForm} exact path='/reactive/crm/sales_market_statuses/:id/edit' />
        <Route component={SalesMarketStatusIndex} exact path='/reactive/crm/sales_market_statuses' />

        <Route component={TrailerTypeForm} exact path='/reactive/movements/trailer_types/new' />
        <Route component={TrailerTypeShow} exact path='/reactive/movements/trailer_types/:id' />
        <Route component={TrailerTypeForm} exact path='/reactive/movements/trailer_types/:id/edit' />
        <Route component={TrailerTypeIndex} exact path='/reactive/movements/trailer_types' />

        <Route component={UnknownRoute} />
      </Switch>
    </BrowserRouter>
  )
}
