import React from 'react'

// A container element for various tabs.
class TabGroup extends React.Component {
  constructor (props) {
    super(props)
    this.state = { active: 0 }
  }

  handleClick = (event, index) => {
    event.preventDefault()
    this.setState({ active: index })
  }

  render () {
    const children = React.Children.toArray(this.props.children)

    const tabs = children.map((_, i) => (
      <a key={i} className={`tab ${i === this.state.active ? 'active' : ''}`} onClick={e => this.handleClick(e, i)} href='#top'>
        {(this.props.labels || [])[i] || i + 1}
      </a>
    ))

    return (
      <div className='tab-group'>
        <div className='tabs'>{tabs}</div>
        <div className='body'>{children[this.state.active]}</div>
      </div>
    )
  }
}

export default TabGroup
