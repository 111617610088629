import React, { Component } from 'react'

// This should really be a react component, but need to improve state management
export default class Icon {
  constructor(props) {
    this.props = props
  } 
  
  render() {
    return <td className="cell icon">
      <span
        className={`badge badge-${this._badge()}`}
        title={this._title()}
      >
        <i className={`fa fa-${this._icon()}`} />
      </span>
    </td>
  }

  _title() {
    let title = []
    if (!this.props.is_whole_job) { title.push("Split job") }
    if (this.props.off_groupage) { title.push("Off groupage") }
    return title.join(" / ").presence()
  }

  _badge() {
    return this.props.off_groupage ? 'info' : 'primary'
  }

  _icon() {
    return this.props.is_whole_job ? 'cube' : 'puzzle-piece'
  }

}

