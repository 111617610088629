import React, { Component } from 'react'

import Popover from '../Popover'

const LABELS = {
  geographic_info: 'Location',
  capacity_info:   'Capacity',
  warehouse_info:  'Warehouse',
  customs_info:    'Customs',
  additional_info: 'Add. Info.'
}

export default class Notes extends Component {
  render() {
    let notes = LABELS.keys().select(k => this.props[k])
    let width = { maxWidth: `${95 / notes.length}%`, marginLeft: "1%" }
    return (
      <div className="notes" style={this.props.style} >{
        notes.map(k => (
          <Popover key={k} title={k.humanize()} content={this.props[k]} >
            <span className="label label-success hint-text" style={width}>
              <i className="fa fa-exclamation-circle" /> {LABELS[k]}
            </span>
          </Popover>
        )).presence()
      }</div>
    )
  }
}

