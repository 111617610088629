import React, { Component } from 'react'

import Routes, { url_for } from '../Routes'
import Button from '../Button'

import StateManager from '../StateManager'

export default class Colivery extends Component {
  constructor(props) {
    super(props)
    this.manager = new StateManager()
    this.state = this.manager.getColiveryState(props.unit_id, props.type)
  }

  render() {
    const LOCKED = this._isLocked()
    const ON_TRAILER = this._isOnTrailer()
    let text = this._text()
    let status = this.state.status || (LOCKED && 'locked') ||
      (ON_TRAILER && 'on_trailer') || ''
    if (status == 'in_progress' && this.state.same_trailer) { status = 'complete' }
    // This is to replicate what I think is a v1 bug...
    if (status == 'complete' && !this.state.company_hub) { status = '' }
    let css = `${text == "No details yet..." ? 'missing' : ''} ${status}`

    return (
      <td
        className="cell colivery"
        style={this.props.style}
        title={this._title()}
        onClick={e => e.stopPropagation() /* Don't select the unit via this cell */}
      >
        <Button
          type={ON_TRAILER ? 'primary' : 'default'}
          disabled={LOCKED}
          icon="truck"
          href={this._toggleOnTrailerUrl()}
          method="post"
        />
        <span
          onClick={this._trailerClick()}
          className={css}
        >
          {text}
        </span>
      </td>
    )
  }

  showModal() {
    this.manager.showBookingSelect({ id: this.props.unit_id, type: this.props.type })
  }

// private

  _trailerClick() {
    if (this._isLocked() || !this._isOnTrailer()) { return null }
    return this.showModal.bind(this)
  }

  // Only warehouse coliveries have a status
  _isLocked() {
    return this.state.warehouse && this.state.status != 'pending'
  }

  _href() {
    return "#"
  }

  _toggleOnTrailerUrl() {
    let params = {}
    params[`planning_unit[${this.props.type}_on_trailer]`] = !this._isOnTrailer()

    return url_for('change_planning_unit_path', this.props.unit_id, params)
  }

  _title() {
    if (this._isLocked()) {
      let status = this.state.status || ''
      return `This ${this.props.type} is ${status.gsub('_', ' ')}`
    }
    if (this._isOnTrailer()) { return '' }
    return "International trailer not required"
  }

  _text() {
    return this._isOnTrailer() ? this._trailer() : this._movements()
  }

  _isOnTrailer() {
    return this.manager.getOnTrailer(this.props.unit_id, this.props.type)
  }

  _requiresMovement() {
    return this.manager.requiresMovement(this.props.unit_id, this.props.type)
  }

  _movements() {
    let movements = this.manager.getMovements(this.props.unit_id, this.props.type)

    if (movements.length > 1) { return "Multiple" }
    if (movements.length == 0) {
      return this._requiresMovement() ? "No details yet..." : "n/a"
    }
    let m = movements[0]

    if (m.handled_by_client && !m.nominated_carrier) { return "Direct by client" }

    let haulier = m.haulier && m.haulier.short_name
    return `${m.handled_by_client ? "Client" : "DOM"} / ${haulier || '3rd party'}`
  }

  _trailer() {
    let booking = this.manager.getColiveryBooking(this.props.unit_id, this.props.type)

    return [
      booking.trailer_numbers,
      booking.vehicle_registration
    ].select(x => x).join(" / ") || '???'
  }
}

