import React from 'react'

import General from './General'
import Communications from './Communications'
import PageActions from './PageActions'
import Sidebar from './Sidebar'
// Components:
import BreadcrumbBar from '../../../common/BreadcrumbBar'
import ListOfSentEmails from '../../../emails/envelopes/ListOfSentEmails'
import ErrorList from '../../../common/ErrorList'
import FileManager from '../../../files/items/FileManager'
import PleaseWait from '../../../common/PleaseWait'
import StandardLayout from '../../../common/StandardLayout'
import TabContent from '../../../common/Tabs/TabContent'
import TabGroup from '../../../common/Tabs/TabGroup'

// The main "Show NCTS Discrepancy" page layout.
export default function (props) {
  if (!props.discrepancy) { return <PleaseWait /> }

  const breadcrumbs = {
    '/reactive/customs/discrepancies': 'Customs Discrepancies',
    [`/reactive/customs/discrepancies/${props.discrepancy.id}`]: props.discrepancy.reference
  }

  return (
    <React.Fragment>
      <BreadcrumbBar route={breadcrumbs} />
      <PageActions discrepancy={props.discrepancy} onDestroy={props.onDestroy} />
      <StandardLayout sidebar={<Sidebar discrepancy={props.discrepancy} />}>
        <h3>NCTS Discrepancy {props.discrepancy.reference}</h3>
        <ErrorList errors={props.errors} />
        <TabGroup labels={['General', 'Communications', 'Attachments', 'Emails']}>
          <TabContent>
            <General discrepancy={props.discrepancy} />
          </TabContent>
          <TabContent>
            <Communications
              discrepancy={props.discrepancy}
              onCreate={props.onCreateCommunication}
              onUpdate={props.onUpdateCommunication}
              onDelete={props.onDeleteCommunication}
            />
          </TabContent>
          <TabContent>
            <FileManager model={props.discrepancy} />
          </TabContent>
          <TabContent>
            <ListOfSentEmails
              model={props.discrepancy}
              onError={props.onError}
            />
          </TabContent>
        </TabGroup>
      </StandardLayout>
    </React.Fragment>
  )
}
