import React from 'react'

// Functions as a sort of "super-<select>" for choosing multiple country codes.
class CountryFilter extends React.Component {
  handleRemove = (codeToRemove) => {
    const value = this.values().filter(item => item !== codeToRemove).join(',')
    this.props.onChange({ target: { value: value } }) // Emulate a normal event
  }

  handleAdd = (event) => {
    const value = this.values().concat([event.target.value]).join(',')
    this.props.onChange({ target: { value: value } }) // Emulate a normal event
  }

  // Convert "GB,IT,ES" to ["GB", "IT", "ES"] -- properly handling "" as []
  values () { return this.props.value ? this.props.value.split(',') : [] }

  render () {
    const options = this.props.options.filter(s => !this.values().includes(s))
    const itemCss = 'trailer-planning-country-filter-value'
    const dropdownCss = 'trailer-planning-country-filter-dropdown'

    const currentlySelectedCountries = this.values().empty() ? (
      <div className='trailer-planning-country-filter-placeholder'>
        {this.props.placeholder || 'Select countries...'}
      </div>
    ) : this.values().map(v => (
      <button key={v} className={itemCss} onClick={() => this.handleRemove(v)}>
        {v}
      </button>
    ))

    // We can't add padding to <option> tags with CSS; "cheat" by using spaces:
    const availableOptions = options.map(item => (
      <option key={item} value={item}>&nbsp;{item} &nbsp; &nbsp; </option>
    ))

    return (
      <div className='trailer-planning-country-filter'>
        {currentlySelectedCountries}
        {options.length ? (
          <select className={dropdownCss} onChange={this.handleAdd} value=''>
            <option value='' disabled={true}>+</option>
            {availableOptions}
          </select>
        ) : null}
      </div>
    )
  }
}

export default CountryFilter
