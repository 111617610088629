import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import ErrorList from '../../common/ErrorList'
import DropDown from '../../common/Forms/DropDown'
import FieldList from '../../common/Forms/FieldList'
import FormField from '../../common/Forms/FormField'
import FormFooter from '../../common/Forms/FormFooter'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import SalesMarketStatusApi from '../../apis/crm/SalesMarketStatusApi'

// This is the "edit" page for a specific CRM Sales Market Status.
class SalesMarketStatusForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.match.params.id || '',
      loading: true,
      errors: []
    }
  }

  componentDidMount () {
    if (!this.state.id) { return this.initCreate() }

    SalesMarketStatusApi.show(this.state.id).then(this.initUpdate, this.handleErrors)
  }

  initCreate = () => {
    document.title = 'New Sales Market Status'

    this.setState({
      id: null,
      name: '',
      description: '',
      treatAs: '',
      loading: false,
      errors: []
    })
  }

  initUpdate = (json) => {
    document.title = `Editing ${json.name}`

    this.setState({
      id: json.id,
      name: json.name,
      description: json.description || '',
      treatAs: json.treatAs,
      loading: false,
      errors: []
    })
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value })
  }

  handleTreatAsChange = (event) => {
    this.setState({ treatAs: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const id = this.state.id
    const params = { salesMarketStatus: this.state }

    if (this.state.id) {
      SalesMarketStatusApi.update(id, params).then(this.handleSuccess, this.handleErrors)
    } else {
      SalesMarketStatusApi.create(params).then(this.handleSuccess, this.handleErrors)
    }
  }

  handleSuccess = (json) => {
    this.props.history.push('/reactive/crm/sales_market_statuses')
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/crm/sales_market_statuses': 'Sales Market Statuses',
      '/reactive/crm/sales_market_statuses/new': 'Edit'
    }

    const cancelUrl = '/reactive/crm/sales_market_statuses'

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>{document.title}</h3>
        <ErrorList errors={this.state.errors} />
        <TabGroup labels={['General']}>
          <TabContent>
            <FormSection title='General'>
              <FieldList>
                <FormField label='Name'>
                  <input className='form-control' value={this.state.name} onChange={this.handleNameChange} />
                </FormField>
                <FormField label='Treat As'>
                  <DropDown
                    source={SalesMarketStatusApi.categories}
                    value={this.state.treatAs}
                    onChange={this.handleTreatAsChange}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label='Description' size='wide'>
                  <input className='form-control' value={this.state.description} onChange={this.handleDescriptionChange} />
                </FormField>
              </FieldList>
            </FormSection>
          </TabContent>
        </TabGroup>
        <FormFooter>
          <button className='btn btn-primary' onClick={this.handleSubmit}>
            Save Changes
          </button>
          <Link className='btn btn-labeled' to={cancelUrl}>
            <i className='btn-label fa fa-times' />
            Cancel
          </Link>
        </FormFooter>
      </div>
    )
  }
}

export default SalesMarketStatusForm
