import React from 'react'

// Components:
import NotificationList from './NotificationList'
import withNotifications from './withNotifications'

// Used for Entities, Jobs, Quotes, etc.
class NotificationDropdown extends React.Component {
  constructor (props) {
    super(props)

    this.state = { showDropDown: false }
  }

  handleClick = (event) => {
    event.preventDefault()

    // Expand/Collapse the drop-down:
    this.setState({ showDropDown: !this.state.showDropDown })
    // Get rid of the "new items" styling:
    this.props.disableAlert()
  }

  handleViewAll = (event) => {
    event.preventDefault()

    // Collapse the drop-down:
    this.setState({ showDropDown: false })

    // FMS-1169: If the link at the bottom of a "Notifications" dropdown button
    // is pressed, switch to the (local) Notifications tab:
    // TODO: Can probably replace this now, thanks to the code below...
    $('a[href="#tab-notifications"]:not(.js-link-to-tab)').click()
  }

  render () {
    // If we haven't loaded any notifications yet, show a loading icon instead:
    const icon = this.props.items ? 'fa-bullhorn' : 'fa-ellipsis-h'
    // If we have new messages, change the component appearance in some way:
    const css = this.props.items && this.props.items.length ? 'btn-danger' : ''

    let label = 'No notifications'
    if (!this.props.items) {
      label = 'Loading...'
    } else if (this.props.items.length) {
      label = `${this.props.items.length} notifications`
    }

    return (
      <div className='notification-button'>
        <a href="#" className={`btn btn-sm btn-labeled ${css}`} onClick={this.handleClick}>
          <i className={`btn-label icon fa ${icon}`} />
          {label} &nbsp;&nbsp;
          <i className="fa fa-caret-down" />
        </a>
        {this.state.showDropDown && (
          <NotificationList
            items={this.props.items}
            onView={this.props.onView}
            onAction={this.props.onAction}
            onViewAll={this.handleViewAll}
          />
        )}
      </div>
    )
  }
}

export default withNotifications(NotificationDropdown)
