import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BooleanIcon from '../../common/Forms/BooleanIcon'
import BreadcrumbBar from '../../common/BreadcrumbBar'
import FieldList from '../../common/Forms/FieldList'
import FormHeader from '../../common/Forms/FormHeader'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import Ribbon from '../../common/Ribbon'
import ShowField from '../../common/Forms/ShowField'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import TrailerTypeApi from '../../apis/movements/TrailerTypeApi'
// I18N:
import enums from '../../i18n/enums'

// This is the "show" page for a specific trailer type.
class TrailerTypeShow extends React.Component {
  constructor (props) {
    super(props)
    this.state = { json: null }
  }

  componentDidMount () {
    TrailerTypeApi.show(this.props.match.params.id).then(
      json => {
        document.title = json.name
        this.setState({ json: json })
      },
      error => console.log(error)
    )
  }

  render () {
    if (!this.state.json) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/movements/trailer_types': 'Trailer Types',
      [`/reactive/movements/trailer_types/${this.state.json.id}`]: this.state.json.name
    }

    const generalSectionHeader = (
      <FormHeader title='General'>
        <label>
          Refrigerated?
          <BooleanIcon value={this.state.json.refrigerated} />
        </label>
      </FormHeader>
    )

    const dimensions = ['lengthM', 'widthM', 'heightM'].map(
      attributeName => this.state.json[attributeName]?.toFixed(2) || '---'
    ).join(' × ')

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <div className='page-profile'>
          <Ribbon>
            <div className='pull-right'>
              <Link className='btn btn-sm btn-labeled btn-warning' to={`/reactive/movements/trailer_types/${this.state.json.id}/edit`}>
                <i className='btn-label icon fa fa-edit' />
                Edit Trailer Type
              </Link>
            </div>
          </Ribbon>
          <h3>{this.state.json.name}</h3>
          <TabGroup labels={['General']}>
            <TabContent>
              <FormSection title={generalSectionHeader}>
                <FieldList>
                  <ShowField label='Name' value={this.state.json.name} />
                  <ShowField label='Category' value={enums.en.movements.trailerTypes.vehicleCategories[this.state.json.vehicleCategory] || this.state.json.vehicleCategory} />
                </FieldList>
                <FieldList>
                  <ShowField label='Trailers' value={enums.en.movements.trailerTypes.trailersAttacheds[this.state.json.trailersAttached] || this.state.json.trailersAttached} />
                  <ShowField label='Capacity' value={this.state.json.grossWeightKg} suffix='kg' />
                  <ShowField label='Dimensions' value={dimensions} suffix='m' />
                  <ShowField label='Loading Metres' value={this.state.json.loadingMetres} suffix='m' />
                </FieldList>
              </FormSection>
            </TabContent>
          </TabGroup>
        </div>
      </div>
    )
  }
}

export default TrailerTypeShow
