import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import CheckBox from '../../common/Forms/CheckBox'
import CurrencyAndValueField from '../../common/Forms/CurrencyAndValueField'
import DropDown from '../../common/Forms/DropDown'
import ErrorList from '../../common/ErrorList'
import FieldList from '../../common/Forms/FieldList'
import FormField from '../../common/Forms/FormField'
import FormFooter from '../../common/Forms/FormFooter'
import FormHeader from '../../common/Forms/FormHeader'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
import UserDropdown from '../../users/UserDropdown'
// APIs:
import CurrencyApi from '../../apis/financials/CurrencyApi'
import CustomsDiscrepancyApi from '../../apis/customs/CustomsDiscrepancyApi'
import CustomsNctsShipmentApi from '../../apis/customs/CustomsNctsShipmentApi'
import UserApi from '../../apis/UserApi'
// I18N:
import enums from '../../i18n/enums'

// This is the "edit" page for a specific discrepancy.
class CustomsDiscrepancyForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.match.params.id || '',
      loading: true,
      errors: []
    }
  }

  componentDidMount () {
    if (!this.state.id) { return this.initCreate() }

    CustomsDiscrepancyApi.show(this.state.id).then(this.initUpdate, this.handleErrors)
  }

  initCreate = () => {
    document.title = 'New Discrepancy'

    const urlParams = new URLSearchParams(window.location.search)
    const nctsShipmentId = urlParams.get('nctsShipmentId')

    this.setState({
      id: null,
      nctsShipmentId: nctsShipmentId,
      nctsShipmentText: '', // See below...
      userId: '',
      kind: '',
      deadline: '',
      penalty: false,
      currencyId: '',
      cost: '',
      recoveredFrom: '',
      recovered: false,
      resolved: false,
      loading: false,
      errors: []
    })

    // Assume that it belongs to us by default:
    UserApi.current().then(
      json => this.setState({ userId: json.id || '' }),
      json => console.log('Could not fetch current user:', json)
    )

    // Fetch the display text for the specified nctsShipmentId:
    CustomsNctsShipmentApi.show(nctsShipmentId).then(
      json => this.setState({ nctsShipmentText: json.reference || '' }),
      json => console.log('Could not fetch NCTS Shipment:', json)
    )
  }

  initUpdate = (json) => {
    document.title = `Editing Discrepancy ${json.reference}`

    this.setState({
      id: json.id,
      nctsShipmentId: json.nctsShipment?.id || '',
      nctsShipmentText: json.nctsShipment?.reference || 'Error: Not found!',
      userId: json.user?.id || '',
      kind: json.kind || '',
      deadline: json.deadline || '',
      penalty: json.penalty || false,
      currencyId: json.currency?.id || '',
      cost: json.cost || '',
      recoveredFrom: json.recoveredFrom || '',
      recovered: json.recovered || false,
      resolved: json.resolved || false,
      loading: false,
      errors: []
    })
  }

  handleUserIdChange = (event) => {
    this.setState({ userId: event.target.value })
  }

  handleKindChange = (event) => {
    this.setState({ kind: event.target.value })
  }

  handleDeadlineChange = (event) => {
    this.setState({ deadline: event.target.value })
  }

  handlePenaltyChange = (event) => {
    const newPenalty = !this.state.penalty

    // Assume that it should use GBP by default:
    if (newPenalty) {
      CurrencyApi.all().then(
        json => {
          const gbp = json.items.find(c => c.currencyCode === 'GBP')
          this.setState({ currencyId: gbp?.id || '' })
        },
        json => console.log('Could not fetch currency:', json)
      )
    }

    this.setState({ penalty: newPenalty })
  }

  handleRecoveredFromChange = (event) => {
    this.setState({ recoveredFrom: event.target.value })
  }

  handleCurrencyIdChange = (event) => {
    this.setState({ currencyId: event.target.value })
  }

  handleCostChange = (event) => {
    this.setState({ cost: event.target.value })
  }

  handleRecoveredChange = (event) => {
    this.setState({ recovered: !this.state.recovered })
  }

  handleResolvedChange = (event) => {
    this.setState({ resolved: !this.state.resolved })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const id = this.state.id
    const params = { nctsDiscrepancy: this.state }

    if (this.state.id) {
      CustomsDiscrepancyApi.update(id, params).then(this.handleSuccess, this.handleErrors)
    } else {
      CustomsDiscrepancyApi.create(params).then(this.handleSuccess, this.handleErrors)
    }
  }

  handleSuccess = (json) => {
    this.props.history.push(`/reactive/customs/discrepancies/${json.id}`)
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/customs/discrepancies': 'Discrepancies',
      '/reactive/customs/discrepancies/new': 'Edit'
    }

    let cancelUrl = '/reactive/customs/discrepancies'
    if (this.state.id) { cancelUrl = `/reactive/customs/discrepancies/${this.state.id}` }

    const generalHeader = (
      <FormHeader title='General'>
        <label>
          Penalty?
          <CheckBox value={this.state.penalty} onChange={this.handlePenaltyChange} />
        </label>
        <label>
          Resolved?
          <CheckBox value={this.state.resolved} onChange={this.handleResolvedChange} />
        </label>
      </FormHeader>
    )

    const penaltyHeader = (
      <FormHeader title='Penalty Details'>
        <label>
          Recovered?
          <CheckBox value={this.state.recovered} onChange={this.handleRecoveredChange} />
        </label>
      </FormHeader>
    )

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>{document.title}</h3>
        <ErrorList errors={this.state.errors} />
        <TabGroup labels={['General']}>
          <TabContent>

            <FormSection title={generalHeader}>
              <FieldList>
                <FormField label='NCTS Shipment'>
                  <input
                    className='form-control'
                    value={this.state.nctsShipmentText}
                    disabled={true}
                  />
                </FormField>
                <FormField label='Handled By'>
                  <UserDropdown
                    value={this.state.userId}
                    onChange={this.handleUserIdChange}
                  />
                </FormField>
                <FormField label='Kind'>
                  <DropDown
                    autoFocus={true}
                    source={CustomsDiscrepancyApi.kinds}
                    format={s => enums.en.customs.discrepancies.kinds[s] || s}
                    value={this.state.kind}
                    onChange={this.handleKindChange}
                  />
                </FormField>
                <FormField label='Deadline'>
                  <input
                    type='date'
                    className='form-control'
                    value={this.state.deadline}
                    onChange={this.handleDeadlineChange}
                  />
                </FormField>
              </FieldList>
            </FormSection>

            {this.state.penalty &&
              <FormSection title={penaltyHeader}>
                <FieldList>
                  <FormField label='Recovered From'>
                    <DropDown
                      source={CustomsDiscrepancyApi.recoveryOptions}
                      format={s => enums.en.customs.discrepancies.recoveredFroms[s] || s}
                      value={this.state.recoveredFrom}
                      onChange={this.handleRecoveredFromChange}
                    />
                  </FormField>
                  <FormField label='Cost'>
                    <CurrencyAndValueField
                      currencyId={this.state.currencyId}
                      onCurrencyIdChange={this.handleCurrencyIdChange}
                      value={this.state.cost}
                      onValueChange={this.handleCostChange}
                    />
                  </FormField>
                </FieldList>
              </FormSection>
            }

          </TabContent>
        </TabGroup>
        <FormFooter>
          <button className='btn btn-primary' onClick={this.handleSubmit}>
            Save Changes
          </button>
          <Link className='btn btn-labeled' to={cancelUrl}>
            <i className='btn-label fa fa-times' />
            Cancel
          </Link>
        </FormFooter>
      </div>
    )
  }
}

export default CustomsDiscrepancyForm
