
export default class AjaxRequest {

  constructor(args) {
    window.ajaxRequest({
      url:      `${args.url}${this._queryString(args.params)}`,
      type:     args.method,
      complete: args.complete
    })
  }

  _queryString(params) {
    if (!params) { return '' }
    var parts = []
    params.each((k,v) => parts.push(`${k}=${v}`))
    return `?${parts.join('&')}`
  }

}
