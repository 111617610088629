import React, { Component } from 'react'

const HINTS = [
  `Drag and drop units to assign to bookings`,
  `Click on a unit to select it, hold 'CTRL' key to select multiple units`,
  `You can drag and drop between multiple windows`,
  `Click the green icon to 'Pop Out' the Unassigned units to a new window. Click again to bring them back`,
  `You toggle between week and day mode via the blue button on the date filter`,

  `The progress filter adjusts which bookings are visible. Completed are hidden by default`,
  `Changes are broadcast to all connected planner users in real time`,
  `Hover over consignment flag icons for an explanation, or check the key`,
  `Click the truck icon in the top left of a booking to expand or collapse the contents`,

  `The 'v' button on top right of a booking allows for editing or sending collection/delivery documents`,
  `Hover over the dangerous goods icons to see details`,
  `Click the column headers to adjust the sort order of job units`,
  `A warning triangle on a trailer booking indicates potential problems; hover for details`,
  `Bookings and unassigned sections are paginated if they contain more than ten jobs`,

  `You can use the 'search' box in the top right of the unassigned sections to filter jobs`,
  `You can export the currently visible bookings and jobs to excel`,
  `Unassigned jobs flagged as 'always show on planner' will ignore the country filters`,
  `The unassigned and pending job week selector filters based on the 'ready' date`,
  `Hover over a trailer booking's progress to show the actual arrival time, if present`,

  `Booking Search filters on both trailer details, and assigned job references, customers, shippers and consignees`,
  `Hotkey Ctrl-B: Booking Search`,
]

export default class Help extends Component {

  constructor(props) {
    super(props)
    this.state = { hint: HINTS[0] }
    this._cycle()
  }

  componentDidMount() {
    this.mounted = true
    setTimeout(this._cycle.bind(this), 10000)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    return (
      <span className="hints">Hint: {this.state.hint}</span>
    )
  }

  _cycle() {
    if (!this.mounted) { return }
    setTimeout(this._cycle.bind(this), 10000)
    this.setState({ hint: HINTS[(Math.random() * HINTS.length).floor()] })
  }
}
