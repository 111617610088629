import BaseApi from '../BaseApi'
import buildGetQuery from '../buildGetQuery'

// This class handles all API interactions related to email envelopes.
export default class {
  static all (params) {
    params = buildGetQuery(params || {})
    return BaseApi.sendRequest('GET', 'api/emails/envelopes', params)
  }

  static show (id) {
    return BaseApi.sendRequest('GET', `api/emails/envelopes/${id}`)
  }

  static create (params) {
    return BaseApi.sendRequest('POST', 'api/emails/envelopes', params)
  }

  static destroy (id) {
    return BaseApi.sendRequest('DELETE', `api/emails/envelopes/${id}`)
  }
}
