import React from 'react'

import CustomsChargesForm from './CustomsChargesForm'
import ErrorList from '../../common/ErrorList'
import PleaseWait from '../../common/PleaseWait'

import CustomsChargesApi from '../../apis/customs/CustomsChargesApi'

// Used to display/edit customs pricing information.
class CustomsChargesTab extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      categories: null,
      errors: []
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData = () => {
    CustomsChargesApi.fetch({ basedOnValue: this.props.basedOnValue }).then(
      json => this.setState({ categories: json }),
      this.handleErrors
    )
  }

  handleErrors = (json) => {
    const data = json || { error: 'Unknown error' } // `json` may be `null`...
    this.setState({ errors: data.errors || [data.error] })
  }

  handleAddCategory = (name) => {
    const names = Object.keys(this.state.categories).map(s => s.toUpperCase())

    if (names.includes(name.toUpperCase())) {
      return this.setState({ errors: ['Category name is already in use'] })
    } else if (!name) {
      return this.setState({ errors: ["Category name can't be blank"] })
    }

    const firstRow = Object.keys(this.state.categories)[0]
    const blankRow = (this.state.categories[firstRow] || []).map(item => ({
      quantity: item.quantity, value: ''
    }))

    const data = { ...this.state.categories }
    data[name] = blankRow
    this.setState({ categories: data })
  }

  handleRemoveCategory = (name) => {
    const data = { ...this.state.categories }
    delete data[name]
    this.setState({ categories: data })
  }

  handleAddQuantity = (quantity) => {
    const data = {}

    Object.keys(this.state.categories).forEach(c => {
      const oldData = this.state.categories[c]

      let newData = [{ quantity: quantity, value: '' }]
      if (oldData.find(tier => tier.quantity === quantity)) { newData = [] }

      data[c] = oldData.concat(newData).sort((a, b) => a.quantity > b.quantity)
    })

    this.setState({ categories: data })
  }

  handleRemoveQuantity = (quantity) => {
    const data = {}

    Object.keys(this.state.categories).forEach(c => {
      data[c] = this.state.categories[c].filter(t => t.quantity !== quantity)
    })

    this.setState({ categories: data })
  }

  handleValueChange = (category, quantity, value) => {
    const data = {}

    Object.keys(this.state.categories).forEach(key => {
      data[key] = this.state.categories[key].map(item => {
        if (key !== category || item.quantity !== quantity) { return item }

        return { quantity: quantity, value: value }
      })
    })

    this.setState({ categories: data })
  }

  handleReset = () => {
    if (window.confirm('Undo all recent changes?')) { this.fetchData() }
  }

  handleSubmit = () => {
    const params = {
      basedOnValue: this.props.basedOnValue,
      categories: this.state.categories
    }

    CustomsChargesApi.update(params).then(
      json => bootbox.alert('Saved successfully!'),
      this.handleErrors
    )
  }

  render () {
    if (!this.state.categories) { return <PleaseWait /> }

    return (
      <React.Fragment>
        <ErrorList errors={this.state.errors} />
        <CustomsChargesForm
          categories={this.state.categories}
          onAddCategory={this.handleAddCategory}
          onAddQuantity={this.handleAddQuantity}
          onRemoveCategory={this.handleRemoveCategory}
          onRemoveQuantity={this.handleRemoveQuantity}
          onValueChange={this.handleValueChange}
          onReset={this.handleReset}
          onSubmit={this.handleSubmit}
        />
      </React.Fragment>
    )
  }
}

export default CustomsChargesTab
